import { useContext } from "react";
import { GoogleMap, OverlayView } from "@react-google-maps/api";

import MerchantContext from "@/contexts/MerchantContext";
import { useStoreOperatingHour } from "@/contexts/StoreOperatingHourContext";
import styles from "./OrderModeDialog.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { convertFrom24To12Format } from "@/utils/util";

const PickupDialog = () => {
  const today = new Date();
  const merchantInfoContext = useContext(MerchantContext);
  const { landingPageData } = useStoreOperatingHour();

  const center = {
    lat: Number(merchantInfoContext.storeLatitude),
    lng: Number(merchantInfoContext.storeLongitude),
  };

  return (
    <div>
      <div className="mt-6 mb-1">
        <div className="font-bold text-center mb-2">Pick-up from</div>
        <div className="flex-grow">
          <div className="flex flex-col gap-2 border-[2px] py-5 px-3 rounded-md w-full mb-5">
            <p className="font-semibold text-left my-0">
              {merchantInfoContext.name}
            </p>
            {landingPageData.warungStoreStatus && (
              <p className="text-[12px] text-left text-primary text-xs my-0">
                {landingPageData.warungStoreStatus}
              </p>
            )}
            <p className="text-left my-0">
              {landingPageData.operatingHours[today.getDay()].open &&
                convertFrom24To12Format(
                  landingPageData.operatingHours[today.getDay()].open
                )}{" "}
              {" - "}
              <span>
                {landingPageData.operatingHours[today.getDay()].close &&
                  convertFrom24To12Format(
                    landingPageData.operatingHours[today.getDay()].close
                  )}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div
        className={[
          styles["fill-remaining-height-pick-up"],
          "flex flex-col sm:h-[40vh] rounded-lg overflow-hidden",
        ].join(" ")}
      >
        <GoogleMap
          zoom={12}
          center={center}
          mapContainerClassName="h-full w-full"
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            gestureHandling: "greedy",
          }}
        >
          <OverlayView
            position={center}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div className="flex flex-col items-center">
              <FontAwesomeIcon
                icon={faLocationDot}
                className="text-primary text-[2.2rem]"
              />
              <div className="pt-[5px] border-b-2 border-primary w-[1.2rem]"></div>
            </div>
          </OverlayView>
        </GoogleMap>
      </div>
    </div>
  );
};

export default PickupDialog;
