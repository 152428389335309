import moment from "moment";
import axios from "axios";
import Cookies from "universal-cookie";

export const compare = (item1, item2) => {
  if (item1.sequence < item2.sequence) return -1;
  // put A in front
  else if (item1.sequence > item2.sequence) return 1;
  // put B in front
  else return 0;
};

export const isEmpty = (obj) => {
  var hasOwnProperty = Object.prototype.hasOwnProperty;
  if (obj == null) return true;
  if (obj === undefined) return true;
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;
  if (typeof obj !== "object") return true;
  for (var key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }
  return true;
};

export const isValidPhoneNumber = (str) => {
  //validate malaysia phone no
  // if (obj.substr(0, 3) == "+60" && obj.length >= 12) {
  //   return true;
  // }
  // //valdiate singapore phone no
  // else if (obj.substr(0, 3) == "+65" && obj.length >= 11) {
  //   return true;
  // } else {
  //   return false;
  // }

  return /^(\+?6?01)[0|1|2|3|4|6|7|8|9]-*[0-9]{7,8}$/.test(str);
};

export const isValidEmail = (str) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    str
  );
};

export const isDateBetween = (startDate, endDate) => {
  const start = !!startDate ? moment(startDate) : undefined;
  const end = !!endDate ? moment(endDate) : undefined;
  return moment().isBetween(start, end, null, "[]");
};

// Reusable Function to Enforce MaxLength
export const enforce_maxlength = (event) => {
  var t = event.target;
  if (t.hasAttribute("maxlength")) {
    t.value = t.value.slice(0, t.getAttribute("maxlength"));
  }
};

export const createCookieForSignInData = (userSignInData, signInData) => {
  const cookie = new Cookies();
  var expDate = 0;
  var shopId = getShopId();
  const data = {
    primaryEmail: signInData.primaryEmail,
    accountNo: userSignInData.accountNo,
    customerId: userSignInData.customerId,
    accessToken: userSignInData.accessToken,
    refreshToken: userSignInData.refreshToken,
    keepSignIn: signInData.keepSignIn ?? false,
  };
  if (signInData.keepSignIn) {
    expDate = new Date();
    expDate.setMonth(expDate.getMonth() + 5);
  }

  cookie.set(`${shopId}@signIn`, JSON.stringify(data), {
    expires: expDate,
    path: "/",
  });
  cookie.set(`${shopId}@sessionId`, data.customerId, {
    expires: expDate,
    path: "/",
  });
};

export const uploadFileToS3 = (signedUrl, file, loadingState) => {
  let form = new FormData();
  const newData = JSON.parse(signedUrl);
  Object.keys(newData.fields).forEach((key) =>
    form.append(key, newData.fields[key])
  );
  form.append("file", file);
  // return fetch(newData.url, { method: "POST", body: form });
  return axios.request({
    method: "POST",
    data: form,
    url: newData.url,
    onUploadProgress: (p) => {
      loadingState({
        fileprogress: p.loaded / p.total,
      });
    },
  });
};

export const totalPagination = (total, limit) => {
  return (total / limit) % 1 !== 0
    ? parseInt(total / limit) + 1
    : total / limit;
};

export const convertFrom24To12Format = (time24) => {
  const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
  const period = +sHours < 12 ? "AM" : "PM";
  const hours = +sHours % 12 || 12;

  return `${hours}:${minutes} ${period}`;
};

export const convertFrom12To24Format = (time12) => {
  const [sHours, minutes, period] = time12
    .match(/([0-9]{1,2}):([0-9]{2}) (AM|PM)/)
    .slice(1);
  const PM = period === "PM";
  const hours = (+sHours % 12) + (PM ? 12 : 0);

  return `${("0" + hours).slice(-2)}:${minutes}`;
};

export const getSelectedModifiersToDisplayList = (selectedModifierGroups) => {
  let outputList = [];

  selectedModifierGroups?.forEach((modifierGroup) => {
    if (
      modifierGroup.modifierGroupType === "text" &&
      modifierGroup.requestRemark
    ) {
      outputList.push(modifierGroup.requestRemark);
    } else {
      modifierGroup.modifier.forEach((modifier) => {
        if (modifier?.isSelected === undefined || modifier.isSelected) {
          outputList.push(modifier.modifierName);
        }
      });
    }
  });

  return outputList;
};

// to get key index for cookie
export const getShopId = () => {
  const cookie = new Cookies();
  var shopId = "fam";
  if (cookie.get("s@warung")) {
    shopId = cookie.get("s@warung");
  }
  return shopId;
};

export const getDomainForSSR = (context) => {
  let domain = "testmerchant.fam-dev.click";
  // let domain = "demeter.ewarung.shop";
  let shopId = "fam";
  if (
    !["localhost", ".vfs.cloud9.ap-southeast-1.amazonaws.com"].some((element) =>
      context.req.headers.host.includes(element)
    )
  ) {
    domain = context.req.headers["x-forwarded-host"];
  }
  console.log("ctx.req.headers", context.req.headers);

  if (domain.includes("ewarung.shop")) {
    if (context.query.s || context.req.cookies["s@warung"]) {
      shopId = context.query.s ?? context.req.cookies["s@warung"];
      domain = domain + "/?s=" + shopId;
    } else {
      return { domain, shopId: null };
    }
  }

  return { domain, shopId };
};

export const getOrderTypeText = (orderType) => {
  let text = "";

  switch (orderType) {
    case "PickUp":
      text = "Pick Up";
      break;
    case "DineIn":
      text = "Dine In";
      break;
    case "Delivery":
      text = "Delivery";
      break;
  }

  return text;
};
