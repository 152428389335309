import React, { createContext, useContext, useState, useEffect } from "react";
import { useRouter } from "next/router";
// utils
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
import { createCookieForSignInData, getShopId } from "@/utils/util";

//API
import { API, graphqlOperation } from "aws-amplify";
import {
  customerSignOut,
  customerSignIn,
  oTPVerification,
  resendOTP,
} from "@/graphql/mutations";
import { getCustomer } from "@/graphql/queries";
// contexts
import { setToastState } from "@/states/toastBarState";
import MerchantContext from "@/contexts/MerchantContext";
const AuthContext = createContext();

function AuthContextProvider({ children }) {
  const merchantInfoContext = useContext(MerchantContext);

  const router = useRouter();
  const cookie = new Cookies();
  const [user, setUser] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingGetCustomer, setIsLoadingGetCustomer] = useState(false);

  // alert
  const [error, setError] = useState({
    show: false,
    message: "",
  });
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [signInData, setSignInData] = useState({
    primaryEmail: "",
    password: "",
    accountNo: "",
    merchantId: "",
    platformAccountId: "",
  });

  //fam / warung / multistore (haven't implemented)
  const [merchantType, setMerchantType] = useState("");

  useEffect(() => {
    const signInData = cookie.get(`${getShopId()}@signIn`);
    // signed in
    if (!(signInData === undefined)) {
      setIsAuthenticated(true);
      getCustomerData();
      console.log("there???");
    }

    if (getShopId() === "fam") {
      setMerchantType("fam");
    } else if (cookie.get("s@warung")) {
      setMerchantType("warung");
    }

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // SignOut
  async function handleSignOut() {
    setIsLoading(true);
    const signInData = cookie.get(`${getShopId()}@signIn`);
    try {
      var params = {
        accountNo: signInData.accountNo,
        accessToken: signInData.accessToken,
        refreshToken: signInData.refreshToken,
        merchantId: merchantInfoContext.merchantId,
      };
      let signout = await API.graphql(
        graphqlOperation(customerSignOut, params)
      );

      if (signout.data.customerSignOut.status === true) {
        cookie.remove(`${getShopId()}@signIn`);
        cookie.set(`${getShopId()}@sessionId`, uuidv4(), { path: "/" });
        sessionStorage.clear(`${getShopId()}@selectedItems`);
        setToastState({
          show: true,
          severity: "success",
          message: "Logout Successfully",
        });
        setIsAuthenticated(false);
        setUser({});
        // router.push("/");
      } else {
        setToastState({
          show: true,
          severity: "error",
          message: "Logout Failed",
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  // handle sign in
  const handleLogin = async () => {
    try {
      var params = {
        primaryEmail: signInData.primaryEmail,
        password: signInData.password,
        merchantId: merchantInfoContext.merchantId,
        oldCustomerId: cookie.get(`${getShopId()}@sessionId`),
      };
      const user = await API.graphql(graphqlOperation(customerSignIn, params));
      if (user.data.customerSignIn.status === true) {
        const userSignInData = user.data.customerSignIn;
        createCookieForSignInData(userSignInData, signInData);
        getCustomerData();
        setIsAuthenticated(true);
        return true;
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setToastState({
          show: true,
          message: user.data.customerSignIn.message,
        });
        if (
          user.data.customerSignIn.message.includes(
            "Please Check Your Email To Verify Your Account."
          )
        ) {
          setOpenOtpModal(true);
        }
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  // otp verification
  const handleOtpVerification = async (otpValues) => {
    let params = {
      customerOtp: otpValues,
      primaryEmail: signInData.primaryEmail,
      merchantId: merchantInfoContext.merchantId,
    };
    try {
      const res = await API.graphql(graphqlOperation(oTPVerification, params));
      if (res.data.oTPVerification.message === "Valid OTP and email") {
        setOpenOtpModal(false);
        setToastState({
          show: true,
          severity: "success",
          message: "Sign Up Successful",
        });
        if (router.query.return === "checkout") {
          console.log("tocheck again");
          // const userSignInData = res.data.customerSignIn;
          // localStorage.setItem("fam@sessionId", userSignInData.customerId);
          // let signInObj = {
          //   primaryEmail: signInData.primaryEmail,
          //   accountNo: userSignInData.accountNo,
          //   customerId: userSignInData.customerId,
          //   accessToken: userSignInData.accessToken,
          //   refreshToken: userSignInData.refreshToken,
          //   keepSignIn: signInData.keepSignIn,
          // };

          // localStorage.setItem("fam@signIn", JSON.stringify(signInObj));
        }
        return true;
      } else {
        setToastState({
          show: true,
          severity: "error",
          message: res.data.oTPVerification.message,
        });
        return false;
      }
    } catch (error) {
      console.log(error);
      setToastState({
        show: true,
        severity: "error",
        message: "Something went wrong. Please try again later.",
      });
      return false;
    }
  };

  // resend otp
  const handleOtpResend = async (e) => {
    e.preventDefault();
    let params = {
      primaryEmail: customerData.primaryEmail,
      merchantId: merchantInfoContext.merchantId,
    };
    try {
      const res = await API.graphql(graphqlOperation(resendOTP, params));
      if (res.data.resendOTP.status === "true") {
        setToastState({
          show: true,
          severity: "success",
          message: res.data.resendOTP.message,
        });
      } else {
        setToastState({
          show: true,
          severity: "error",
          message: res.data.resendOTP.message,
        });
      }
    } catch (error) {
      console.log(error);
      setToastState({
        show: true,
        severity: "error",
        message: "OTP Request Failed",
      });
    }
  };

  // get customer data
  const getCustomerData = async () => {
    const cookie = new Cookies();
    setIsLoadingGetCustomer(true);
    try {
      var params = {
        merchantId: merchantInfoContext.merchantId,
        customerId: cookie.get(`${getShopId()}@signIn`)?.customerId,
      };

      let { data: responseObj } = await API.graphql(
        graphqlOperation(getCustomer, params)
      );
      if (
        responseObj.getCustomer.message === "Success" &&
        responseObj.getCustomer.status === true
      ) {
        setUser(responseObj.getCustomer);
      }
      setIsLoadingGetCustomer(false);
    } catch (error) {
      console.log(error);
      setIsLoadingGetCustomer(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        setIsAuthenticated,
        isAuthenticated,
        user,
        setUser,
        handleSignOut,
        handleLogin,
        handleOtpResend,
        handleOtpVerification,
        error,
        setError,
        isLoading,
        signInData,
        setSignInData,
        openOtpModal,
        setOpenOtpModal,
        merchantType,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => useContext(AuthContext);

export { AuthContextProvider, useAuth };
