import React, { useContext } from "react";
import Image from "next/image";
import Link from "next/link";
// contexts
import MerchantContext from "@/contexts/MerchantContext";
// image
import appStore from "@/images/download-app-store.svg";
import googlePlay from "@/images/google-play-badge.svg";
// icons library
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faYoutube,
  faInstagram,
  faTiktok,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

// utils
import { isDateBetween } from "@/utils/util";

const legalPolicyMappingList = [
  {
    type: "Privacy",
    name: "Privacy Policy",
    link: "/policy/privacy",
  },
  {
    type: "Refund",
    name: "Refund Policy",
    link: "/policy/refund",
  },
  {
    type: "Term Of Service",
    name: "Term of Service",
    link: "/policy/term-of-service",
  },
  {
    type: "Shipping",
    name: "Shipping Policy",
    link: "/policy/shipping",
  },
];

const merchantInfoList = [
  {
    type: "FAQ",
    name: "FAQ",
    link: "/faq",
  },
  {
    type: "About Us",
    name: "About Us",
    link: "/about-us",
  },
  {
    type: "Contact Us",
    name: "Contact Us",
    link: "/contact-us",
  },
];

const Footer = (props) => {
  const merchantInfoContext = useContext(MerchantContext);
  const footerNavList = props.siteNav?.filter(
    (data) =>
      isDateBetween(data.effectiveStartDateTime, data.effectiveEndDateTime) &&
      data.menuType === "footer"
  );

  const listAvailablePolicies = props.policies
    ?.filter((data) => data.isDisabled === false)
    .map((obj) => obj.policyType);

  const socialMediaIconsList = [
    {
      name: "facebook",
      src: <FontAwesomeIcon icon={faFacebook} />,
      url: merchantInfoContext.facebookUrl,
    },
    {
      name: "instagram",
      src: <FontAwesomeIcon icon={faInstagram} />,
      url: merchantInfoContext.instagramUrl,
    },
    {
      name: "youtube",
      src: <FontAwesomeIcon icon={faYoutube} />,
      url: merchantInfoContext.youtubeUrl,
    },
    {
      name: "twitter",
      src: <FontAwesomeIcon icon={faTwitter} />,
      url: merchantInfoContext.twitterUrl,
    },
    {
      name: "tiktok",
      src: <FontAwesomeIcon icon={faTiktok} />,
      url: merchantInfoContext.tiktokUrl,
    },
    {
      name: "linkedin",
      src: <FontAwesomeIcon icon={faLinkedin} />,
      url: merchantInfoContext.linkedInUrl,
    },
  ];

  return (
    <footer className="bg-primary rounded-t-3xl box-border">
      <div className="mx-auto max-w-1200 sm:px-6 ">
        <div className="py-10">
          <div className="flex flex-col md:flex-row">
            <div className="flex basis-1/3 flex-col px-7 border-b text-headerFooterFontColor border-b-white md:border-r md:border-r-white md:border-b-transparent ">
              <div className="py-5 flex relative w-auto h-[200px] ">
                <Image
                  alt="logo"
                  src={process.env.BUCKET_URL + merchantInfoContext.logo}
                  layout="fill"
                  objectFit="contain"
                />
              </div>
              <div className="mb-2 font-bold">
                {merchantInfoContext.name}{" "}
                {merchantInfoContext.merchantRegistrationNumber && (
                  <>({merchantInfoContext.merchantRegistrationNumber})</>
                )}
              </div>
              <div className="mb-2 text-[13px]">
                Email: {merchantInfoContext.email}
              </div>
              <div className="mb-2 text-[13px]">
                Contact: {merchantInfoContext.phone}
              </div>
              <div className="mb-2 text-[13px]">
                Address: {merchantInfoContext.address}
              </div>
              <div className="flex flex-row justify-start mb-5 items-center">
                {socialMediaIconsList.map((obj, index) => {
                  if (obj.url === null) return;
                  return (
                    <div className="w-[20px] h-[20px] mr-3 " key={index}>
                      <a
                        target="_blank"
                        href={
                          obj.url.includes("https://")
                            ? obj.url
                            : "https://" + obj.url
                        }
                        rel="noopener noreferrer"
                      >
                        {obj.src}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="mt-5 basis-2/3 flex-1 grid grid-cols-none xs:grid-cols-6 xs:grid-flow-rows gap-4 px-7 auto text-headerFooterFontColor">
              <div className="row-span-2 xs:col-span-2">
                {legalPolicyMappingList.map((obj, index) => {
                  if (listAvailablePolicies?.includes(obj.type)) {
                    return (
                      <div className="mb-1 text-[13px]" key={index}>
                        <Link passHref href={obj.link}>
                          <a className="no-underline">{obj.name}</a>
                        </Link>
                      </div>
                    );
                  }
                })}
                {merchantInfoList.map((obj, index) => {
                  return (
                    <div className="mb-1 text-[13px]" key={index}>
                      <Link passHref href={obj.link}>
                        <a className="no-underline">{obj.name}</a>
                      </Link>
                    </div>
                  );
                })}
              </div>
              <div className="xs:col-span-4">
                <div className="grid xs:grid-cols-2 gap-4">
                  {footerNavList?.map((obj, idx) => {
                    return (
                      <div className="" key={idx}>
                        <div className="mb-1 font-bold text-[14px]">
                          {obj.title}
                        </div>
                        {obj.childItems.length > 0 && (
                          <div className="text-[13px]">
                            {obj.childItems
                              .filter((o) =>
                                isDateBetween(
                                  o.effectiveStartDateTime,
                                  o.effectiveEndDateTime
                                )
                              )
                              .map((o, idx) => (
                                <div className="mb-1" key={idx}>
                                  <Link passHref href={`/${o.linkValue}`}>
                                    <a className="no-underline">{o.title}</a>
                                  </Link>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                {/* google play & app store */}
                <div className="mt-3 flex flex-col xs:flex-row">
                  {merchantInfoContext.googlePlayUrl && (
                    <div className="mb-1 xs:mr-3 xs:mb-0">
                      <a
                        target="_blank"
                        href={
                          merchantInfoContext.googlePlayUrl.includes("https://")
                            ? merchantInfoContext.googlePlayUrl
                            : "https://" + merchantInfoContext.googlePlayUrl
                        }
                        rel="noopener noreferrer"
                      >
                        <Image src={googlePlay} alt="google-play" />
                      </a>
                    </div>
                  )}
                  {merchantInfoContext.playStoreUrl && (
                    <div>
                      <a
                        target="_blank"
                        href={
                          merchantInfoContext.playStoreUrl.includes("https://")
                            ? merchantInfoContext.playStoreUrl
                            : "https://" + merchantInfoContext.playStoreUrl
                        }
                        rel="noopener noreferrer"
                      >
                        <Image src={appStore} alt="app-store" />
                      </a>
                    </div>
                  )}
                </div>
              </div>
              {merchantInfoContext.copyright && (
                <div className="xs:col-span-full text-sm">
                  {merchantInfoContext.copyright}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
