import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
// import Script from "next/script";

// components
import Layout from "@/components/layouts/Layout";
import PrivateRoute from "@/components/privateRoute/PrivateRoute";
import Maintenance from "@/components/maintenance/Maintenance";
import ErrorPage from "next/error";
import Error from "./_error";

// styles
import "../styles/globals.scss";

// font awesome library icons
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;

// APIs
import { Amplify } from "aws-amplify";
import awsExports from "../aws-exports";
import { getSiteNavigationCache, getLegalPolicy } from "@/graphql/queries";
Amplify.configure({ ...awsExports });

// contexts
import { AuthContextProvider } from "@/contexts/AuthContext";
import { WishlistContextProvider } from "@/contexts/WishlistContext";
import { CartContextProvider } from "@/contexts/CartContext";
import { StoreOperatingHourContextProvider } from "@/contexts/StoreOperatingHourContext";
import { OrderContextProvider } from "@/contexts/OrderContext";

import MerchantContext from "@/contexts/MerchantContext";

import { appRoutes } from "@/enums/appRoutes";

// localization
import { IntlProvider } from "react-intl";
import { locale } from "@/enums/locale.mapper";

import NProgress from "nprogress";
import "@/styles/nprogress.css";
import "@/styles/calendar.scss";

const enMessage = require("@/languages/en.json");
const cnMessage = require("@/languages/cn.json");

const messageMapper = {
  [locale.en]: enMessage,
  [locale["zh-cn"]]: cnMessage,
};
import { useGetSiteNavs } from "@/hooks/useSiteNavs";
import { useGetLegalPolicies } from "@/hooks/useLegalPolicies";
import { useGetSplashScreen } from "@/hooks/useSplashScreen";

import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
import { getDomainForSSR, getShopId } from "@/utils/util";

// rest api
import { getMerchantData } from "./api/merchant";
import Custom404 from "./404";

const swrOptionParameters = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  refreshWhenOffline: false,
  refreshWhenHidden: false,
  refreshInterval: 0,
};

function MyApp({ Component, pageProps, props }) {
  const router = useRouter();

  if (props.status && props.status !== 200) {
    return <Custom404 showButton={false} />;
  }

  const { siteNavs, headerList, footerList } = useGetSiteNavs(
    props.response.merchantId,
    swrOptionParameters
  );
  const { legalPolicies } = useGetLegalPolicies(
    props.response.merchantId,
    swrOptionParameters
  );
  const { splashScreenList } = useGetSplashScreen(
    props.response.merchantId,
    swrOptionParameters
  );

  // localization
  const [messages, setMessages] = useState(enMessage);
  // React.useEffect(() => {
  //   setMessages(messageMapper[siteInfoContext.language]);
  // }, [siteInfoContext.language]);

  // for loading when getServerSideProps / getStaticProps is loading
  const [loading, setLoading] = useState(false);

  const [appContextObj, setAppContextObj] = React.useState({});

  const protectedRoutes = [
    appRoutes.ORDER,
    appRoutes.MY_PROFILE,
    appRoutes.ADDRESS_BOOK,
    appRoutes.EDIT_ADDRESS_BOOK,
    appRoutes.WISHLIST,
    "/point-history",
  ];

  const tngWarungRoutes = [appRoutes.LANDING, appRoutes.COLLECTIONS_MENU];
  const cookie = new Cookies();

  useEffect(() => {
    localStorage.setItem("fam@siteConfigInfo", JSON.stringify(props.response));
  }, []);

  console.log("shopId", getShopId());

  useEffect(() => {
    const handleStart = (url) => {
      console.log(`Loading: ${url}`);
      NProgress.start();
    };
    const handleStop = () => {
      NProgress.done();
    };
    // if (router.route === "/warung-order" || router.route === "/bookmark") {
    //   setIsLandingPage(false);
    // }

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleStop);
    router.events.on("routeChangeError", handleStop);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleStop);
      router.events.off("routeChangeError", handleStop);
    };
  }, [router]);

  return (
    <>
      <IntlProvider messages={messages} locale={"en"} defaultLocale={locale.en}>
        {process.env.NEXT_PUBLIC_MAINTENANCE ? (
          <Maintenance />
        ) : (
          <MerchantContext.Provider value={props.response}>
            <AuthContextProvider>
              <StoreOperatingHourContextProvider>
                <CartContextProvider>
                  <WishlistContextProvider>
                    <OrderContextProvider>
                      <Layout
                        setting={props.response}
                        headerList={headerList}
                        footerList={footerList}
                        legalPolicies={legalPolicies}
                        splashScreenList={splashScreenList}
                        routePath={router.route}
                      >
                        {/* {loading ? <h1>Loading...</h1> : <Component {...pageProps} />} */}
                        <PrivateRoute protectedRoutes={protectedRoutes}>
                          <Component {...pageProps} />
                        </PrivateRoute>
                      </Layout>
                    </OrderContextProvider>
                  </WishlistContextProvider>
                </CartContextProvider>
              </StoreOperatingHourContextProvider>
            </AuthContextProvider>
          </MerchantContext.Provider>
        )}
      </IntlProvider>
    </>
  );
}

MyApp.getInitialProps = async ({ ctx }) => {
  if (ctx.req) {
    console.log("inside if here");
    // first time open web server side render
    const { domain, shopId } = getDomainForSSR(ctx);
    console.log("domain", domain);
    let exdate = new Date();
    exdate.setDate(exdate.getDate() + 1);
    console.log("cookies", ctx.req.cookies);
    let cookieList = [];
    if (shopId) {
      if (shopId !== "fam")
        cookieList.push(`s@warung=${shopId}; path=/; expires=${exdate};`);

      if (ctx.req.cookies[`${shopId}@sessionId`] === undefined) {
        cookieList.push(
          `${shopId}@sessionId=${uuidv4()}; path=/; expires=${exdate}`
        );
      }
    } else {
      return {
        props: {
          status: 400,
        },
      };
    }

    const merchantJsonData = await getMerchantData(domain);

    if (merchantJsonData.siteFont === null)
      merchantJsonData.siteFont = "Helvetica";
    if (merchantJsonData.siteColor === null)
      merchantJsonData.siteColor = "#42a5f5";
    if (merchantJsonData.siteNotificationBarColor === null)
      merchantJsonData.siteNotificationBarColor = "#039c5c";
    if (merchantJsonData.currency === null) merchantJsonData.currency = "MYR";
    // temporary hardcode change header footer font color for specific merchant
    if (domain === "growingdesserts.axrailcommerce.com") {
      merchantJsonData.headerFooterFontColor = "#0000000";
    } else {
      merchantJsonData.headerFooterFontColor = "#ffffff";
    }

    merchantJsonData.domain = domain;

    ctx.res.setHeader("set-cookie", [
      ...cookieList,
      `${shopId}@merchantId=${merchantJsonData.merchantId}; path=/;`,
    ]);

    return {
      props: {
        response: merchantJsonData,
        status: merchantJsonData.status,
      },
    };
  } else {
    console.log("else there");
    // when change router path, client side changes
    return {
      props: {
        response: JSON.parse(localStorage.getItem("fam@siteConfigInfo")),
      },
    };
  }
};

export default MyApp;
