import { useEffect, useState } from "react";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import DeliveryPopupInput from "./DeliveryPopupInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { setToastState } from "@/states/toastBarState";

export const PlacesAutocompleteInput = ({
  funcSplitAddress,
  section,
  formikProps,
  resetForm,
  setIsLoadFromInput,
  getUserLocation,
  ...placesAutocompleteProps
}) => {
  const [focus, setFocus] = useState(false);
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = placesAutocompleteProps;

  useEffect(() => {
    if (formikProps.values.address) {
      setValue(formikProps.values.address);
    }
  }, [formikProps.values.address]);

  const handleSelect = async (address) => {
    formikProps.setFieldValue("isLoadFromInput", true);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    formikProps.setFieldValue("selectedLatLng", { lat, lng });
    formikProps.setFieldValue("address", results[0].formatted_address);
    funcSplitAddress(
      results[0].address_components,
      results[0].formatted_address,
      formikProps.setFieldValue
    );
  };

  return (
    <div
      className="w-full relative"
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
    >
      <DeliveryPopupInput
        label="Address *"
        type="text"
        value={value}
        buttonIcon={
          <FontAwesomeIcon
            icon={faLocationCrosshairs}
            size="xl"
            className="text-gray-400"
          />
        }
        buttonOnClick={() =>
          getUserLocation(formikProps).then((val) => {
            if (!val) {
              setToastState({
                show: true,
                severity: "error",
                message: "Please allow location access.",
              });
            }
          })
        }
        // disabled={!ready}
        onChange={(e) => {
          setValue(e.target.value);
          formikProps.handleChange(e);
          formikProps.resetForm({
            values: {
              ...formikProps.values,
              address: e.target.value,
              selectedLatLng: { lat: null, lng: null },
              country: "",
              postalCode: "",
              state: "",
              city: "",
            },
          });
        }}
        placeholder="Type to search for an address"
        name="address"
        section={section}
        errors={formikProps.errors.address}
        touched={formikProps.touched.address}
      />
      <div className="absolute top-[3rem] bg-gray-100 z-10 h-auto w-full text-left text-xs max-h-[20rem] overflow-y-scroll">
        {status === "OK" &&
          focus &&
          data.map(({ place_id, description, structured_formatting }) => (
            <div
              className="py-3 px-5 cursor-pointer hover:bg-gray-200"
              key={place_id}
              onMouseDown={(e) => {
                e.stopPropagation();
                handleSelect(description, formikProps.setFieldValue);
              }}
              onFocus={() => setFocus(true)}
            >
              <div className="text-[0.9rem] font-semibold">
                {structured_formatting.main_text}
              </div>
              <div>{description}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PlacesAutocompleteInput;
