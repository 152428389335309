import { createContext, useContext, useEffect, useState } from "react";

// apis
import { API, graphqlOperation } from "aws-amplify";
import { getCustomerCarts } from "@/graphql/queries";
import {
  addItemToCustomerCart,
  updateItemInCustomerCart,
  removeItemFromCustomerCart,
} from "@/graphql/mutations";
// utils
import Cookies from "universal-cookie";
import produce from "immer";
// contexts
import { useAuth } from "@/contexts/AuthContext";
import MerchantContext from "@/contexts/MerchantContext";

import { setToastState } from "@/states/toastBarState";
import { getShopId } from "@/utils/util";

const CartContext = createContext({
  cart: [],
});

const CartContextProvider = ({ children }) => {
  const merchantInfoContext = useContext(MerchantContext);
  const cookie = new Cookies();
  const { isAuthenticated } = useAuth();
  const [isCartFetching, setIsCartFetching] = useState(true);
  const [cartList, setCartList] = useState([]);

  const signInData = cookie.get(`${getShopId()}@signIn`);

  useEffect(() => {
    getCartList();
  }, [isAuthenticated]);

  // fetch customer cart list
  const getCartList = async () => {
    setIsCartFetching(true);
    const sessionId = cookie.get(`${getShopId()}@sessionId`);
    try {
      var params = {
        customerId: sessionId,
        merchantId: merchantInfoContext.merchantId,
      };
      let res = await API.graphql(graphqlOperation(getCustomerCarts, params));
      let responseProductItem = res.data.getCustomerCarts.items;
      setCartList(responseProductItem ?? []);
      setIsCartFetching(false);
    } catch (error) {
      setIsCartFetching(false);
    }
  };

  // remove item from cart
  const removeItemCartList = async (customerCartId) => {
    try {
      var params = {
        merchantId: merchantInfoContext.merchantId,
        accessToken: signInData?.accessToken ?? "",
        customerCartId: customerCartId,
      };
      const res = await API.graphql(
        graphqlOperation(removeItemFromCustomerCart, params)
      );
      if (res.data.removeItemFromCustomerCart.status === "true") {
        setCartList(
          cartList.filter((obj) => obj.customerCartId !== customerCartId)
        );
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const updateItemQtyCartList = async (customerCartId, quantity) => {
    try {
      const sessionId = cookie.get(`${getShopId()}@sessionId`);
      var params = {
        merchantId: merchantInfoContext.merchantId,
        customerCartId: customerCartId,
        accessToken: signInData?.accessToken ?? "",
        quantity: quantity,
      };
      const res = await API.graphql(
        graphqlOperation(updateItemInCustomerCart, params)
      );

      if (res.data.updateItemInCustomerCart.status === "true") {
        // update by id
        const updatedCartList = produce(cartList, (draft) => {
          const index = draft.findIndex(
            (obj) => obj.customerCartId === customerCartId
          );
          if (index !== -1) {
            draft[index].quantity = quantity;
            draft[index].subtotal = quantity * draft[index].deliveryPrice;
            draft[index].subtotalWithTax =
              quantity * draft[index].deliveryPriceWithTax;
          }
        });

        setCartList(updatedCartList);
        return true;
      } else if (res.data.updateItemInCustomerCart.status === "false") {
        setToastState({
          show: true,
          severity: "error",
          message: res.data.updateItemInCustomerCart.message,
        });
        return false;
      }
    } catch (error) {
      setToastState({
        show: true,
        severity: "error",
        message: "An unexpected error occurred, please try again later.",
      });
    }
  };

  // add new item to cart
  const addItemCartList = async (
    itemId,
    quantity,
    itemProperty,
    orderType,
    selectedModifierGroups = []
  ) => {
    try {
      const sessionId = cookie.get(`${getShopId()}@sessionId`);
      let params = {
        merchantId: merchantInfoContext.merchantId,
        accessToken: signInData?.accessToken ?? "",
        mandatoryItem: true,
        itemId: itemId,
        quantity: quantity,
        customerId: sessionId,
        itemProperty: itemProperty,
        selectedModifierGroups: selectedModifierGroups,
        type: orderType,
      };
      let res = await API.graphql(
        graphqlOperation(addItemToCustomerCart, params)
      );

      const resData = res.data.addItemToCustomerCart;
      if (resData?.status) {
        // to update the list
        getCartList();
        setToastState({
          show: true,
          severity: "success",
          message: res.data.addItemToCustomerCart.message,
        });
        return resData;
      } else {
        setToastState({
          show: true,
          severity: "error",
          message: res.data.addItemToCustomerCart.message,
        });
        return resData;
      }
    } catch (error) {
      return null;
    }
  };

  return (
    <CartContext.Provider
      value={{
        getCartList,
        cartList,
        setCartList,
        removeItemCartList,
        addItemCartList,
        updateItemQtyCartList,
        isCartFetching,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
const useCart = () => {
  return useContext(CartContext);
};
export { CartContext, CartContextProvider, useCart };
