const DineinDialog = ({
  tableNumber,
  setTableNumber,
  ContainedButton,
  isAuthenticated,
}) => {
  return (
    <div className="mt-6 mb-1 h-full">
      <div className="font-bold text-center text-[#636363] text-[24px] mb-2">
        Enter table number
      </div>
      <div>
        <input
          className="m-1 h-20 w- xs:m-2 xs:h-10 xs:w-20 border text-center 
                        border border-grey-500 my-8
                        form-control rounded"
          autoComplete="off"
          type="text"
          value={tableNumber}
          onChange={(e) => setTableNumber(e.target.value)}
          id="tableNumber"
        />
      </div>
      <form>
        <div className="mt-2">
          <ContainedButton
            className="w-full mb-2"
            border="rounded-full"
            // onClick={() => console.log("Confirm")}
          >
            Confirm
          </ContainedButton>
        </div>
      </form>
    </div>
  );
};

export default DineinDialog;
