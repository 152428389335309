import React, { useContext, useEffect, useState } from "react";
import DeliveryIcon from "@/images/delivery-option-icon.png";
import PickupIcon from "@/images/pickup-option-icon.png";
import DineInIcon from "@/images/dinein-option-icon.png";
import Image from "next/image";
import dynamic from "next/dynamic";

// components
import DialogModal from "@/components/dialog/DialogModal";
import { ContainedButton } from "@/components/buttons/Buttons";
import { LoadingIcon } from "@/components/icons/Icons";
import PickupDialog from "@/components/orderModeDialog/PickupDialog";
import DeliveryDialog from "@/components/orderModeDialog/DeliveryDialog";
import DineinDialog from "@/components/orderModeDialog/DineinDialog";
import AddDelivery from "@/components/orderModeDialog/AddDelivery";
import { RoundCloseButton } from "@/components/buttons/Buttons";

//API
import { API, graphqlOperation } from "aws-amplify";
import { getCustomerFavouriteAddresses } from "@/graphql/queries";

// styles
import styles from "./OrderModeDialog.module.scss";

//utils
import Cookies from "universal-cookie";
import { getShopId } from "@/utils/util";

//context
import MerchantContext from "@/contexts/MerchantContext";
import { useOrder } from "@/contexts/OrderContext";
import { ArrowLeftIcon, XIcon } from "@heroicons/react/outline";
import { useAuth } from "@/contexts/AuthContext";
import MobileViewFullScreenModal from "../dialog/MobileViewFullScreenModal/MobileViewFullScreenModal";

import { OrderModalStateActions } from "@/enums/enums";

const OrderModeDialog = (props) => {
  const { orderModalState, dispatchOrderModalState } = props;

  const merchantInfoContext = useContext(MerchantContext);
  const {
    orderType,
    handleChangeOrderType,
    tableNumber,
    setTableNumber,
    googleMapsIsLoaded,
    deliveryAddressList,
    setDeliveryAddressList,
  } = useOrder();
  const { isAuthenticated } = useAuth();

  const availableOrderMode = [
    {
      value: "Delivery",
      name: "Delivery",
      icon: DeliveryIcon,
    },

    {
      value: "DineIn",
      name: "Dine In",
      icon: DineInIcon,
    },
    {
      value: "PickUp",
      name: "Pick Up",
      icon: PickupIcon,
    },
  ];

  const cookie = new Cookies();
  const [isAddressLoading, setIsAddressLoading] = useState(false);
  const [merchantOrderMode, setMerchantOrderMode] = useState([]);
  const { section } = orderModalState;

  const getDeliveryAddressList = async () => {
    setIsAddressLoading(true);

    try {
      var params = {
        accessToken: cookie.get(`${getShopId()}@signIn`)?.accessToken,
        customerId: cookie.get(`${getShopId()}@signIn`)?.customerId,
        merchantId: merchantInfoContext.merchantId,
      };
      let res = await API.graphql(
        graphqlOperation(getCustomerFavouriteAddresses, params)
      );
      if (res && res.data.getCustomerFavouriteAddresses.message === "Success") {
        setDeliveryAddressList(
          res.data.getCustomerFavouriteAddresses.addresses
        );
        localStorage.setItem(
          `${getShopId()}@addressList`,
          JSON.stringify(res.data.getCustomerFavouriteAddresses.addresses)
        );
      }
      setIsAddressLoading(false);
    } catch (error) {
      setIsAddressLoading(false);
    }
  };

  const handleChangeSection = (section, addressDetailToEdit = null) => {
    let orderModalStateType = "";

    switch (section) {
      case "Add":
        orderModalStateType = OrderModalStateActions.ADD_ADDRESS;
        break;
      case "Edit":
        orderModalStateType = OrderModalStateActions.EDIT_ADDRESS;
        break;
      case "Home":
        orderModalStateType = OrderModalStateActions.HOME;
        break;
      default:
        orderModalStateType = OrderModalStateActions.HOME;
    }

    dispatchOrderModalState({
      type: orderModalStateType,
      ...(section === "Edit" && {
        data: {
          addressDetailToEdit: addressDetailToEdit,
        },
      }),
    });
  };

  useEffect(() => {
    getDeliveryAddressList();

    let tempOrderMode = [...availableOrderMode];

    //temporary hardcoded for ac case (only delivery for now)
    if (getShopId() === "fam") {
      tempOrderMode = tempOrderMode.filter(
        (orderMode) => orderMode.value === "Delivery"
      );
    }

    let newOrderMode = tempOrderMode.filter((item) => {
      if (merchantInfoContext.orderOption.includes(item.value)) {
        return item;
      }
    });

    setMerchantOrderMode(newOrderMode);
  }, []);

  return (
    <MobileViewFullScreenModal
      className="bg-white p-5 w-[100%] sm:h-[100%] sm:w-[60%] sm:max-w-[36rem] sm:mt-[2rem] sm:mb-[2rem] sm:rounded-lg"
      open={orderModalState?.view}
      onClose={() =>
        dispatchOrderModalState({
          type: OrderModalStateActions.CLOSE,
        })
      }
    >
      <>
        <div className="flex justify-between cursor-pointer mb-3">
          <div>
            {(section === "Edit" || section === "Add") && (
              <div
                className="w-[5rem] text-left"
                onClick={() => handleChangeSection("Home")}
              >
                <ArrowLeftIcon className="w-6 h-6 text-primary" />
              </div>
            )}
          </div>
          <div
            className="w-[5rem] text-right"
            onClick={() =>
              dispatchOrderModalState({ type: OrderModalStateActions.CLOSE })
            }
          >
            <XIcon className="w-6 h-6 text-primary" />
          </div>
        </div>
        {/* Order Mode Selection */}
        {section !== "Add" && section !== "Edit" ? (
          <div
            className={["justify-center", styles["flex-with-gap"]].join(" ")}
          >
            {merchantOrderMode.map((mode, idx) => (
              <div
                className={`text-center border-[2px] p-2 rounded-md w-full cursor-pointer hover:border-primary ${
                  orderType === mode.value ? "border-primary bg-[#e5f3fb]" : ""
                }`}
                key={idx}
                onClick={() => handleChangeOrderType(mode.value)}
              >
                <>
                  <div>
                    <Image src={mode.icon} width="40px" height="40px" />
                  </div>
                  <div>{mode.name}</div>
                </>
              </div>
            ))}
          </div>
        ) : null}

        {/* Order Mode Details */}
        {orderType === "Delivery" && (
          <DeliveryDialog
            isAddressLoading={isAddressLoading}
            favAddressList={deliveryAddressList}
            setFavAddressList={setDeliveryAddressList}
            LoadingIcon={LoadingIcon}
            ContainedButton={ContainedButton}
            orderType={orderType}
            section={section}
            handleChangeSection={handleChangeSection}
            dispatchOrderModalState={dispatchOrderModalState}
            orderModalState={orderModalState}
            getDeliveryAddressList={getDeliveryAddressList}
            isAuthenticated={isAuthenticated}
            googleMapsIsLoaded={googleMapsIsLoaded}
          />
        )}

        {orderType === "PickUp" && <PickupDialog />}

        {orderType === "DineIn" && (
          <DineinDialog
            tableNumber={tableNumber}
            setTableNumber={setTableNumber}
            ContainedButton={ContainedButton}
            isAuthenticated={isAuthenticated}
          />
        )}
      </>
    </MobileViewFullScreenModal>
  );
};

export default OrderModeDialog;
