/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addItemToCustomerCart = /* GraphQL */ `
  mutation AddItemToCustomerCart(
    $accessToken: String
    $customerId: String
    $itemId: String
    $itemProperty: String
    $mandatoryItem: Boolean
    $merchantId: String
    $quantity: Int
    $selectedModifierGroups: [ModifierGroupsInput]
    $storeId: String
    $storeName: String
    $type: String
  ) {
    addItemToCustomerCart(
      accessToken: $accessToken
      customerId: $customerId
      itemId: $itemId
      itemProperty: $itemProperty
      mandatoryItem: $mandatoryItem
      merchantId: $merchantId
      quantity: $quantity
      selectedModifierGroups: $selectedModifierGroups
      storeId: $storeId
      storeName: $storeName
      type: $type
    ) {
      customerCartId
      message
      status
    }
  }
`;
export const adminAddCollectionsToProduct = /* GraphQL */ `
  mutation AdminAddCollectionsToProduct(
    $collectionList: [String]
    $merchantId: String
    $productIdList: [String]
  ) {
    adminAddCollectionsToProduct(
      collectionList: $collectionList
      merchantId: $merchantId
      productIdList: $productIdList
    ) {
      message
      status
    }
  }
`;
export const adminAddTagsToModule = /* GraphQL */ `
  mutation AdminAddTagsToModule(
    $merchantId: String
    $taggingList: [String]
    $type: String
    $typeIdList: [String]
  ) {
    adminAddTagsToModule(
      merchantId: $merchantId
      taggingList: $taggingList
      type: $type
      typeIdList: $typeIdList
    ) {
      message
      status
    }
  }
`;
export const adminCancelManualPaymentOrder = /* GraphQL */ `
  mutation AdminCancelManualPaymentOrder(
    $merchantId: String
    $orderId: String
    $token: String
  ) {
    adminCancelManualPaymentOrder(
      merchantId: $merchantId
      orderId: $orderId
      token: $token
    ) {
      message
      status
    }
  }
`;
export const adminCancelOrder = /* GraphQL */ `
  mutation AdminCancelOrder(
    $merchantId: String
    $notifyCustomer: Boolean
    $orderDetailsToBeCancelled: [OrderDetailToBeCancelled]
    $orderId: String
    $reason: String
    $restock: Boolean
  ) {
    adminCancelOrder(
      merchantId: $merchantId
      notifyCustomer: $notifyCustomer
      orderDetailsToBeCancelled: $orderDetailsToBeCancelled
      orderId: $orderId
      reason: $reason
      restock: $restock
    ) {
      message
      status
    }
  }
`;
export const adminCheckMarketPlaceTestStatus = /* GraphQL */ `
  mutation AdminCheckMarketPlaceTestStatus($marketPlaceSettingId: String) {
    adminCheckMarketPlaceTestStatus(
      marketPlaceSettingId: $marketPlaceSettingId
    ) {
      message
      status
      storeCode
      storeName
    }
  }
`;
export const adminCheckTopUpShippingPaymentStatus = /* GraphQL */ `
  mutation AdminCheckTopUpShippingPaymentStatus(
    $merchantId: String
    $orderNumber: String
  ) {
    adminCheckTopUpShippingPaymentStatus(
      merchantId: $merchantId
      orderNumber: $orderNumber
    ) {
      message
      status
    }
  }
`;
export const adminCompletePickUpOrder = /* GraphQL */ `
  mutation AdminCompletePickUpOrder(
    $merchantId: String
    $orderId: String
    $token: String
  ) {
    adminCompletePickUpOrder(
      merchantId: $merchantId
      orderId: $orderId
      token: $token
    ) {
      message
      status
    }
  }
`;
export const adminCreateCustomOrder = /* GraphQL */ `
  mutation AdminCreateCustomOrder(
    $billingAddress: String
    $billingCity: String
    $billingCountry: String
    $billingPostalCode: String
    $billingState: String
    $customerEmail: String
    $customerFirstName: String
    $customerId: String
    $customerLastName: String
    $itemList: [CustomOrderItemInput]
    $merchantId: String
    $mobileNo: String
    $remark: String
    $shippingAddress: String
    $shippingCity: String
    $shippingCountry: String
    $shippingPostalCode: String
    $shippingState: String
  ) {
    adminCreateCustomOrder(
      billingAddress: $billingAddress
      billingCity: $billingCity
      billingCountry: $billingCountry
      billingPostalCode: $billingPostalCode
      billingState: $billingState
      customerEmail: $customerEmail
      customerFirstName: $customerFirstName
      customerId: $customerId
      customerLastName: $customerLastName
      itemList: $itemList
      merchantId: $merchantId
      mobileNo: $mobileNo
      remark: $remark
      shippingAddress: $shippingAddress
      shippingCity: $shippingCity
      shippingCountry: $shippingCountry
      shippingPostalCode: $shippingPostalCode
      shippingState: $shippingState
    ) {
      message
      status
    }
  }
`;
export const adminCreateFacebookLiveCampaign = /* GraphQL */ `
  mutation AdminCreateFacebookLiveCampaign(
    $facebookLiveCampaignDetails: [FacebookLiveCampaignDetailInput]
    $facebookLiveCampaignId: String
    $merchantId: String
    $salesChannelName: String
    $scheduleEndDateTime: String
    $scheduleStartDateTime: String
    $status: String
    $storeId: String
    $storeName: String
    $title: String
  ) {
    adminCreateFacebookLiveCampaign(
      facebookLiveCampaignDetails: $facebookLiveCampaignDetails
      facebookLiveCampaignId: $facebookLiveCampaignId
      merchantId: $merchantId
      salesChannelName: $salesChannelName
      scheduleEndDateTime: $scheduleEndDateTime
      scheduleStartDateTime: $scheduleStartDateTime
      status: $status
      storeId: $storeId
      storeName: $storeName
      title: $title
    ) {
      createdAt
      createdBy
      facebookLiveCampaignId
      merchantId
      message
      salesChannelName
      scheduleEndDateTime
      scheduleStartDateTime
      status
      storeId
      storeName
      title
      updatedAt
      updatedBy
    }
  }
`;
export const adminCreateFaq = /* GraphQL */ `
  mutation AdminCreateFaq(
    $answer: String
    $faqCategory: String
    $merchantId: String
    $parentId: String
    $question: String
    $sequence: Int
  ) {
    adminCreateFaq(
      answer: $answer
      faqCategory: $faqCategory
      merchantId: $merchantId
      parentId: $parentId
      question: $question
      sequence: $sequence
    ) {
      message
      status
    }
  }
`;
export const adminCreateFaqCategory = /* GraphQL */ `
  mutation AdminCreateFaqCategory(
    $merchantId: String
    $sequence: Int
    $title: String
  ) {
    adminCreateFaqCategory(
      merchantId: $merchantId
      sequence: $sequence
      title: $title
    ) {
      message
      status
    }
  }
`;
export const adminCreateFilterTab = /* GraphQL */ `
  mutation AdminCreateFilterTab(
    $condition: String
    $merchantId: String
    $title: String
    $type: String
  ) {
    adminCreateFilterTab(
      condition: $condition
      merchantId: $merchantId
      title: $title
      type: $type
    ) {
      message
      status
    }
  }
`;
export const adminCreateHomeCollection = /* GraphQL */ `
  mutation AdminCreateHomeCollection(
    $collectionIcon: String
    $collectionId: String
    $collectionImage: String
    $collectionSeoUrl: String
    $effectiveEndDateTime: String
    $effectiveStartDateTime: String
    $homeCollectionType: String
    $isDisabled: Boolean
    $merchantId: String
    $sequence: Int
    $title: String
  ) {
    adminCreateHomeCollection(
      collectionIcon: $collectionIcon
      collectionId: $collectionId
      collectionImage: $collectionImage
      collectionSeoUrl: $collectionSeoUrl
      effectiveEndDateTime: $effectiveEndDateTime
      effectiveStartDateTime: $effectiveStartDateTime
      homeCollectionType: $homeCollectionType
      isDisabled: $isDisabled
      merchantId: $merchantId
      sequence: $sequence
      title: $title
    ) {
      message
      status
    }
  }
`;
export const adminCreateLandingPageBanner = /* GraphQL */ `
  mutation AdminCreateLandingPageBanner(
    $buttonAction: String
    $buttonActionValue: String
    $buttonTitle: String
    $description: String
    $detailPageImage: String
    $effectiveEndDateTime: String
    $effectiveStartDateTime: String
    $homeImage: String
    $isDisabled: Boolean
    $merchantId: String
    $seoDescription: String
    $seoTitle: String
    $seoUrl: String
    $sequence: Int
    $title: String
  ) {
    adminCreateLandingPageBanner(
      buttonAction: $buttonAction
      buttonActionValue: $buttonActionValue
      buttonTitle: $buttonTitle
      description: $description
      detailPageImage: $detailPageImage
      effectiveEndDateTime: $effectiveEndDateTime
      effectiveStartDateTime: $effectiveStartDateTime
      homeImage: $homeImage
      isDisabled: $isDisabled
      merchantId: $merchantId
      seoDescription: $seoDescription
      seoTitle: $seoTitle
      seoUrl: $seoUrl
      sequence: $sequence
      title: $title
    ) {
      message
      status
    }
  }
`;
export const adminCreateManualPaymentOption = /* GraphQL */ `
  mutation AdminCreateManualPaymentOption(
    $isBankTransfer: Boolean
    $isDisabled: Boolean
    $manualPaymentMethodName: String
    $merchantId: String
    $paymentInstructions: String
    $qrCode: String
  ) {
    adminCreateManualPaymentOption(
      isBankTransfer: $isBankTransfer
      isDisabled: $isDisabled
      manualPaymentMethodName: $manualPaymentMethodName
      merchantId: $merchantId
      paymentInstructions: $paymentInstructions
      qrCode: $qrCode
    ) {
      message
      status
    }
  }
`;
export const adminCreateMembershipTier = /* GraphQL */ `
  mutation AdminCreateMembershipTier(
    $merchantId: String
    $point: Float
    $title: String
  ) {
    adminCreateMembershipTier(
      merchantId: $merchantId
      point: $point
      title: $title
    ) {
      message
      status
    }
  }
`;
export const adminCreateOrUpdateNotificationSetting = /* GraphQL */ `
  mutation AdminCreateOrUpdateNotificationSetting(
    $emailBody: String
    $emailSubject: String
    $merchantId: String
    $status: String
    $type: String
  ) {
    adminCreateOrUpdateNotificationSetting(
      emailBody: $emailBody
      emailSubject: $emailSubject
      merchantId: $merchantId
      status: $status
      type: $type
    ) {
      message
      status
    }
  }
`;
export const adminCreateOrUpdateWarehouse = /* GraphQL */ `
  mutation AdminCreateOrUpdateWarehouse(
    $address: String
    $city: String
    $country: String
    $isOnDemandDeliveryEnabled: Boolean
    $isOwnTransportEnabled: Boolean
    $isStandardDeliveryEnabled: Boolean
    $maxDeliveryDistance: Float
    $merchantId: String
    $onDemandDefaultVehicleType: String
    $onDemandMaxDistance: Float
    $postalCode: String
    $standardPreferredPartnerName1: String
    $standardPreferredPartnerName2: String
    $state: String
    $warehouseId: String
  ) {
    adminCreateOrUpdateWarehouse(
      address: $address
      city: $city
      country: $country
      isOnDemandDeliveryEnabled: $isOnDemandDeliveryEnabled
      isOwnTransportEnabled: $isOwnTransportEnabled
      isStandardDeliveryEnabled: $isStandardDeliveryEnabled
      maxDeliveryDistance: $maxDeliveryDistance
      merchantId: $merchantId
      onDemandDefaultVehicleType: $onDemandDefaultVehicleType
      onDemandMaxDistance: $onDemandMaxDistance
      postalCode: $postalCode
      standardPreferredPartnerName1: $standardPreferredPartnerName1
      standardPreferredPartnerName2: $standardPreferredPartnerName2
      state: $state
      warehouseId: $warehouseId
    ) {
      message
      status
    }
  }
`;
export const adminCreatePointConversion = /* GraphQL */ `
  mutation AdminCreatePointConversion(
    $merchantId: String
    $pointConversionDetails: [PointConversionInput]
  ) {
    adminCreatePointConversion(
      merchantId: $merchantId
      pointConversionDetails: $pointConversionDetails
    ) {
      message
      status
    }
  }
`;
export const adminCreatePriceGroup = /* GraphQL */ `
  mutation AdminCreatePriceGroup(
    $delivery: Float
    $incrementType: String
    $merchantId: String
    $name: String
    $pickUp: Float
    $storeIds: [String]
  ) {
    adminCreatePriceGroup(
      delivery: $delivery
      incrementType: $incrementType
      merchantId: $merchantId
      name: $name
      pickUp: $pickUp
      storeIds: $storeIds
    ) {
      message
      priceGroupId
      status
    }
  }
`;
export const adminCreateProduct = /* GraphQL */ `
  mutation AdminCreateProduct(
    $code: String
    $collectionNames: [String]
    $cover: String
    $deliveryCompareAtPrice: Float
    $deliveryCostPerItem: Float
    $deliveryPrice: Float
    $deliveryPriceWithTax: Float
    $description: String
    $effectiveEndDateTime: String
    $effectiveStartDateTime: String
    $hasVariant: Boolean
    $image: [String]
    $isDisabled: Boolean
    $isVirtualGoods: Boolean
    $merchantId: String
    $noOfPurchases: Int
    $noOfViews: Int
    $pickupCompareAtPrice: Float
    $pickupCostPerItem: Float
    $pickupPrice: Float
    $pickupPriceWithTax: Float
    $platform: String
    $productUOMs: [ProductUOMInput]
    $promotionEndDateTime: String
    $promotionStartDateTime: String
    $sellOnFacebookStore: Boolean
    $sellOnFoodPanda: Boolean
    $sellOnGrabFood: Boolean
    $sellOnGrabMart: Boolean
    $sellOnInstagram: Boolean
    $sellOnLazada: Boolean
    $sellOnOfflineStore: Boolean
    $sellOnOnlineStore: Boolean
    $sellOnPandaMart: Boolean
    $sellOnShopee: Boolean
    $seoDescription: String
    $seoTitle: String
    $seoUrl: String
    $taggingNames: [String]
    $title: String
    $variantName1: String
    $variantName2: String
    $variantName3: String
    $variantValues1: [String]
    $variantValues2: [String]
    $variantValues3: [String]
    $video: String
    $virtualGoodsExpiredAt: String
    $virtualGoodsExpiryDays: String
  ) {
    adminCreateProduct(
      code: $code
      collectionNames: $collectionNames
      cover: $cover
      deliveryCompareAtPrice: $deliveryCompareAtPrice
      deliveryCostPerItem: $deliveryCostPerItem
      deliveryPrice: $deliveryPrice
      deliveryPriceWithTax: $deliveryPriceWithTax
      description: $description
      effectiveEndDateTime: $effectiveEndDateTime
      effectiveStartDateTime: $effectiveStartDateTime
      hasVariant: $hasVariant
      image: $image
      isDisabled: $isDisabled
      isVirtualGoods: $isVirtualGoods
      merchantId: $merchantId
      noOfPurchases: $noOfPurchases
      noOfViews: $noOfViews
      pickupCompareAtPrice: $pickupCompareAtPrice
      pickupCostPerItem: $pickupCostPerItem
      pickupPrice: $pickupPrice
      pickupPriceWithTax: $pickupPriceWithTax
      platform: $platform
      productUOMs: $productUOMs
      promotionEndDateTime: $promotionEndDateTime
      promotionStartDateTime: $promotionStartDateTime
      sellOnFacebookStore: $sellOnFacebookStore
      sellOnFoodPanda: $sellOnFoodPanda
      sellOnGrabFood: $sellOnGrabFood
      sellOnGrabMart: $sellOnGrabMart
      sellOnInstagram: $sellOnInstagram
      sellOnLazada: $sellOnLazada
      sellOnOfflineStore: $sellOnOfflineStore
      sellOnOnlineStore: $sellOnOnlineStore
      sellOnPandaMart: $sellOnPandaMart
      sellOnShopee: $sellOnShopee
      seoDescription: $seoDescription
      seoTitle: $seoTitle
      seoUrl: $seoUrl
      taggingNames: $taggingNames
      title: $title
      variantName1: $variantName1
      variantName2: $variantName2
      variantName3: $variantName3
      variantValues1: $variantValues1
      variantValues2: $variantValues2
      variantValues3: $variantValues3
      video: $video
      virtualGoodsExpiredAt: $virtualGoodsExpiredAt
      virtualGoodsExpiryDays: $virtualGoodsExpiryDays
    ) {
      message
      status
    }
  }
`;
export const adminCreateProductBundle = /* GraphQL */ `
  mutation AdminCreateProductBundle(
    $barcode: String
    $collectionNames: [String]
    $cover: String
    $deliveryCompareAtPrice: Float
    $deliveryCostPerItem: Float
    $deliveryPrice: Float
    $deliveryPriceWithTax: Float
    $description: String
    $effectiveEndDateTime: String
    $effectiveStartDateTime: String
    $image: [String]
    $isDisabled: Boolean
    $isProductBundleTaxable: Boolean
    $merchantId: String
    $pickupCompareAtPrice: Float
    $pickupCostPerItem: Float
    $pickupPrice: Float
    $pickupPriceWithTax: Float
    $productBundleDetail: [ProductBundleDetailInput]
    $productBundlePricing: [ProductBundlePricingInput]
    $promotionEndDateTime: String
    $promotionStartDateTime: String
    $quantityForSales: Int
    $sellOnFacebookStore: Boolean
    $sellOnFoodPanda: Boolean
    $sellOnGrabFood: Boolean
    $sellOnGrabMart: Boolean
    $sellOnInstagram: Boolean
    $sellOnLazada: Boolean
    $sellOnOfflineStore: Boolean
    $sellOnOnlineStore: Boolean
    $sellOnPandaMart: Boolean
    $sellOnShopee: Boolean
    $seoDescription: String
    $seoTitle: String
    $seoUrl: String
    $shippingDimensionHeight: Float
    $shippingDimensionLength: Float
    $shippingDimensionWidth: Float
    $shippingWeight: Float
    $shippingWeightUnit: String
    $sku: String
    $taggingNames: [String]
    $title: String
    $totalStockQuantity: Int
    $video: String
  ) {
    adminCreateProductBundle(
      barcode: $barcode
      collectionNames: $collectionNames
      cover: $cover
      deliveryCompareAtPrice: $deliveryCompareAtPrice
      deliveryCostPerItem: $deliveryCostPerItem
      deliveryPrice: $deliveryPrice
      deliveryPriceWithTax: $deliveryPriceWithTax
      description: $description
      effectiveEndDateTime: $effectiveEndDateTime
      effectiveStartDateTime: $effectiveStartDateTime
      image: $image
      isDisabled: $isDisabled
      isProductBundleTaxable: $isProductBundleTaxable
      merchantId: $merchantId
      pickupCompareAtPrice: $pickupCompareAtPrice
      pickupCostPerItem: $pickupCostPerItem
      pickupPrice: $pickupPrice
      pickupPriceWithTax: $pickupPriceWithTax
      productBundleDetail: $productBundleDetail
      productBundlePricing: $productBundlePricing
      promotionEndDateTime: $promotionEndDateTime
      promotionStartDateTime: $promotionStartDateTime
      quantityForSales: $quantityForSales
      sellOnFacebookStore: $sellOnFacebookStore
      sellOnFoodPanda: $sellOnFoodPanda
      sellOnGrabFood: $sellOnGrabFood
      sellOnGrabMart: $sellOnGrabMart
      sellOnInstagram: $sellOnInstagram
      sellOnLazada: $sellOnLazada
      sellOnOfflineStore: $sellOnOfflineStore
      sellOnOnlineStore: $sellOnOnlineStore
      sellOnPandaMart: $sellOnPandaMart
      sellOnShopee: $sellOnShopee
      seoDescription: $seoDescription
      seoTitle: $seoTitle
      seoUrl: $seoUrl
      shippingDimensionHeight: $shippingDimensionHeight
      shippingDimensionLength: $shippingDimensionLength
      shippingDimensionWidth: $shippingDimensionWidth
      shippingWeight: $shippingWeight
      shippingWeightUnit: $shippingWeightUnit
      sku: $sku
      taggingNames: $taggingNames
      title: $title
      totalStockQuantity: $totalStockQuantity
      video: $video
    ) {
      message
      status
    }
  }
`;
export const adminCreateProductCollection = /* GraphQL */ `
  mutation AdminCreateProductCollection(
    $banner: String
    $collectionProducts: [ItemToBeUpdated]
    $condition: String
    $conditionType: String
    $description: String
    $icon: String
    $merchantId: String
    $name: String
    $seoDescription: String
    $seoTitle: String
    $seoUrl: String
    $taggingNames: [String]
    $type: String
  ) {
    adminCreateProductCollection(
      banner: $banner
      collectionProducts: $collectionProducts
      condition: $condition
      conditionType: $conditionType
      description: $description
      icon: $icon
      merchantId: $merchantId
      name: $name
      seoDescription: $seoDescription
      seoTitle: $seoTitle
      seoUrl: $seoUrl
      taggingNames: $taggingNames
      type: $type
    ) {
      message
      status
    }
  }
`;
export const adminCreateProductExclusion = /* GraphQL */ `
  mutation AdminCreateProductExclusion(
    $merchantId: String
    $productExclusionDetails: [ProductExclusionInput]
  ) {
    adminCreateProductExclusion(
      merchantId: $merchantId
      productExclusionDetails: $productExclusionDetails
    ) {
      message
      status
    }
  }
`;
export const adminCreatePromoCodeCampaign = /* GraphQL */ `
  mutation AdminCreatePromoCodeCampaign(
    $activeCount: String
    $category: String
    $createdBy: String
    $customerCondition: String
    $deliveryDiscountType: String
    $discountAmountCap: Float
    $discountOnProductValue: Float
    $discountQuantityCap: Int
    $effectiveEndDateTime: String
    $effectiveStartDateTime: String
    $isDisabled: Boolean
    $maxUserUsageLimit: Int
    $merchantId: String
    $minimumCondition: String
    $minimumPurchase: Float
    $minimumQuantity: Int
    $orderType: [String]
    $productConditions: ProductsDiscount
    $productsDiscount: ProductsDiscount
    $promoCode: String
    $promoCodeCampaignId: String
    $remainingUsage: Int
    $specificCustomerTag: String
    $specificCustomers: [String]
    $status: String
    $stores: [String]
    $title: String
    $totalUsageLimit: Int
    $type: String
    $updatedBy: String
  ) {
    adminCreatePromoCodeCampaign(
      activeCount: $activeCount
      category: $category
      createdBy: $createdBy
      customerCondition: $customerCondition
      deliveryDiscountType: $deliveryDiscountType
      discountAmountCap: $discountAmountCap
      discountOnProductValue: $discountOnProductValue
      discountQuantityCap: $discountQuantityCap
      effectiveEndDateTime: $effectiveEndDateTime
      effectiveStartDateTime: $effectiveStartDateTime
      isDisabled: $isDisabled
      maxUserUsageLimit: $maxUserUsageLimit
      merchantId: $merchantId
      minimumCondition: $minimumCondition
      minimumPurchase: $minimumPurchase
      minimumQuantity: $minimumQuantity
      orderType: $orderType
      productConditions: $productConditions
      productsDiscount: $productsDiscount
      promoCode: $promoCode
      promoCodeCampaignId: $promoCodeCampaignId
      remainingUsage: $remainingUsage
      specificCustomerTag: $specificCustomerTag
      specificCustomers: $specificCustomers
      status: $status
      stores: $stores
      title: $title
      totalUsageLimit: $totalUsageLimit
      type: $type
      updatedBy: $updatedBy
    ) {
      message
      status
    }
  }
`;
export const adminCreateSalesChannelStoreSetting = /* GraphQL */ `
  mutation AdminCreateSalesChannelStoreSetting(
    $clientId: String
    $clientSecret: String
    $merchantId: String
    $refreshToken: String
    $salesChannelId: String
    $salesChannelName: String
    $storeName: String
    $syncFrequency: String
  ) {
    adminCreateSalesChannelStoreSetting(
      clientId: $clientId
      clientSecret: $clientSecret
      merchantId: $merchantId
      refreshToken: $refreshToken
      salesChannelId: $salesChannelId
      salesChannelName: $salesChannelName
      storeName: $storeName
      syncFrequency: $syncFrequency
    ) {
      message
      status
    }
  }
`;
export const adminCreateShippingRate = /* GraphQL */ `
  mutation AdminCreateShippingRate(
    $amount: Float
    $conditionType: String
    $estimatedDuration: String
    $maxValue: Float
    $merchantId: String
    $minValue: Float
    $shippingZoneId: String
    $title: String
  ) {
    adminCreateShippingRate(
      amount: $amount
      conditionType: $conditionType
      estimatedDuration: $estimatedDuration
      maxValue: $maxValue
      merchantId: $merchantId
      minValue: $minValue
      shippingZoneId: $shippingZoneId
      title: $title
    ) {
      message
      status
    }
  }
`;
export const adminCreateShippingZone = /* GraphQL */ `
  mutation AdminCreateShippingZone(
    $country: String
    $merchantId: String
    $state: [String]
    $title: String
    $transportType: String
    $warehouseId: String
  ) {
    adminCreateShippingZone(
      country: $country
      merchantId: $merchantId
      state: $state
      title: $title
      transportType: $transportType
      warehouseId: $warehouseId
    ) {
      message
      status
    }
  }
`;
export const adminCreateSiteNavigation = /* GraphQL */ `
  mutation AdminCreateSiteNavigation(
    $effectiveEndDateTime: String
    $effectiveStartDateTime: String
    $isDisabled: Boolean
    $linkValue: String
    $menuType: String
    $merchantId: String
    $navigationType: String
    $openNewTab: Boolean
    $parentId: String
    $sequence: Int
    $title: String
  ) {
    adminCreateSiteNavigation(
      effectiveEndDateTime: $effectiveEndDateTime
      effectiveStartDateTime: $effectiveStartDateTime
      isDisabled: $isDisabled
      linkValue: $linkValue
      menuType: $menuType
      merchantId: $merchantId
      navigationType: $navigationType
      openNewTab: $openNewTab
      parentId: $parentId
      sequence: $sequence
      title: $title
    ) {
      message
      status
    }
  }
`;
export const adminCreateSplashScreen = /* GraphQL */ `
  mutation AdminCreateSplashScreen(
    $buttonAction: String
    $buttonActionValue: String
    $effectiveEndDateTime: String
    $effectiveStartDateTime: String
    $homeImage: String
    $isDisabled: Boolean
    $merchantId: String
    $selectedItem: SelectedItemInSplashScreenInput
    $sequence: Int
    $title: String
  ) {
    adminCreateSplashScreen(
      buttonAction: $buttonAction
      buttonActionValue: $buttonActionValue
      effectiveEndDateTime: $effectiveEndDateTime
      effectiveStartDateTime: $effectiveStartDateTime
      homeImage: $homeImage
      isDisabled: $isDisabled
      merchantId: $merchantId
      selectedItem: $selectedItem
      sequence: $sequence
      title: $title
    ) {
      message
      status
    }
  }
`;
export const adminCreateStockMovement = /* GraphQL */ `
  mutation AdminCreateStockMovement(
    $buyerNotes: String
    $expectedArrivalDate: String
    $merchantId: String
    $referenceNumber: String
    $status: String
    $stockMovementDetails: [StockMovementDetailInput]
    $supplierId: String
    $supplierName: String
    $taggingNames: [String]
    $trackingNumber: String
  ) {
    adminCreateStockMovement(
      buyerNotes: $buyerNotes
      expectedArrivalDate: $expectedArrivalDate
      merchantId: $merchantId
      referenceNumber: $referenceNumber
      status: $status
      stockMovementDetails: $stockMovementDetails
      supplierId: $supplierId
      supplierName: $supplierName
      taggingNames: $taggingNames
      trackingNumber: $trackingNumber
    ) {
      message
      status
    }
  }
`;
export const adminCreateStore = /* GraphQL */ `
  mutation AdminCreateStore(
    $address: String
    $city: String
    $code: String
    $deliveryServiceAvailable: Boolean
    $isDisabled: Boolean
    $items: [CreateStoreItemInput]
    $latitude: String
    $longitude: String
    $managerContact: String
    $managerName: String
    $merchantId: String
    $name: String
    $pickupServiceAvailable: Boolean
    $postalCode: String
    $priceGroupName: String
    $salesChannelName: String
    $state: String
    $storeOperatingHours: [StoreOperatingHourInput]
    $taggings: [String]
  ) {
    adminCreateStore(
      address: $address
      city: $city
      code: $code
      deliveryServiceAvailable: $deliveryServiceAvailable
      isDisabled: $isDisabled
      items: $items
      latitude: $latitude
      longitude: $longitude
      managerContact: $managerContact
      managerName: $managerName
      merchantId: $merchantId
      name: $name
      pickupServiceAvailable: $pickupServiceAvailable
      postalCode: $postalCode
      priceGroupName: $priceGroupName
      salesChannelName: $salesChannelName
      state: $state
      storeOperatingHours: $storeOperatingHours
      taggings: $taggings
    ) {
      message
      status
      storeId
    }
  }
`;
export const adminCreateStoreBlockedOutPeriod = /* GraphQL */ `
  mutation AdminCreateStoreBlockedOutPeriod(
    $endDateTime: String
    $merchantId: String
    $startDateTime: String
    $storeId: String
    $storeType: String
  ) {
    adminCreateStoreBlockedOutPeriod(
      endDateTime: $endDateTime
      merchantId: $merchantId
      startDateTime: $startDateTime
      storeId: $storeId
      storeType: $storeType
    ) {
      message
      status
    }
  }
`;
export const adminCreateStoreOperatingHour = /* GraphQL */ `
  mutation AdminCreateStoreOperatingHour(
    $merchantId: String
    $storeId: String
    $storeOperatingHours: [StoreOperatingHourInput]
  ) {
    adminCreateStoreOperatingHour(
      merchantId: $merchantId
      storeId: $storeId
      storeOperatingHours: $storeOperatingHours
    ) {
      message
      status
    }
  }
`;
export const adminCreateSupplier = /* GraphQL */ `
  mutation AdminCreateSupplier(
    $address: String
    $contact: String
    $contactName: String
    $country: String
    $email: String
    $merchantId: String
    $name: String
  ) {
    adminCreateSupplier(
      address: $address
      contact: $contact
      contactName: $contactName
      country: $country
      email: $email
      merchantId: $merchantId
      name: $name
    ) {
      message
      status
      supplierId
    }
  }
`;
export const adminCreateUserMatrix = /* GraphQL */ `
  mutation AdminCreateUserMatrix(
    $email: String
    $merchantId: String
    $name: String
    $userMatrixes: [UserMatrixInput]
  ) {
    adminCreateUserMatrix(
      email: $email
      merchantId: $merchantId
      name: $name
      userMatrixes: $userMatrixes
    ) {
      message
      status
    }
  }
`;
export const adminCreateVoucher = /* GraphQL */ `
  mutation AdminCreateVoucher(
    $acquireOptions: AcquireMapping
    $activeCount: String
    $category: String
    $customerCondition: String
    $deliveryDiscountType: String
    $discountAmountCap: Float
    $discountOnProductValue: Float
    $discountQuantityCap: Int
    $effectiveEndDateTime: String
    $effectiveStartDateTime: String
    $maxUserUsageLimit: Int
    $merchantId: String
    $minimumAmount: Float
    $minimumCondition: String
    $minimumQuantity: Int
    $orderType: [String]
    $productConditions: ConditionMapping
    $productsDiscount: ConditionMapping
    $salesChannelName: ConditionMapping
    $status: String
    $stores: [String]
    $title: String
    $totalUsageLimit: Int
    $type: String
    $voucherBanner: String
    $voucherCode: String
    $voucherIcon: String
    $voucherId: String
  ) {
    adminCreateVoucher(
      acquireOptions: $acquireOptions
      activeCount: $activeCount
      category: $category
      customerCondition: $customerCondition
      deliveryDiscountType: $deliveryDiscountType
      discountAmountCap: $discountAmountCap
      discountOnProductValue: $discountOnProductValue
      discountQuantityCap: $discountQuantityCap
      effectiveEndDateTime: $effectiveEndDateTime
      effectiveStartDateTime: $effectiveStartDateTime
      maxUserUsageLimit: $maxUserUsageLimit
      merchantId: $merchantId
      minimumAmount: $minimumAmount
      minimumCondition: $minimumCondition
      minimumQuantity: $minimumQuantity
      orderType: $orderType
      productConditions: $productConditions
      productsDiscount: $productsDiscount
      salesChannelName: $salesChannelName
      status: $status
      stores: $stores
      title: $title
      totalUsageLimit: $totalUsageLimit
      type: $type
      voucherBanner: $voucherBanner
      voucherCode: $voucherCode
      voucherIcon: $voucherIcon
      voucherId: $voucherId
    ) {
      message
      status
    }
  }
`;
export const adminDeleteCustomOrder = /* GraphQL */ `
  mutation AdminDeleteCustomOrder($customOrderId: String, $merchantId: String) {
    adminDeleteCustomOrder(
      customOrderId: $customOrderId
      merchantId: $merchantId
    ) {
      message
      status
    }
  }
`;
export const adminDeleteCustomerCart = /* GraphQL */ `
  mutation AdminDeleteCustomerCart(
    $customerIdList: [String]
    $merchantId: String
  ) {
    adminDeleteCustomerCart(
      customerIdList: $customerIdList
      merchantId: $merchantId
    ) {
      message
      status
    }
  }
`;
export const adminDeleteFacebookLiveCampaign = /* GraphQL */ `
  mutation AdminDeleteFacebookLiveCampaign(
    $facebookLiveCampaignIdList: [String]
    $merchantId: String
  ) {
    adminDeleteFacebookLiveCampaign(
      facebookLiveCampaignIdList: $facebookLiveCampaignIdList
      merchantId: $merchantId
    ) {
      message
      status
    }
  }
`;
export const adminDeleteFacebookLivePage = /* GraphQL */ `
  mutation AdminDeleteFacebookLivePage(
    $merchantId: String
    $storeIdList: [String]
  ) {
    adminDeleteFacebookLivePage(
      merchantId: $merchantId
      storeIdList: $storeIdList
    ) {
      message
      status
    }
  }
`;
export const adminDeleteFaq = /* GraphQL */ `
  mutation AdminDeleteFaq($faqIdList: [String], $merchantId: String) {
    adminDeleteFaq(faqIdList: $faqIdList, merchantId: $merchantId) {
      message
      status
    }
  }
`;
export const adminDeleteFaqCategory = /* GraphQL */ `
  mutation AdminDeleteFaqCategory(
    $faqCategoryIdList: [String]
    $merchantId: String
  ) {
    adminDeleteFaqCategory(
      faqCategoryIdList: $faqCategoryIdList
      merchantId: $merchantId
    ) {
      message
      status
    }
  }
`;
export const adminDeleteGalleryDetail = /* GraphQL */ `
  mutation AdminDeleteGalleryDetail(
    $galleryDetailIds: [String]
    $merchantId: String
  ) {
    adminDeleteGalleryDetail(
      galleryDetailIds: $galleryDetailIds
      merchantId: $merchantId
    ) {
      message
      status
    }
  }
`;
export const adminDeleteHomeCollection = /* GraphQL */ `
  mutation AdminDeleteHomeCollection(
    $homeCollectionIdList: [String]
    $merchantId: String
  ) {
    adminDeleteHomeCollection(
      homeCollectionIdList: $homeCollectionIdList
      merchantId: $merchantId
    ) {
      message
      status
    }
  }
`;
export const adminDeleteLandingPageBanner = /* GraphQL */ `
  mutation AdminDeleteLandingPageBanner(
    $landingPageBannerIdList: [String]
    $merchantId: String
  ) {
    adminDeleteLandingPageBanner(
      landingPageBannerIdList: $landingPageBannerIdList
      merchantId: $merchantId
    ) {
      message
      status
    }
  }
`;
export const adminDeleteManualPaymentOption = /* GraphQL */ `
  mutation AdminDeleteManualPaymentOption(
    $manualPaymentOptionsIdList: [String]
    $mechantId: String
  ) {
    adminDeleteManualPaymentOption(
      manualPaymentOptionsIdList: $manualPaymentOptionsIdList
      mechantId: $mechantId
    ) {
      message
      status
    }
  }
`;
export const adminDeleteMembershipTier = /* GraphQL */ `
  mutation AdminDeleteMembershipTier(
    $membershipTierIdList: [String]
    $merchantId: String
  ) {
    adminDeleteMembershipTier(
      membershipTierIdList: $membershipTierIdList
      merchantId: $merchantId
    ) {
      message
      status
    }
  }
`;
export const adminDeleteOrder = /* GraphQL */ `
  mutation AdminDeleteOrder($merchantId: String, $orderList: [String]) {
    adminDeleteOrder(merchantId: $merchantId, orderList: $orderList) {
      message
      status
    }
  }
`;
export const adminDeletePointConversion = /* GraphQL */ `
  mutation AdminDeletePointConversion(
    $merchantId: String
    $pointConversionIdList: [String]
  ) {
    adminDeletePointConversion(
      merchantId: $merchantId
      pointConversionIdList: $pointConversionIdList
    ) {
      message
      status
    }
  }
`;
export const adminDeletePriceGroup = /* GraphQL */ `
  mutation AdminDeletePriceGroup(
    $merchantId: String
    $priceGroupIds: [String]
  ) {
    adminDeletePriceGroup(
      merchantId: $merchantId
      priceGroupIds: $priceGroupIds
    ) {
      message
      status
    }
  }
`;
export const adminDeleteProduct = /* GraphQL */ `
  mutation AdminDeleteProduct($merchantId: String, $productIdList: [String]) {
    adminDeleteProduct(merchantId: $merchantId, productIdList: $productIdList) {
      message
      status
    }
  }
`;
export const adminDeleteProductBundle = /* GraphQL */ `
  mutation AdminDeleteProductBundle(
    $merchantId: String
    $productBundleIdList: [String]
  ) {
    adminDeleteProductBundle(
      merchantId: $merchantId
      productBundleIdList: $productBundleIdList
    ) {
      message
      status
    }
  }
`;
export const adminDeleteProductCollection = /* GraphQL */ `
  mutation AdminDeleteProductCollection(
    $merchantId: String
    $productCollectionIdList: [String]
  ) {
    adminDeleteProductCollection(
      merchantId: $merchantId
      productCollectionIdList: $productCollectionIdList
    ) {
      message
      status
    }
  }
`;
export const adminDeleteProductExclusion = /* GraphQL */ `
  mutation AdminDeleteProductExclusion(
    $merchantId: String
    $productExclusionId: String
  ) {
    adminDeleteProductExclusion(
      merchantId: $merchantId
      productExclusionId: $productExclusionId
    ) {
      message
      status
    }
  }
`;
export const adminDeletePromoCodeCampaign = /* GraphQL */ `
  mutation AdminDeletePromoCodeCampaign(
    $merchantId: String
    $promoCodeCampaignIdList: [String]
  ) {
    adminDeletePromoCodeCampaign(
      merchantId: $merchantId
      promoCodeCampaignIdList: $promoCodeCampaignIdList
    ) {
      message
      status
    }
  }
`;
export const adminDeleteShippingRate = /* GraphQL */ `
  mutation AdminDeleteShippingRate(
    $merchantId: String
    $shippingRateIdList: [String]
  ) {
    adminDeleteShippingRate(
      merchantId: $merchantId
      shippingRateIdList: $shippingRateIdList
    ) {
      message
      status
    }
  }
`;
export const adminDeleteShippingZone = /* GraphQL */ `
  mutation AdminDeleteShippingZone(
    $merchantId: String
    $shippingZoneId: String
  ) {
    adminDeleteShippingZone(
      merchantId: $merchantId
      shippingZoneId: $shippingZoneId
    ) {
      message
      status
    }
  }
`;
export const adminDeleteSiteNavigation = /* GraphQL */ `
  mutation AdminDeleteSiteNavigation(
    $merchantId: String
    $siteNavigationId: String
  ) {
    adminDeleteSiteNavigation(
      merchantId: $merchantId
      siteNavigationId: $siteNavigationId
    ) {
      message
      status
    }
  }
`;
export const adminDeleteSplashScreen = /* GraphQL */ `
  mutation AdminDeleteSplashScreen(
    $merchantId: String
    $splashScreenIdList: [String]
  ) {
    adminDeleteSplashScreen(
      merchantId: $merchantId
      splashScreenIdList: $splashScreenIdList
    ) {
      message
      status
    }
  }
`;
export const adminDeleteStockMovement = /* GraphQL */ `
  mutation AdminDeleteStockMovement(
    $merchantId: String
    $stockMovementIdList: [String]
  ) {
    adminDeleteStockMovement(
      merchantId: $merchantId
      stockMovementIdList: $stockMovementIdList
    ) {
      message
      status
    }
  }
`;
export const adminDeleteStore = /* GraphQL */ `
  mutation AdminDeleteStore($merchantId: String, $storeIds: [String]) {
    adminDeleteStore(merchantId: $merchantId, storeIds: $storeIds) {
      message
      status
    }
  }
`;
export const adminDeleteSupplier = /* GraphQL */ `
  mutation AdminDeleteSupplier($merchantId: String, $supplierId: String) {
    adminDeleteSupplier(merchantId: $merchantId, supplierId: $supplierId) {
      message
      status
    }
  }
`;
export const adminDeleteTags = /* GraphQL */ `
  mutation AdminDeleteTags(
    $merchantId: String
    $taggingList: [String]
    $type: String
  ) {
    adminDeleteTags(
      merchantId: $merchantId
      taggingList: $taggingList
      type: $type
    ) {
      message
      status
    }
  }
`;
export const adminDeleteUserMatrix = /* GraphQL */ `
  mutation AdminDeleteUserMatrix($merchantId: String, $userId: String) {
    adminDeleteUserMatrix(merchantId: $merchantId, userId: $userId) {
      message
      status
    }
  }
`;
export const adminDeleteVoucher = /* GraphQL */ `
  mutation AdminDeleteVoucher($merchantId: String, $voucherIdList: [String]) {
    adminDeleteVoucher(merchantId: $merchantId, voucherIdList: $voucherIdList) {
      message
      status
    }
  }
`;
export const adminForgotPassword = /* GraphQL */ `
  mutation AdminForgotPassword($merchantId: String, $mobileNo: String) {
    adminForgotPassword(merchantId: $merchantId, mobileNo: $mobileNo) {
      message
      status
    }
  }
`;
export const adminOtpVerification = /* GraphQL */ `
  mutation AdminOtpVerification(
    $merchantId: String
    $merchantOtp: String
    $mobileNo: String
  ) {
    adminOtpVerification(
      merchantId: $merchantId
      merchantOtp: $merchantOtp
      mobileNo: $mobileNo
    ) {
      message
      status
    }
  }
`;
export const adminProcessFacebookLiveSetup = /* GraphQL */ `
  mutation AdminProcessFacebookLiveSetup(
    $accessToken: String
    $appScopedUserId: String
    $merchantId: String
  ) {
    adminProcessFacebookLiveSetup(
      accessToken: $accessToken
      appScopedUserId: $appScopedUserId
      merchantId: $merchantId
    ) {
      message
      status
    }
  }
`;
export const adminRedeemVirtualGoods = /* GraphQL */ `
  mutation AdminRedeemVirtualGoods($code: String, $merchantId: String) {
    adminRedeemVirtualGoods(code: $code, merchantId: $merchantId) {
      message
      status
    }
  }
`;
export const adminRefundOrder = /* GraphQL */ `
  mutation AdminRefundOrder(
    $merchantId: String
    $orderDetailsToBeRefunded: [OrderDetailToBeRefunded]
    $orderId: String
    $paymentType: String
    $reason: String
    $refundType: String
    $restock: Boolean
  ) {
    adminRefundOrder(
      merchantId: $merchantId
      orderDetailsToBeRefunded: $orderDetailsToBeRefunded
      orderId: $orderId
      paymentType: $paymentType
      reason: $reason
      refundType: $refundType
      restock: $restock
    ) {
      message
      status
    }
  }
`;
export const adminRemoveFilterTab = /* GraphQL */ `
  mutation AdminRemoveFilterTab($filterTabId: String, $merchantId: String) {
    adminRemoveFilterTab(filterTabId: $filterTabId, merchantId: $merchantId) {
      message
      status
    }
  }
`;
export const adminRemoveTagsFromModule = /* GraphQL */ `
  mutation AdminRemoveTagsFromModule(
    $merchantId: String
    $taggingList: [String]
    $type: String
    $typeIdList: [String]
  ) {
    adminRemoveTagsFromModule(
      merchantId: $merchantId
      taggingList: $taggingList
      type: $type
      typeIdList: $typeIdList
    ) {
      message
      status
    }
  }
`;
export const adminRequestForDelivery = /* GraphQL */ `
  mutation AdminRequestForDelivery(
    $merchantId: String
    $orderList: [OrderInput]
  ) {
    adminRequestForDelivery(merchantId: $merchantId, orderList: $orderList) {
      message
      status
    }
  }
`;
export const adminResetPassword = /* GraphQL */ `
  mutation AdminResetPassword(
    $isForgot: Boolean
    $merchantId: String
    $password: String
    $previousPassword: String
    $token: String
    $username: String
  ) {
    adminResetPassword(
      isForgot: $isForgot
      merchantId: $merchantId
      password: $password
      previousPassword: $previousPassword
      token: $token
      username: $username
    ) {
      message
      status
    }
  }
`;
export const adminSendFacebookLiveComment = /* GraphQL */ `
  mutation AdminSendFacebookLiveComment(
    $facebookLiveCampaignId: String
    $hotkey: String
    $merchantId: String
    $productName: String
    $storeId: String
  ) {
    adminSendFacebookLiveComment(
      facebookLiveCampaignId: $facebookLiveCampaignId
      hotkey: $hotkey
      merchantId: $merchantId
      productName: $productName
      storeId: $storeId
    ) {
      message
      status
    }
  }
`;
export const adminSendOrderReminderToSQS = /* GraphQL */ `
  mutation AdminSendOrderReminderToSQS(
    $merchantId: String
    $orderIdList: [String]
  ) {
    adminSendOrderReminderToSQS(
      merchantId: $merchantId
      orderIdList: $orderIdList
    ) {
      message
      status
    }
  }
`;
export const adminSendPendingCheckoutReminderToSQS = /* GraphQL */ `
  mutation AdminSendPendingCheckoutReminderToSQS(
    $customerIdList: [String]
    $emailBody: String
    $emailSubject: String
    $merchantId: String
  ) {
    adminSendPendingCheckoutReminderToSQS(
      customerIdList: $customerIdList
      emailBody: $emailBody
      emailSubject: $emailSubject
      merchantId: $merchantId
    ) {
      message
      status
    }
  }
`;
export const adminSendPendingPaymentReminderToSQS = /* GraphQL */ `
  mutation AdminSendPendingPaymentReminderToSQS(
    $merchantId: String
    $orderIdList: [String]
  ) {
    adminSendPendingPaymentReminderToSQS(
      merchantId: $merchantId
      orderIdList: $orderIdList
    ) {
      message
      status
    }
  }
`;
export const adminSendPromoCodeToSQS = /* GraphQL */ `
  mutation AdminSendPromoCodeToSQS(
    $customerIdList: [String]
    $emailBody: String
    $emailSubject: String
    $merchantId: String
    $promoCode: String
  ) {
    adminSendPromoCodeToSQS(
      customerIdList: $customerIdList
      emailBody: $emailBody
      emailSubject: $emailSubject
      merchantId: $merchantId
      promoCode: $promoCode
    ) {
      message
      status
    }
  }
`;
export const adminShipOrderLazada = /* GraphQL */ `
  mutation AdminShipOrderLazada(
    $merchantId: String
    $orderNumber: String
    $storeCode: String
  ) {
    adminShipOrderLazada(
      merchantId: $merchantId
      orderNumber: $orderNumber
      storeCode: $storeCode
    ) {
      message
      status
    }
  }
`;
export const adminShipOrderShopee = /* GraphQL */ `
  mutation AdminShipOrderShopee(
    $merchantId: String
    $orderNumber: String
    $storeCode: String
  ) {
    adminShipOrderShopee(
      merchantId: $merchantId
      orderNumber: $orderNumber
      storeCode: $storeCode
    ) {
      message
      status
    }
  }
`;
export const adminSyncDataFromMarketPlace = /* GraphQL */ `
  mutation AdminSyncDataFromMarketPlace(
    $merchantId: String
    $salesChannelId: String
    $salesChannelName: String
  ) {
    adminSyncDataFromMarketPlace(
      merchantId: $merchantId
      salesChannelId: $salesChannelId
      salesChannelName: $salesChannelName
    ) {
      message
      status
    }
  }
`;
export const adminSyncOrderFromMarketPlace = /* GraphQL */ `
  mutation AdminSyncOrderFromMarketPlace(
    $merchantId: String
    $salesChannelId: String
    $salesChannelName: String
    $storeCode: String
    $storeId: String
  ) {
    adminSyncOrderFromMarketPlace(
      merchantId: $merchantId
      salesChannelId: $salesChannelId
      salesChannelName: $salesChannelName
      storeCode: $storeCode
      storeId: $storeId
    ) {
      message
      status
    }
  }
`;
export const adminTestLazadaAuth = /* GraphQL */ `
  mutation AdminTestLazadaAuth($merchantId: String) {
    adminTestLazadaAuth(merchantId: $merchantId) {
      authUrl
      marketPlaceSettingId
      message
      status
    }
  }
`;
export const adminTestShopeeAuth = /* GraphQL */ `
  mutation AdminTestShopeeAuth($merchantId: String) {
    adminTestShopeeAuth(merchantId: $merchantId) {
      authUrl
      marketPlaceSettingId
      message
      status
    }
  }
`;
export const adminTopUpShippingCredits = /* GraphQL */ `
  mutation AdminTopUpShippingCredits($merchantId: String, $topUpAmount: Float) {
    adminTopUpShippingCredits(
      merchantId: $merchantId
      topUpAmount: $topUpAmount
    ) {
      gatewayPaymentParams
      gatewayPaymentUrl
      merchantOrderId
      message
      orderNumber
      status
    }
  }
`;
export const adminTriggerExportCSV = /* GraphQL */ `
  mutation AdminTriggerExportCSV(
    $filter: TriggerExportCsvFilter
    $itemIdList: [String]
    $merchantId: String
    $module: String
    $sort: ElasticSearchSortDirection
  ) {
    adminTriggerExportCSV(
      filter: $filter
      itemIdList: $itemIdList
      merchantId: $merchantId
      module: $module
      sort: $sort
    ) {
      downloadJobId
      message
      status
    }
  }
`;
export const adminUpdateBulkOrderFulfillment = /* GraphQL */ `
  mutation AdminUpdateBulkOrderFulfillment(
    $merchantId: String
    $orderIdListToBeUpdated: [String]
  ) {
    adminUpdateBulkOrderFulfillment(
      merchantId: $merchantId
      orderIdListToBeUpdated: $orderIdListToBeUpdated
    ) {
      message
      status
    }
  }
`;
export const adminUpdateContactUsInfo = /* GraphQL */ `
  mutation AdminUpdateContactUsInfo(
    $contactUsContent: String
    $contactUsFormEnabled: Boolean
    $merchantId: String
    $storeList: [ContactUsStoreInput]
  ) {
    adminUpdateContactUsInfo(
      contactUsContent: $contactUsContent
      contactUsFormEnabled: $contactUsFormEnabled
      merchantId: $merchantId
      storeList: $storeList
    ) {
      message
      status
    }
  }
`;
export const adminUpdateCustomOrder = /* GraphQL */ `
  mutation AdminUpdateCustomOrder(
    $billingAddress: String
    $billingCity: String
    $billingCountry: String
    $billingPostalCode: String
    $billingState: String
    $customOrderId: String
    $customerEmail: String
    $customerFirstName: String
    $customerId: String
    $customerLastName: String
    $itemList: [CustomOrderItemInput]
    $merchantId: String
    $mobileNo: String
    $remark: String
    $shippingAddress: String
    $shippingCity: String
    $shippingCountry: String
    $shippingPostalCode: String
    $shippingState: String
  ) {
    adminUpdateCustomOrder(
      billingAddress: $billingAddress
      billingCity: $billingCity
      billingCountry: $billingCountry
      billingPostalCode: $billingPostalCode
      billingState: $billingState
      customOrderId: $customOrderId
      customerEmail: $customerEmail
      customerFirstName: $customerFirstName
      customerId: $customerId
      customerLastName: $customerLastName
      itemList: $itemList
      merchantId: $merchantId
      mobileNo: $mobileNo
      remark: $remark
      shippingAddress: $shippingAddress
      shippingCity: $shippingCity
      shippingCountry: $shippingCountry
      shippingPostalCode: $shippingPostalCode
      shippingState: $shippingState
    ) {
      message
      status
    }
  }
`;
export const adminUpdateDisplayRecordSequence = /* GraphQL */ `
  mutation AdminUpdateDisplayRecordSequence(
    $merchantId: String
    $noOfHeaders: Int
    $records: [RecordInput]
    $type: String
  ) {
    adminUpdateDisplayRecordSequence(
      merchantId: $merchantId
      noOfHeaders: $noOfHeaders
      records: $records
      type: $type
    ) {
      message
      status
    }
  }
`;
export const adminUpdateFacebookLiveCampaign = /* GraphQL */ `
  mutation AdminUpdateFacebookLiveCampaign(
    $facebookLiveCampaignDetails: [FacebookLiveCampaignDetailInput]
    $facebookLiveCampaignId: String
    $merchantId: String
    $salesChannelName: String
    $scheduleEndDateTime: String
    $scheduleStartDateTime: String
    $status: String
    $storeId: String
    $storeName: String
    $title: String
  ) {
    adminUpdateFacebookLiveCampaign(
      facebookLiveCampaignDetails: $facebookLiveCampaignDetails
      facebookLiveCampaignId: $facebookLiveCampaignId
      merchantId: $merchantId
      salesChannelName: $salesChannelName
      scheduleEndDateTime: $scheduleEndDateTime
      scheduleStartDateTime: $scheduleStartDateTime
      status: $status
      storeId: $storeId
      storeName: $storeName
      title: $title
    ) {
      message
      status
    }
  }
`;
export const adminUpdateFacebookLiveMessageTemplate = /* GraphQL */ `
  mutation AdminUpdateFacebookLiveMessageTemplate(
    $merchantId: String
    $message: FacebookLiveMessageInput
    $messageType: String
  ) {
    adminUpdateFacebookLiveMessageTemplate(
      merchantId: $merchantId
      message: $message
      messageType: $messageType
    ) {
      message
      status
    }
  }
`;
export const adminUpdateFaq = /* GraphQL */ `
  mutation AdminUpdateFaq(
    $answer: String
    $faqCategory: String
    $faqId: String
    $merchantId: String
    $parentId: String
    $question: String
    $sequence: Int
  ) {
    adminUpdateFaq(
      answer: $answer
      faqCategory: $faqCategory
      faqId: $faqId
      merchantId: $merchantId
      parentId: $parentId
      question: $question
      sequence: $sequence
    ) {
      message
      status
    }
  }
`;
export const adminUpdateFaqCategory = /* GraphQL */ `
  mutation AdminUpdateFaqCategory(
    $faqCategoryId: String
    $merchantId: String
    $sequence: Int
    $title: String
  ) {
    adminUpdateFaqCategory(
      faqCategoryId: $faqCategoryId
      merchantId: $merchantId
      sequence: $sequence
      title: $title
    ) {
      message
      status
    }
  }
`;
export const adminUpdateGalleryDetail = /* GraphQL */ `
  mutation AdminUpdateGalleryDetail(
    $galleryDetailId: String
    $merchantId: String
    $name: String
  ) {
    adminUpdateGalleryDetail(
      galleryDetailId: $galleryDetailId
      merchantId: $merchantId
      name: $name
    ) {
      message
      status
    }
  }
`;
export const adminUpdateHomeCollection = /* GraphQL */ `
  mutation AdminUpdateHomeCollection(
    $collectionIcon: String
    $collectionId: String
    $collectionImage: String
    $collectionSeoUrl: String
    $effectiveEndDateTime: String
    $effectiveStartDateTime: String
    $homeCollectionId: String
    $homeCollectionType: String
    $isDisabled: Boolean
    $merchantId: String
    $sequence: Int
    $title: String
  ) {
    adminUpdateHomeCollection(
      collectionIcon: $collectionIcon
      collectionId: $collectionId
      collectionImage: $collectionImage
      collectionSeoUrl: $collectionSeoUrl
      effectiveEndDateTime: $effectiveEndDateTime
      effectiveStartDateTime: $effectiveStartDateTime
      homeCollectionId: $homeCollectionId
      homeCollectionType: $homeCollectionType
      isDisabled: $isDisabled
      merchantId: $merchantId
      sequence: $sequence
      title: $title
    ) {
      message
      status
    }
  }
`;
export const adminUpdateItemDuringFacebookLive = /* GraphQL */ `
  mutation AdminUpdateItemDuringFacebookLive(
    $deliveryPrice: Float
    $facebookLiveCampaignDetailId: String
    $facebookLiveCampaignId: String
    $hotkey: String
    $itemId: String
    $itemProperty: String
    $merchantId: String
    $productId: String
    $productName: String
    $quantity: Int
  ) {
    adminUpdateItemDuringFacebookLive(
      deliveryPrice: $deliveryPrice
      facebookLiveCampaignDetailId: $facebookLiveCampaignDetailId
      facebookLiveCampaignId: $facebookLiveCampaignId
      hotkey: $hotkey
      itemId: $itemId
      itemProperty: $itemProperty
      merchantId: $merchantId
      productId: $productId
      productName: $productName
      quantity: $quantity
    ) {
      message
      status
    }
  }
`;
export const adminUpdateLandingPageBanner = /* GraphQL */ `
  mutation AdminUpdateLandingPageBanner(
    $buttonAction: String
    $buttonActionValue: String
    $buttonTitle: String
    $description: String
    $detailPageImage: String
    $effectiveEndDateTime: String
    $effectiveStartDateTime: String
    $homeImage: String
    $isDisabled: Boolean
    $landingPageBannerId: String
    $merchantId: String
    $seoDescription: String
    $seoTitle: String
    $seoUrl: String
    $sequence: Int
    $title: String
  ) {
    adminUpdateLandingPageBanner(
      buttonAction: $buttonAction
      buttonActionValue: $buttonActionValue
      buttonTitle: $buttonTitle
      description: $description
      detailPageImage: $detailPageImage
      effectiveEndDateTime: $effectiveEndDateTime
      effectiveStartDateTime: $effectiveStartDateTime
      homeImage: $homeImage
      isDisabled: $isDisabled
      landingPageBannerId: $landingPageBannerId
      merchantId: $merchantId
      seoDescription: $seoDescription
      seoTitle: $seoTitle
      seoUrl: $seoUrl
      sequence: $sequence
      title: $title
    ) {
      message
      status
    }
  }
`;
export const adminUpdateLegalPolicy = /* GraphQL */ `
  mutation AdminUpdateLegalPolicy(
    $legalPolicies: [LegalPolicyInput]
    $merchantId: String
  ) {
    adminUpdateLegalPolicy(
      legalPolicies: $legalPolicies
      merchantId: $merchantId
    ) {
      message
      status
    }
  }
`;
export const adminUpdateManualPaymentOption = /* GraphQL */ `
  mutation AdminUpdateManualPaymentOption(
    $isBankTransfer: Boolean
    $isDisabled: Boolean
    $manualPaymentMethodName: String
    $manualPaymentOptionsId: String
    $merchantId: String
    $paymentInstructions: String
    $qrCode: String
  ) {
    adminUpdateManualPaymentOption(
      isBankTransfer: $isBankTransfer
      isDisabled: $isDisabled
      manualPaymentMethodName: $manualPaymentMethodName
      manualPaymentOptionsId: $manualPaymentOptionsId
      merchantId: $merchantId
      paymentInstructions: $paymentInstructions
      qrCode: $qrCode
    ) {
      message
      status
    }
  }
`;
export const adminUpdateManualPaymentOrderStatus = /* GraphQL */ `
  mutation AdminUpdateManualPaymentOrderStatus(
    $manualPaymentMethodName: String
    $merchantId: String
    $newOrderStatus: String
    $orderId: String
  ) {
    adminUpdateManualPaymentOrderStatus(
      manualPaymentMethodName: $manualPaymentMethodName
      merchantId: $merchantId
      newOrderStatus: $newOrderStatus
      orderId: $orderId
    ) {
      message
      status
    }
  }
`;
export const adminUpdateMembershipTier = /* GraphQL */ `
  mutation AdminUpdateMembershipTier(
    $membershipTierId: String
    $merchantId: String
    $point: Float
    $title: String
  ) {
    adminUpdateMembershipTier(
      membershipTierId: $membershipTierId
      merchantId: $merchantId
      point: $point
      title: $title
    ) {
      message
      status
    }
  }
`;
export const adminUpdateMerchantAboutUs = /* GraphQL */ `
  mutation AdminUpdateMerchantAboutUs(
    $aboutUsBanner: String
    $aboutUsDescription: String
    $merchantId: String
  ) {
    adminUpdateMerchantAboutUs(
      aboutUsBanner: $aboutUsBanner
      aboutUsDescription: $aboutUsDescription
      merchantId: $merchantId
    ) {
      message
      status
    }
  }
`;
export const adminUpdateMerchantBusinessInfo = /* GraphQL */ `
  mutation AdminUpdateMerchantBusinessInfo(
    $companyAddress: String
    $companyEmail: String
    $companyName: String
  ) {
    adminUpdateMerchantBusinessInfo(
      companyAddress: $companyAddress
      companyEmail: $companyEmail
      companyName: $companyName
    ) {
      message
      status
    }
  }
`;
export const adminUpdateMerchantGeneralConfig = /* GraphQL */ `
  mutation AdminUpdateMerchantGeneralConfig(
    $address: String
    $currency: String
    $kycBusinessAddress: String
    $kycNRIC: String
    $kycNRICName: String
    $merchantId: String
    $merchantRegistrationNumber: String
    $name: String
    $notificationEmail: String
    $orderOption: [String]
    $ownerEmail: String
    $ownerPhone: String
    $senderEmail: String
    $tax: Float
    $warungStoreStatus: String
    $whatsappNo: String
  ) {
    adminUpdateMerchantGeneralConfig(
      address: $address
      currency: $currency
      kycBusinessAddress: $kycBusinessAddress
      kycNRIC: $kycNRIC
      kycNRICName: $kycNRICName
      merchantId: $merchantId
      merchantRegistrationNumber: $merchantRegistrationNumber
      name: $name
      notificationEmail: $notificationEmail
      orderOption: $orderOption
      ownerEmail: $ownerEmail
      ownerPhone: $ownerPhone
      senderEmail: $senderEmail
      tax: $tax
      warungStoreStatus: $warungStoreStatus
      whatsappNo: $whatsappNo
    ) {
      message
      status
    }
  }
`;
export const adminUpdateMerchantGeneralConfigAndSiteAnalytics = /* GraphQL */ `
  mutation AdminUpdateMerchantGeneralConfigAndSiteAnalytics(
    $effectiveEndDate: String
    $effectiveStartDate: String
    $facebookPixelId: String
    $favicon: String
    $googleAnalyticsId: String
    $logo: String
    $merchantId: String
    $notificationMessage: String
    $seoDescription: String
    $seoTitle: String
  ) {
    adminUpdateMerchantGeneralConfigAndSiteAnalytics(
      effectiveEndDate: $effectiveEndDate
      effectiveStartDate: $effectiveStartDate
      facebookPixelId: $facebookPixelId
      favicon: $favicon
      googleAnalyticsId: $googleAnalyticsId
      logo: $logo
      merchantId: $merchantId
      notificationMessage: $notificationMessage
      seoDescription: $seoDescription
      seoTitle: $seoTitle
    ) {
      message
      status
    }
  }
`;
export const adminUpdateMerchantMembershipTierStatus = /* GraphQL */ `
  mutation AdminUpdateMerchantMembershipTierStatus(
    $membershipTierActivated: Boolean
    $merchantId: String
  ) {
    adminUpdateMerchantMembershipTierStatus(
      membershipTierActivated: $membershipTierActivated
      merchantId: $merchantId
    ) {
      message
      status
    }
  }
`;
export const adminUpdateMerchantPaymentConfig = /* GraphQL */ `
  mutation AdminUpdateMerchantPaymentConfig(
    $manualPayment: ManualPaymentOption
    $merchantId: String
    $paymentGateway: PaymentGateway
  ) {
    adminUpdateMerchantPaymentConfig(
      manualPayment: $manualPayment
      merchantId: $merchantId
      paymentGateway: $paymentGateway
    ) {
      message
      status
    }
  }
`;
export const adminUpdateMerchantSiteFontAndColor = /* GraphQL */ `
  mutation AdminUpdateMerchantSiteFontAndColor(
    $copyright: String
    $facebookName: String
    $facebookUrl: String
    $googlePlayUrl: String
    $instagramName: String
    $instagramUrl: String
    $linkedInName: String
    $linkedInUrl: String
    $logo: String
    $merchantId: String
    $playStoreUrl: String
    $siteColor: String
    $siteFont: String
    $tiktokName: String
    $tiktokUrl: String
    $twitterName: String
    $twitterUrl: String
    $youtubeName: String
    $youtubeUrl: String
  ) {
    adminUpdateMerchantSiteFontAndColor(
      copyright: $copyright
      facebookName: $facebookName
      facebookUrl: $facebookUrl
      googlePlayUrl: $googlePlayUrl
      instagramName: $instagramName
      instagramUrl: $instagramUrl
      linkedInName: $linkedInName
      linkedInUrl: $linkedInUrl
      logo: $logo
      merchantId: $merchantId
      playStoreUrl: $playStoreUrl
      siteColor: $siteColor
      siteFont: $siteFont
      tiktokName: $tiktokName
      tiktokUrl: $tiktokUrl
      twitterName: $twitterName
      twitterUrl: $twitterUrl
      youtubeName: $youtubeName
      youtubeUrl: $youtubeUrl
    ) {
      message
      status
    }
  }
`;
export const adminUpdateNotificationSetting = /* GraphQL */ `
  mutation AdminUpdateNotificationSetting(
    $emailBody: String
    $emailSubject: String
    $merchantId: String
    $notificationSettingId: String
  ) {
    adminUpdateNotificationSetting(
      emailBody: $emailBody
      emailSubject: $emailSubject
      merchantId: $merchantId
      notificationSettingId: $notificationSettingId
    ) {
      message
      status
    }
  }
`;
export const adminUpdateNotificationSettingStatus = /* GraphQL */ `
  mutation AdminUpdateNotificationSettingStatus(
    $merchantId: String
    $status: String
    $type: String
  ) {
    adminUpdateNotificationSettingStatus(
      merchantId: $merchantId
      status: $status
      type: $type
    ) {
      message
      status
    }
  }
`;
export const adminUpdateOrder = /* GraphQL */ `
  mutation AdminUpdateOrder(
    $cancelledBy: String
    $cancelledReason: String
    $fulfillmentStatus: String
    $merchantId: String
    $orderId: String
    $paymentStatus: String
    $sellerNote: String
    $taggings: [String]
  ) {
    adminUpdateOrder(
      cancelledBy: $cancelledBy
      cancelledReason: $cancelledReason
      fulfillmentStatus: $fulfillmentStatus
      merchantId: $merchantId
      orderId: $orderId
      paymentStatus: $paymentStatus
      sellerNote: $sellerNote
      taggings: $taggings
    ) {
      message
      status
    }
  }
`;
export const adminUpdateOrderFulfillment = /* GraphQL */ `
  mutation AdminUpdateOrderFulfillment(
    $merchantId: String
    $orderDetailsToBeFulfilled: [OrderDetailToBeFulfilled]
    $orderId: String
    $salesChannelName: String
  ) {
    adminUpdateOrderFulfillment(
      merchantId: $merchantId
      orderDetailsToBeFulfilled: $orderDetailsToBeFulfilled
      orderId: $orderId
      salesChannelName: $salesChannelName
    ) {
      message
      status
    }
  }
`;
export const adminUpdateOrderStatus = /* GraphQL */ `
  mutation AdminUpdateOrderStatus(
    $merchantId: String
    $ordersToBeUpdated: [OrderToBeUpdated]
  ) {
    adminUpdateOrderStatus(
      merchantId: $merchantId
      ordersToBeUpdated: $ordersToBeUpdated
    ) {
      message
      status
    }
  }
`;
export const adminUpdatePointConversion = /* GraphQL */ `
  mutation AdminUpdatePointConversion(
    $effectiveEndDateTime: String
    $effectiveStartDateTime: String
    $itemId: String
    $itemProperty: String
    $merchantId: String
    $multiplier: Float
    $pointConversionId: String
    $productId: String
    $spent: Float
  ) {
    adminUpdatePointConversion(
      effectiveEndDateTime: $effectiveEndDateTime
      effectiveStartDateTime: $effectiveStartDateTime
      itemId: $itemId
      itemProperty: $itemProperty
      merchantId: $merchantId
      multiplier: $multiplier
      pointConversionId: $pointConversionId
      productId: $productId
      spent: $spent
    ) {
      message
      status
    }
  }
`;
export const adminUpdatePriceGroup = /* GraphQL */ `
  mutation AdminUpdatePriceGroup(
    $delivery: Float
    $incrementType: String
    $merchantId: String
    $name: String
    $pickUp: Float
    $priceGroupId: String
    $storeIds: [String]
  ) {
    adminUpdatePriceGroup(
      delivery: $delivery
      incrementType: $incrementType
      merchantId: $merchantId
      name: $name
      pickUp: $pickUp
      priceGroupId: $priceGroupId
      storeIds: $storeIds
    ) {
      message
      status
    }
  }
`;
export const adminUpdateProduct = /* GraphQL */ `
  mutation AdminUpdateProduct(
    $code: String
    $collectionNames: [String]
    $cover: String
    $deliveryCompareAtPrice: Float
    $deliveryCostPerItem: Float
    $deliveryPrice: Float
    $deliveryPriceWithTax: Float
    $description: String
    $effectiveEndDateTime: String
    $effectiveStartDateTime: String
    $hasVariant: Boolean
    $image: [String]
    $isDisabled: Boolean
    $isVirtualGoods: Boolean
    $merchantId: String
    $noOfPurchases: Int
    $noOfViews: Int
    $pickupCompareAtPrice: Float
    $pickupCostPerItem: Float
    $pickupPrice: Float
    $pickupPriceWithTax: Float
    $platform: String
    $productId: String
    $productUOMs: [ProductUOMInput]
    $promotionEndDateTime: String
    $promotionStartDateTime: String
    $sellOnFacebookStore: Boolean
    $sellOnFoodPanda: Boolean
    $sellOnGrabFood: Boolean
    $sellOnGrabMart: Boolean
    $sellOnInstagram: Boolean
    $sellOnLazada: Boolean
    $sellOnOfflineStore: Boolean
    $sellOnOnlineStore: Boolean
    $sellOnPandaMart: Boolean
    $sellOnShopee: Boolean
    $seoDescription: String
    $seoTitle: String
    $seoUrl: String
    $taggingNames: [String]
    $title: String
    $variantName1: String
    $variantName2: String
    $variantName3: String
    $variantValues1: [String]
    $variantValues2: [String]
    $variantValues3: [String]
    $video: String
    $virtualGoodsExpiredAt: String
    $virtualGoodsExpiryDays: String
  ) {
    adminUpdateProduct(
      code: $code
      collectionNames: $collectionNames
      cover: $cover
      deliveryCompareAtPrice: $deliveryCompareAtPrice
      deliveryCostPerItem: $deliveryCostPerItem
      deliveryPrice: $deliveryPrice
      deliveryPriceWithTax: $deliveryPriceWithTax
      description: $description
      effectiveEndDateTime: $effectiveEndDateTime
      effectiveStartDateTime: $effectiveStartDateTime
      hasVariant: $hasVariant
      image: $image
      isDisabled: $isDisabled
      isVirtualGoods: $isVirtualGoods
      merchantId: $merchantId
      noOfPurchases: $noOfPurchases
      noOfViews: $noOfViews
      pickupCompareAtPrice: $pickupCompareAtPrice
      pickupCostPerItem: $pickupCostPerItem
      pickupPrice: $pickupPrice
      pickupPriceWithTax: $pickupPriceWithTax
      platform: $platform
      productId: $productId
      productUOMs: $productUOMs
      promotionEndDateTime: $promotionEndDateTime
      promotionStartDateTime: $promotionStartDateTime
      sellOnFacebookStore: $sellOnFacebookStore
      sellOnFoodPanda: $sellOnFoodPanda
      sellOnGrabFood: $sellOnGrabFood
      sellOnGrabMart: $sellOnGrabMart
      sellOnInstagram: $sellOnInstagram
      sellOnLazada: $sellOnLazada
      sellOnOfflineStore: $sellOnOfflineStore
      sellOnOnlineStore: $sellOnOnlineStore
      sellOnPandaMart: $sellOnPandaMart
      sellOnShopee: $sellOnShopee
      seoDescription: $seoDescription
      seoTitle: $seoTitle
      seoUrl: $seoUrl
      taggingNames: $taggingNames
      title: $title
      variantName1: $variantName1
      variantName2: $variantName2
      variantName3: $variantName3
      variantValues1: $variantValues1
      variantValues2: $variantValues2
      variantValues3: $variantValues3
      video: $video
      virtualGoodsExpiredAt: $virtualGoodsExpiredAt
      virtualGoodsExpiryDays: $virtualGoodsExpiryDays
    ) {
      message
      status
    }
  }
`;
export const adminUpdateProductBundle = /* GraphQL */ `
  mutation AdminUpdateProductBundle(
    $barcode: String
    $collectionNames: [String]
    $cover: String
    $deliveryCompareAtPrice: Float
    $deliveryCostPerItem: Float
    $deliveryPrice: Float
    $deliveryPriceWithTax: Float
    $description: String
    $effectiveEndDateTime: String
    $effectiveStartDateTime: String
    $image: [String]
    $isDisabled: Boolean
    $isProductBundleTaxable: Boolean
    $merchantId: String
    $pickupCompareAtPrice: Float
    $pickupCostPerItem: Float
    $pickupPrice: Float
    $pickupPriceWithTax: Float
    $productBundleDetail: [ProductBundleDetailInput]
    $productBundleId: String
    $productBundlePricing: [ProductBundlePricingInput]
    $promotionEndDateTime: String
    $promotionStartDateTime: String
    $quantityForSales: Int
    $sellOnFacebookStore: Boolean
    $sellOnFoodPanda: Boolean
    $sellOnGrabFood: Boolean
    $sellOnGrabMart: Boolean
    $sellOnInstagram: Boolean
    $sellOnLazada: Boolean
    $sellOnOfflineStore: Boolean
    $sellOnOnlineStore: Boolean
    $sellOnPandaMart: Boolean
    $sellOnShopee: Boolean
    $seoDescription: String
    $seoTitle: String
    $seoUrl: String
    $shippingDimensionHeight: Float
    $shippingDimensionLength: Float
    $shippingDimensionWidth: Float
    $shippingWeight: Float
    $shippingWeightUnit: String
    $sku: String
    $taggingNames: [String]
    $title: String
    $totalStockQuantity: Int
    $video: String
  ) {
    adminUpdateProductBundle(
      barcode: $barcode
      collectionNames: $collectionNames
      cover: $cover
      deliveryCompareAtPrice: $deliveryCompareAtPrice
      deliveryCostPerItem: $deliveryCostPerItem
      deliveryPrice: $deliveryPrice
      deliveryPriceWithTax: $deliveryPriceWithTax
      description: $description
      effectiveEndDateTime: $effectiveEndDateTime
      effectiveStartDateTime: $effectiveStartDateTime
      image: $image
      isDisabled: $isDisabled
      isProductBundleTaxable: $isProductBundleTaxable
      merchantId: $merchantId
      pickupCompareAtPrice: $pickupCompareAtPrice
      pickupCostPerItem: $pickupCostPerItem
      pickupPrice: $pickupPrice
      pickupPriceWithTax: $pickupPriceWithTax
      productBundleDetail: $productBundleDetail
      productBundleId: $productBundleId
      productBundlePricing: $productBundlePricing
      promotionEndDateTime: $promotionEndDateTime
      promotionStartDateTime: $promotionStartDateTime
      quantityForSales: $quantityForSales
      sellOnFacebookStore: $sellOnFacebookStore
      sellOnFoodPanda: $sellOnFoodPanda
      sellOnGrabFood: $sellOnGrabFood
      sellOnGrabMart: $sellOnGrabMart
      sellOnInstagram: $sellOnInstagram
      sellOnLazada: $sellOnLazada
      sellOnOfflineStore: $sellOnOfflineStore
      sellOnOnlineStore: $sellOnOnlineStore
      sellOnPandaMart: $sellOnPandaMart
      sellOnShopee: $sellOnShopee
      seoDescription: $seoDescription
      seoTitle: $seoTitle
      seoUrl: $seoUrl
      shippingDimensionHeight: $shippingDimensionHeight
      shippingDimensionLength: $shippingDimensionLength
      shippingDimensionWidth: $shippingDimensionWidth
      shippingWeight: $shippingWeight
      shippingWeightUnit: $shippingWeightUnit
      sku: $sku
      taggingNames: $taggingNames
      title: $title
      totalStockQuantity: $totalStockQuantity
      video: $video
    ) {
      message
      status
    }
  }
`;
export const adminUpdateProductCollection = /* GraphQL */ `
  mutation AdminUpdateProductCollection(
    $banner: String
    $collectionProducts: [ItemToBeUpdated]
    $condition: String
    $conditionType: String
    $description: String
    $icon: String
    $merchantId: String
    $name: String
    $productCollectionId: String
    $seoDescription: String
    $seoTitle: String
    $seoUrl: String
    $taggingNames: [String]
    $type: String
  ) {
    adminUpdateProductCollection(
      banner: $banner
      collectionProducts: $collectionProducts
      condition: $condition
      conditionType: $conditionType
      description: $description
      icon: $icon
      merchantId: $merchantId
      name: $name
      productCollectionId: $productCollectionId
      seoDescription: $seoDescription
      seoTitle: $seoTitle
      seoUrl: $seoUrl
      taggingNames: $taggingNames
      type: $type
    ) {
      message
      status
    }
  }
`;
export const adminUpdateProductInventory = /* GraphQL */ `
  mutation AdminUpdateProductInventory(
    $merchantId: String
    $productUOMs: [ProductUOMInput]
  ) {
    adminUpdateProductInventory(
      merchantId: $merchantId
      productUOMs: $productUOMs
    ) {
      message
      status
    }
  }
`;
export const adminUpdatePromoCodeCampaign = /* GraphQL */ `
  mutation AdminUpdatePromoCodeCampaign(
    $activeCount: String
    $category: String
    $createdBy: String
    $customerCondition: String
    $deliveryDiscountType: String
    $discountAmountCap: Float
    $discountOnProductValue: Float
    $discountQuantityCap: Int
    $effectiveEndDateTime: String
    $effectiveStartDateTime: String
    $isDisabled: Boolean
    $maxUserUsageLimit: Int
    $merchantId: String
    $minimumCondition: String
    $minimumPurchase: Float
    $minimumQuantity: Int
    $orderType: [String]
    $productConditions: ProductsDiscount
    $productsDiscount: ProductsDiscount
    $promoCode: String
    $promoCodeCampaignId: String
    $remainingUsage: Int
    $specificCustomerTag: String
    $specificCustomers: [String]
    $status: String
    $stores: [String]
    $title: String
    $totalUsageLimit: Int
    $type: String
    $updatedBy: String
  ) {
    adminUpdatePromoCodeCampaign(
      activeCount: $activeCount
      category: $category
      createdBy: $createdBy
      customerCondition: $customerCondition
      deliveryDiscountType: $deliveryDiscountType
      discountAmountCap: $discountAmountCap
      discountOnProductValue: $discountOnProductValue
      discountQuantityCap: $discountQuantityCap
      effectiveEndDateTime: $effectiveEndDateTime
      effectiveStartDateTime: $effectiveStartDateTime
      isDisabled: $isDisabled
      maxUserUsageLimit: $maxUserUsageLimit
      merchantId: $merchantId
      minimumCondition: $minimumCondition
      minimumPurchase: $minimumPurchase
      minimumQuantity: $minimumQuantity
      orderType: $orderType
      productConditions: $productConditions
      productsDiscount: $productsDiscount
      promoCode: $promoCode
      promoCodeCampaignId: $promoCodeCampaignId
      remainingUsage: $remainingUsage
      specificCustomerTag: $specificCustomerTag
      specificCustomers: $specificCustomers
      status: $status
      stores: $stores
      title: $title
      totalUsageLimit: $totalUsageLimit
      type: $type
      updatedBy: $updatedBy
    ) {
      message
      status
    }
  }
`;
export const adminUpdateSalesChannelStoreSetting = /* GraphQL */ `
  mutation AdminUpdateSalesChannelStoreSetting(
    $isDisabled: Boolean
    $marketPlaceSettingId: String
    $merchantId: String
    $salesChannelId: String
    $salesChannelName: String
    $storeCode: String
    $storeId: String
    $storeName: String
    $syncFrequency: String
  ) {
    adminUpdateSalesChannelStoreSetting(
      isDisabled: $isDisabled
      marketPlaceSettingId: $marketPlaceSettingId
      merchantId: $merchantId
      salesChannelId: $salesChannelId
      salesChannelName: $salesChannelName
      storeCode: $storeCode
      storeId: $storeId
      storeName: $storeName
      syncFrequency: $syncFrequency
    ) {
      message
      status
    }
  }
`;
export const adminUpdateShippingRate = /* GraphQL */ `
  mutation AdminUpdateShippingRate(
    $amount: Float
    $conditionType: String
    $estimatedDuration: String
    $maxValue: Float
    $merchantId: String
    $minValue: Float
    $shippingRateId: String
    $title: String
  ) {
    adminUpdateShippingRate(
      amount: $amount
      conditionType: $conditionType
      estimatedDuration: $estimatedDuration
      maxValue: $maxValue
      merchantId: $merchantId
      minValue: $minValue
      shippingRateId: $shippingRateId
      title: $title
    ) {
      message
      status
    }
  }
`;
export const adminUpdateShippingZone = /* GraphQL */ `
  mutation AdminUpdateShippingZone(
    $country: String
    $merchantId: String
    $shippingZoneId: String
    $state: [String]
    $title: String
    $transportType: String
    $warehouseId: String
  ) {
    adminUpdateShippingZone(
      country: $country
      merchantId: $merchantId
      shippingZoneId: $shippingZoneId
      state: $state
      title: $title
      transportType: $transportType
      warehouseId: $warehouseId
    ) {
      message
      status
    }
  }
`;
export const adminUpdateSiteNavigation = /* GraphQL */ `
  mutation AdminUpdateSiteNavigation(
    $effectiveEndDateTime: String
    $effectiveStartDateTime: String
    $isDisabled: Boolean
    $linkValue: String
    $menuType: String
    $merchantId: String
    $navigationType: String
    $openNewTab: Boolean
    $parentId: String
    $sequence: Int
    $siteNavigationId: String
    $title: String
  ) {
    adminUpdateSiteNavigation(
      effectiveEndDateTime: $effectiveEndDateTime
      effectiveStartDateTime: $effectiveStartDateTime
      isDisabled: $isDisabled
      linkValue: $linkValue
      menuType: $menuType
      merchantId: $merchantId
      navigationType: $navigationType
      openNewTab: $openNewTab
      parentId: $parentId
      sequence: $sequence
      siteNavigationId: $siteNavigationId
      title: $title
    ) {
      message
      status
    }
  }
`;
export const adminUpdateSplashScreen = /* GraphQL */ `
  mutation AdminUpdateSplashScreen(
    $buttonAction: String
    $buttonActionValue: String
    $effectiveEndDateTime: String
    $effectiveStartDateTime: String
    $homeImage: String
    $isDisabled: Boolean
    $merchantId: String
    $selectedItem: SelectedItemInSplashScreenInput
    $sequence: Int
    $splashScreenId: String
    $title: String
  ) {
    adminUpdateSplashScreen(
      buttonAction: $buttonAction
      buttonActionValue: $buttonActionValue
      effectiveEndDateTime: $effectiveEndDateTime
      effectiveStartDateTime: $effectiveStartDateTime
      homeImage: $homeImage
      isDisabled: $isDisabled
      merchantId: $merchantId
      selectedItem: $selectedItem
      sequence: $sequence
      splashScreenId: $splashScreenId
      title: $title
    ) {
      message
      status
    }
  }
`;
export const adminUpdateStockMovement = /* GraphQL */ `
  mutation AdminUpdateStockMovement(
    $buyerNotes: String
    $expectedArrivalDate: String
    $merchantId: String
    $referenceNumber: String
    $status: String
    $stockMovementDetails: [StockMovementDetailInput]
    $stockMovementId: String
    $supplierId: String
    $supplierName: String
    $taggingNames: [String]
    $trackingNumber: String
  ) {
    adminUpdateStockMovement(
      buyerNotes: $buyerNotes
      expectedArrivalDate: $expectedArrivalDate
      merchantId: $merchantId
      referenceNumber: $referenceNumber
      status: $status
      stockMovementDetails: $stockMovementDetails
      stockMovementId: $stockMovementId
      supplierId: $supplierId
      supplierName: $supplierName
      taggingNames: $taggingNames
      trackingNumber: $trackingNumber
    ) {
      message
      status
    }
  }
`;
export const adminUpdateStore = /* GraphQL */ `
  mutation AdminUpdateStore(
    $address: String
    $city: String
    $code: String
    $deliveryServiceAvailable: Boolean
    $isDisabled: Boolean
    $items: [UpdateStoreItemInput]
    $latitude: String
    $longitude: String
    $managerContact: String
    $managerName: String
    $merchantId: String
    $name: String
    $pickupServiceAvailable: Boolean
    $postalCode: String
    $priceGroupName: String
    $salesChannelName: String
    $state: String
    $storeOperatingHours: [StoreOperatingHourInput]
    $taggings: [String]
  ) {
    adminUpdateStore(
      address: $address
      city: $city
      code: $code
      deliveryServiceAvailable: $deliveryServiceAvailable
      isDisabled: $isDisabled
      items: $items
      latitude: $latitude
      longitude: $longitude
      managerContact: $managerContact
      managerName: $managerName
      merchantId: $merchantId
      name: $name
      pickupServiceAvailable: $pickupServiceAvailable
      postalCode: $postalCode
      priceGroupName: $priceGroupName
      salesChannelName: $salesChannelName
      state: $state
      storeOperatingHours: $storeOperatingHours
      taggings: $taggings
    ) {
      message
      status
    }
  }
`;
export const adminUpdateStoreBlockedOutPeriod = /* GraphQL */ `
  mutation AdminUpdateStoreBlockedOutPeriod(
    $endDateTime: String
    $merchantId: String
    $startDateTime: String
    $storeId: String
    $storeType: String
  ) {
    adminUpdateStoreBlockedOutPeriod(
      endDateTime: $endDateTime
      merchantId: $merchantId
      startDateTime: $startDateTime
      storeId: $storeId
      storeType: $storeType
    ) {
      message
      status
    }
  }
`;
export const adminUpdateStoreOperatingHour = /* GraphQL */ `
  mutation AdminUpdateStoreOperatingHour(
    $merchantId: String
    $storeId: String
    $storeOperatingHours: [StoreOperatingHourInput]
  ) {
    adminUpdateStoreOperatingHour(
      merchantId: $merchantId
      storeId: $storeId
      storeOperatingHours: $storeOperatingHours
    ) {
      message
      status
    }
  }
`;
export const adminUpdateSupplier = /* GraphQL */ `
  mutation AdminUpdateSupplier(
    $address: String
    $contact: String
    $contactName: String
    $country: String
    $email: String
    $merchantId: String
    $name: String
    $supplierId: String
  ) {
    adminUpdateSupplier(
      address: $address
      contact: $contact
      contactName: $contactName
      country: $country
      email: $email
      merchantId: $merchantId
      name: $name
      supplierId: $supplierId
    ) {
      message
      status
    }
  }
`;
export const adminUpdateTagsOnModule = /* GraphQL */ `
  mutation AdminUpdateTagsOnModule(
    $merchantId: String
    $taggingList: [String]
    $type: String
    $typeIdList: [String]
  ) {
    adminUpdateTagsOnModule(
      merchantId: $merchantId
      taggingList: $taggingList
      type: $type
      typeIdList: $typeIdList
    ) {
      message
      status
      updatedAt
      updatedBy
    }
  }
`;
export const adminUpdateUserMatrix = /* GraphQL */ `
  mutation AdminUpdateUserMatrix(
    $merchantId: String
    $userId: String
    $userMatrixes: [UserMatrixInput]
  ) {
    adminUpdateUserMatrix(
      merchantId: $merchantId
      userId: $userId
      userMatrixes: $userMatrixes
    ) {
      message
      status
    }
  }
`;
export const adminUpdateVoucher = /* GraphQL */ `
  mutation AdminUpdateVoucher(
    $acquireOptions: AcquireMapping
    $activeCount: String
    $category: String
    $customerCondition: String
    $deliveryDiscountType: String
    $discountAmountCap: Float
    $discountOnProductValue: Float
    $discountQuantityCap: Int
    $effectiveEndDateTime: String
    $effectiveStartDateTime: String
    $maxUserUsageLimit: Int
    $merchantId: String
    $minimumAmount: Float
    $minimumCondition: String
    $minimumQuantity: Int
    $orderType: [String]
    $productConditions: ConditionMapping
    $productsDiscount: ConditionMapping
    $salesChannelName: ConditionMapping
    $status: String
    $stores: [String]
    $title: String
    $totalUsageLimit: Int
    $type: String
    $voucherBanner: String
    $voucherCode: String
    $voucherIcon: String
    $voucherId: String
  ) {
    adminUpdateVoucher(
      acquireOptions: $acquireOptions
      activeCount: $activeCount
      category: $category
      customerCondition: $customerCondition
      deliveryDiscountType: $deliveryDiscountType
      discountAmountCap: $discountAmountCap
      discountOnProductValue: $discountOnProductValue
      discountQuantityCap: $discountQuantityCap
      effectiveEndDateTime: $effectiveEndDateTime
      effectiveStartDateTime: $effectiveStartDateTime
      maxUserUsageLimit: $maxUserUsageLimit
      merchantId: $merchantId
      minimumAmount: $minimumAmount
      minimumCondition: $minimumCondition
      minimumQuantity: $minimumQuantity
      orderType: $orderType
      productConditions: $productConditions
      productsDiscount: $productsDiscount
      salesChannelName: $salesChannelName
      status: $status
      stores: $stores
      title: $title
      totalUsageLimit: $totalUsageLimit
      type: $type
      voucherBanner: $voucherBanner
      voucherCode: $voucherCode
      voucherIcon: $voucherIcon
      voucherId: $voucherId
    ) {
      message
      status
    }
  }
`;
export const adminUpdateWarehouseDeliveryConfig = /* GraphQL */ `
  mutation AdminUpdateWarehouseDeliveryConfig(
    $isOwnTransportEnabled: Boolean
    $isStandardDeliveryEnabled: Boolean
    $onDemandDefaultVehicleType: String
    $onDemandMaxDistance: Float
    $standardPreferredPartnerName1: String
    $standardPreferredPartnerName2: String
    $warehouseId: String
  ) {
    adminUpdateWarehouseDeliveryConfig(
      isOwnTransportEnabled: $isOwnTransportEnabled
      isStandardDeliveryEnabled: $isStandardDeliveryEnabled
      onDemandDefaultVehicleType: $onDemandDefaultVehicleType
      onDemandMaxDistance: $onDemandMaxDistance
      standardPreferredPartnerName1: $standardPreferredPartnerName1
      standardPreferredPartnerName2: $standardPreferredPartnerName2
      warehouseId: $warehouseId
    ) {
      message
      status
    }
  }
`;
export const adminUpdateWebhookCallbackConfig = /* GraphQL */ `
  mutation AdminUpdateWebhookCallbackConfig(
    $callbackEvent: String
    $callbackUrl: String
    $webhookCallbackConfigId: String
  ) {
    adminUpdateWebhookCallbackConfig(
      callbackEvent: $callbackEvent
      callbackUrl: $callbackUrl
      webhookCallbackConfigId: $webhookCallbackConfigId
    ) {
      message
      status
    }
  }
`;
export const buyMerchantAddon = /* GraphQL */ `
  mutation BuyMerchantAddon($addOnPackage: [String], $merchantId: String) {
    buyMerchantAddon(addOnPackage: $addOnPackage, merchantId: $merchantId) {
      message
      paymentUrl
      status
    }
  }
`;
export const checkCart = /* GraphQL */ `
  mutation CheckCart(
    $customerCartIds: [String]
    $customerId: String
    $merchantId: String
    $promoCode: String
  ) {
    checkCart(
      customerCartIds: $customerCartIds
      customerId: $customerId
      merchantId: $merchantId
      promoCode: $promoCode
    ) {
      cartItems {
        createdAt
        customOrderId
        customerCartId
        customerFirstName
        customerId
        customerLastName
        customerMobileNo
        customerPrimaryEmail
        deliveryCompareAtPrice
        deliveryPrice
        deliveryPriceWithTax
        errorMessage
        facebookLiveCampaignDetailId
        isFreeItem
        itemId
        itemImage
        itemProperty
        itemSku
        itemTitle
        itemVariantName1
        itemVariantName2
        itemVariantName3
        itemVariantValue1
        itemVariantValue2
        itemVariantValue3
        mandatoryItem
        maxQuantity
        merchantId
        modifierSubtotal
        pickupCompareAtPrice
        pickupPrice
        pickupPriceWithoutTax
        quantity
        salesChannelName
        selectedModifierGroups {
          isSelected
          modifier {
            availableStatus
            isSelected
            modifierId
            modifierName
            price
          }
          modifierGroupId
          modifierGroupName
          modifierGroupType
          requestRemark
          selectionRangeMax
          selectionRangeMin
        }
        shippingWeight
        shippingWeightUnit
        storeId
        storeName
        subtotal
        subtotalWithTax
        type
        updatedAt
      }
      discount
      freeItems {
        createdAt
        customOrderId
        customerCartId
        customerFirstName
        customerId
        customerLastName
        customerMobileNo
        customerPrimaryEmail
        deliveryCompareAtPrice
        deliveryPrice
        deliveryPriceWithTax
        errorMessage
        facebookLiveCampaignDetailId
        isFreeItem
        itemId
        itemImage
        itemProperty
        itemSku
        itemTitle
        itemVariantName1
        itemVariantName2
        itemVariantName3
        itemVariantValue1
        itemVariantValue2
        itemVariantValue3
        mandatoryItem
        maxQuantity
        merchantId
        modifierSubtotal
        pickupCompareAtPrice
        pickupPrice
        pickupPriceWithoutTax
        quantity
        salesChannelName
        selectedModifierGroups {
          isSelected
          modifier {
            availableStatus
            isSelected
            modifierId
            modifierName
            price
          }
          modifierGroupId
          modifierGroupName
          modifierGroupType
          requestRemark
          selectionRangeMax
          selectionRangeMin
        }
        shippingWeight
        shippingWeightUnit
        storeId
        storeName
        subtotal
        subtotalWithTax
        type
        updatedAt
      }
      isFreeDelivery
      message
      promoCodeCategory
      promoCodeValidation
      status
      subtotal
      subtotalWithTax
    }
  }
`;
export const checkPaymentStatus = /* GraphQL */ `
  mutation CheckPaymentStatus($merchantId: String, $orderNumber: String) {
    checkPaymentStatus(merchantId: $merchantId, orderNumber: $orderNumber) {
      message
      status
    }
  }
`;
export const createContactUsEnquiry = /* GraphQL */ `
  mutation CreateContactUsEnquiry(
    $contactEmail: String
    $contactName: String
    $enquiry: String
    $merchantId: String
  ) {
    createContactUsEnquiry(
      contactEmail: $contactEmail
      contactName: $contactName
      enquiry: $enquiry
      merchantId: $merchantId
    ) {
      message
      status
    }
  }
`;
export const createOrderReview = /* GraphQL */ `
  mutation CreateOrderReview(
    $attachment: [String]
    $comment: String
    $customerId: String
    $merchantId: String
    $orderId: String
  ) {
    createOrderReview(
      attachment: $attachment
      comment: $comment
      customerId: $customerId
      merchantId: $merchantId
      orderId: $orderId
    ) {
      message
      status
    }
  }
`;
export const createProductReview = /* GraphQL */ `
  mutation CreateProductReview(
    $comment: String
    $merchantId: String
    $orderDetailId: String
    $rating: Int
    $reviewAsAnonymous: String
  ) {
    createProductReview(
      comment: $comment
      merchantId: $merchantId
      orderDetailId: $orderDetailId
      rating: $rating
      reviewAsAnonymous: $reviewAsAnonymous
    ) {
      message
      status
    }
  }
`;
export const customerSignIn = /* GraphQL */ `
  mutation CustomerSignIn(
    $merchantId: String
    $mobileNo: String
    $oldCustomerId: String
    $password: String
    $platform: String
    $primaryEmail: String
    $username: String
    $version: String
  ) {
    customerSignIn(
      merchantId: $merchantId
      mobileNo: $mobileNo
      oldCustomerId: $oldCustomerId
      password: $password
      platform: $platform
      primaryEmail: $primaryEmail
      username: $username
      version: $version
    ) {
      accessToken
      accountNo
      customerId
      expiredAt
      merchantId
      message
      otpRequired
      refreshToken
      sessionID
      status
    }
  }
`;
export const customerSignOut = /* GraphQL */ `
  mutation CustomerSignOut(
    $accessToken: String
    $accountNo: String
    $merchantId: String
    $refreshToken: String
  ) {
    customerSignOut(
      accessToken: $accessToken
      accountNo: $accountNo
      merchantId: $merchantId
      refreshToken: $refreshToken
    ) {
      accountNo
      customerId
      merchantId
      message
      status
    }
  }
`;
export const customerSignUp = /* GraphQL */ `
  mutation CustomerSignUp(
    $autoLogin: Boolean
    $dateOfBirth: String
    $firstName: String
    $gender: String
    $lastName: String
    $marketingConsent: Boolean
    $merchantId: String
    $mobileNo: String
    $oldCustomerId: String
    $password: String
    $primaryEmail: String
    $promoCode: String
  ) {
    customerSignUp(
      autoLogin: $autoLogin
      dateOfBirth: $dateOfBirth
      firstName: $firstName
      gender: $gender
      lastName: $lastName
      marketingConsent: $marketingConsent
      merchantId: $merchantId
      mobileNo: $mobileNo
      oldCustomerId: $oldCustomerId
      password: $password
      primaryEmail: $primaryEmail
      promoCode: $promoCode
    ) {
      accountNo
      customerId
      merchantId
      message
      signInResponse {
        accessToken
        accountNo
        customerId
        expiredAt
        merchantId
        message
        otpRequired
        refreshToken
        sessionID
        status
      }
      status
    }
  }
`;
export const deleteCustomerFavouriteAddress = /* GraphQL */ `
  mutation DeleteCustomerFavouriteAddress(
    $customerFavouriteAddressId: String
    $merchantId: String
  ) {
    deleteCustomerFavouriteAddress(
      customerFavouriteAddressId: $customerFavouriteAddressId
      merchantId: $merchantId
    ) {
      message
      status
    }
  }
`;
export const forgotPassword = /* GraphQL */ `
  mutation ForgotPassword($merchantId: String, $primaryEmail: String) {
    forgotPassword(merchantId: $merchantId, primaryEmail: $primaryEmail) {
      message
      status
    }
  }
`;
export const generateAdminS3UploadLink = /* GraphQL */ `
  mutation GenerateAdminS3UploadLink($fileName: String) {
    generateAdminS3UploadLink(fileName: $fileName)
  }
`;
export const generateManualPaymentReceiptUploadLink = /* GraphQL */ `
  mutation GenerateManualPaymentReceiptUploadLink(
    $fileName: String
    $manualPaymentMethodName: String
    $orderNumber: String
  ) {
    generateManualPaymentReceiptUploadLink(
      fileName: $fileName
      manualPaymentMethodName: $manualPaymentMethodName
      orderNumber: $orderNumber
    )
  }
`;
export const kdsChangeOrderStatus = /* GraphQL */ `
  mutation KdsChangeOrderStatus(
    $merchantId: String
    $orderId: String
    $status: String
    $token: String
  ) {
    kdsChangeOrderStatus(
      merchantId: $merchantId
      orderId: $orderId
      status: $status
      token: $token
    ) {
      message
      status
    }
  }
`;
export const kdsChangeProductStatus = /* GraphQL */ `
  mutation KdsChangeProductStatus($productId: String) {
    kdsChangeProductStatus(productId: $productId) {
      message
      status
    }
  }
`;
export const kdsDownloadPDFReceipt = /* GraphQL */ `
  mutation KdsDownloadPDFReceipt($orderId: String) {
    kdsDownloadPDFReceipt(orderId: $orderId) {
      message
      status
    }
  }
`;
export const kdsUpdateStoreHours = /* GraphQL */ `
  mutation KdsUpdateStoreHours(
    $listOfStoreOpenCloseHours: [StoreOpenCloseHoursInput]
    $merchantId: String
  ) {
    kdsUpdateStoreHours(
      listOfStoreOpenCloseHours: $listOfStoreOpenCloseHours
      merchantId: $merchantId
    ) {
      message
      status
    }
  }
`;
export const kdsUpdateStoreOrderOption = /* GraphQL */ `
  mutation KdsUpdateStoreOrderOption(
    $merchantId: String
    $orderOption: [String]
  ) {
    kdsUpdateStoreOrderOption(
      merchantId: $merchantId
      orderOption: $orderOption
    ) {
      message
      status
    }
  }
`;
export const lazadaLoginDataMoat = /* GraphQL */ `
  mutation LazadaLoginDataMoat(
    $ati: String
    $loginMessage: String
    $loginResult: String
    $merchantId: String
  ) {
    lazadaLoginDataMoat(
      ati: $ati
      loginMessage: $loginMessage
      loginResult: $loginResult
      merchantId: $merchantId
    ) {
      message
      status
    }
  }
`;
export const oTPVerification = /* GraphQL */ `
  mutation OTPVerification(
    $customerOtp: String
    $merchantId: String
    $primaryEmail: String
  ) {
    oTPVerification(
      customerOtp: $customerOtp
      merchantId: $merchantId
      primaryEmail: $primaryEmail
    ) {
      message
      status
    }
  }
`;
export const placeCustomerOrderToSQS = /* GraphQL */ `
  mutation PlaceCustomerOrderToSQS(
    $accessToken: String
    $billingAddress: String
    $billingCity: String
    $billingCountry: String
    $billingPostalCode: String
    $billingState: String
    $customerAccountNo: String
    $customerCartIds: [String]
    $customerFavouriteAddressId: String
    $customerFavouritePaymentId: String
    $customerFirstName: String
    $customerId: String
    $customerLastName: String
    $customerMobileNo: String
    $customerPrimaryEmail: String
    $deliveryAddress: String
    $deliveryCity: String
    $deliveryCountry: String
    $deliveryLatitude: String
    $deliveryLongitude: String
    $deliveryOptionSelected: String
    $deliveryPostalCode: String
    $deliveryState: String
    $isAdvancedOrder: Boolean
    $isCustomerSignedIn: Boolean
    $merchantId: String
    $noteToRider: String
    $orderNumber: String
    $orderType: String
    $paymentMethod: String
    $paymentType: String
    $platform: String
    $promoCode: String
    $remarks: String
    $requiredCutlery: Boolean
    $salesChannelName: String
    $scheduledDateTime: String
    $storeId: String
    $storeName: String
    $tableNumber: String
  ) {
    placeCustomerOrderToSQS(
      accessToken: $accessToken
      billingAddress: $billingAddress
      billingCity: $billingCity
      billingCountry: $billingCountry
      billingPostalCode: $billingPostalCode
      billingState: $billingState
      customerAccountNo: $customerAccountNo
      customerCartIds: $customerCartIds
      customerFavouriteAddressId: $customerFavouriteAddressId
      customerFavouritePaymentId: $customerFavouritePaymentId
      customerFirstName: $customerFirstName
      customerId: $customerId
      customerLastName: $customerLastName
      customerMobileNo: $customerMobileNo
      customerPrimaryEmail: $customerPrimaryEmail
      deliveryAddress: $deliveryAddress
      deliveryCity: $deliveryCity
      deliveryCountry: $deliveryCountry
      deliveryLatitude: $deliveryLatitude
      deliveryLongitude: $deliveryLongitude
      deliveryOptionSelected: $deliveryOptionSelected
      deliveryPostalCode: $deliveryPostalCode
      deliveryState: $deliveryState
      isAdvancedOrder: $isAdvancedOrder
      isCustomerSignedIn: $isCustomerSignedIn
      merchantId: $merchantId
      noteToRider: $noteToRider
      orderNumber: $orderNumber
      orderType: $orderType
      paymentMethod: $paymentMethod
      paymentType: $paymentType
      platform: $platform
      promoCode: $promoCode
      remarks: $remarks
      requiredCutlery: $requiredCutlery
      salesChannelName: $salesChannelName
      scheduledDateTime: $scheduledDateTime
      storeId: $storeId
      storeName: $storeName
      tableNumber: $tableNumber
    ) {
      message
      messageId
      status
    }
  }
`;
export const registerNewMerchant = /* GraphQL */ `
  mutation RegisterNewMerchant(
    $address: String
    $currency: String
    $domain: String
    $name: String
    $ownerEmail: String
    $ownerName: String
    $ownerPhone: String
  ) {
    registerNewMerchant(
      address: $address
      currency: $currency
      domain: $domain
      name: $name
      ownerEmail: $ownerEmail
      ownerName: $ownerName
      ownerPhone: $ownerPhone
    ) {
      message
      status
    }
  }
`;
export const removeItemFromCustomerCart = /* GraphQL */ `
  mutation RemoveItemFromCustomerCart(
    $accessToken: String
    $customerCartId: String
    $merchantId: String
  ) {
    removeItemFromCustomerCart(
      accessToken: $accessToken
      customerCartId: $customerCartId
      merchantId: $merchantId
    ) {
      message
      status
    }
  }
`;
export const resendOTP = /* GraphQL */ `
  mutation ResendOTP($merchantId: String, $primaryEmail: String) {
    resendOTP(merchantId: $merchantId, primaryEmail: $primaryEmail) {
      message
      status
    }
  }
`;
export const resetPassword = /* GraphQL */ `
  mutation ResetPassword(
    $customerId: String
    $merchantId: String
    $newPassword: String
    $oldPassword: String
    $randomHash: String
  ) {
    resetPassword(
      customerId: $customerId
      merchantId: $merchantId
      newPassword: $newPassword
      oldPassword: $oldPassword
      randomHash: $randomHash
    ) {
      message
      status
    }
  }
`;
export const saveCustomerFavouriteAddress = /* GraphQL */ `
  mutation SaveCustomerFavouriteAddress(
    $address: String
    $city: String
    $country: String
    $customerFavouriteAddressId: String
    $customerId: String
    $isDefaultBilling: Boolean
    $isDefaultShipping: Boolean
    $latitude: String
    $longitude: String
    $merchantId: String
    $name: String
    $postalCode: String
    $state: String
  ) {
    saveCustomerFavouriteAddress(
      address: $address
      city: $city
      country: $country
      customerFavouriteAddressId: $customerFavouriteAddressId
      customerId: $customerId
      isDefaultBilling: $isDefaultBilling
      isDefaultShipping: $isDefaultShipping
      latitude: $latitude
      longitude: $longitude
      merchantId: $merchantId
      name: $name
      postalCode: $postalCode
      state: $state
    ) {
      message
      status
    }
  }
`;
export const triggerOnOffFacebookLive = /* GraphQL */ `
  mutation TriggerOnOffFacebookLive(
    $facebookLiveCampaignId: String
    $merchantId: String
    $storeId: String
  ) {
    triggerOnOffFacebookLive(
      facebookLiveCampaignId: $facebookLiveCampaignId
      merchantId: $merchantId
      storeId: $storeId
    ) {
      message
      status
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $address: String
    $address2: String
    $city: String
    $customerId: String
    $dateOfBirth: String
    $firstName: String
    $gender: String
    $lastName: String
    $marketingConsent: Boolean
    $merchantId: String
    $mobileNo: String
    $newPassword: String
    $postal: String
    $primaryEmail: String
    $state: String
  ) {
    updateCustomer(
      address: $address
      address2: $address2
      city: $city
      customerId: $customerId
      dateOfBirth: $dateOfBirth
      firstName: $firstName
      gender: $gender
      lastName: $lastName
      marketingConsent: $marketingConsent
      merchantId: $merchantId
      mobileNo: $mobileNo
      newPassword: $newPassword
      postal: $postal
      primaryEmail: $primaryEmail
      state: $state
    ) {
      message
      status
    }
  }
`;
export const updateCustomerWishlist = /* GraphQL */ `
  mutation UpdateCustomerWishlist(
    $customerId: String
    $customerWishListId: String
    $itemId: String
    $itemProperty: String
    $merchantId: String
    $salesChannelName: String
  ) {
    updateCustomerWishlist(
      customerId: $customerId
      customerWishListId: $customerWishListId
      itemId: $itemId
      itemProperty: $itemProperty
      merchantId: $merchantId
      salesChannelName: $salesChannelName
    ) {
      message
      status
    }
  }
`;
export const updateItemInCustomerCart = /* GraphQL */ `
  mutation UpdateItemInCustomerCart(
    $accessToken: String
    $customerCartId: String
    $merchantId: String
    $quantity: Int
  ) {
    updateItemInCustomerCart(
      accessToken: $accessToken
      customerCartId: $customerCartId
      merchantId: $merchantId
      quantity: $quantity
    ) {
      message
      status
    }
  }
`;
export const updateMerchantKYC = /* GraphQL */ `
  mutation UpdateMerchantKYC(
    $businessAddress: String
    $email: String
    $ic: String
    $merchantId: String
    $name: String
  ) {
    updateMerchantKYC(
      businessAddress: $businessAddress
      email: $email
      ic: $ic
      merchantId: $merchantId
      name: $name
    ) {
      message
      status
    }
  }
`;
export const updatePreferredLanguage = /* GraphQL */ `
  mutation UpdatePreferredLanguage(
    $merchantId: String
    $preferredLanguage: String
  ) {
    updatePreferredLanguage(
      merchantId: $merchantId
      preferredLanguage: $preferredLanguage
    ) {
      message
      status
    }
  }
`;
export const updateProductModifierSelectionInCustomerCart = /* GraphQL */ `
  mutation UpdateProductModifierSelectionInCustomerCart(
    $accessToken: String
    $customerCartId: String
    $merchantId: String
    $quantity: Int
    $selectedModifierGroups: [ModifierGroupsInput]
  ) {
    updateProductModifierSelectionInCustomerCart(
      accessToken: $accessToken
      customerCartId: $customerCartId
      merchantId: $merchantId
      quantity: $quantity
      selectedModifierGroups: $selectedModifierGroups
    ) {
      message
      status
    }
  }
`;
export const updateProductReview = /* GraphQL */ `
  mutation UpdateProductReview(
    $comment: String
    $merchantId: String
    $orderDetailId: String
    $rating: Int
    $reviewAsAnonymous: String
  ) {
    updateProductReview(
      comment: $comment
      merchantId: $merchantId
      orderDetailId: $orderDetailId
      rating: $rating
      reviewAsAnonymous: $reviewAsAnonymous
    ) {
      message
      status
    }
  }
`;
export const updateStoreSetupStatus = /* GraphQL */ `
  mutation UpdateStoreSetupStatus($acknowledgement: Boolean) {
    updateStoreSetupStatus(acknowledgement: $acknowledgement) {
      message
      status
    }
  }
`;
export const uploadManualPaymentReceipt = /* GraphQL */ `
  mutation UploadManualPaymentReceipt(
    $fileName: String
    $manualPaymentMethodName: String
    $merchantId: String
    $orderNumber: String
  ) {
    uploadManualPaymentReceipt(
      fileName: $fileName
      manualPaymentMethodName: $manualPaymentMethodName
      merchantId: $merchantId
      orderNumber: $orderNumber
    ) {
      message
      status
    }
  }
`;
