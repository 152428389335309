import { Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";
import classes from "./MobileViewFullScreenModal.module.scss";

function MobileViewFullScreenModal({ open, onClose, children, ...props }) {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        onClose={onClose}
        // className={`fixed z-[1110] inset-0 overflow-y-auto ml-auto mr-auto ${props?.className}`}
        className={[
          classes["no-scrollbar"],
          "fixed z-[1110] inset-0 overflow-y-scroll",
        ].join(" ")}
      >
        <div className="flex sm:items-center justify-center min-h-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>
          {/*
          ...and another Transition.Child to apply a separate transition
          to the contents.
        */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className={`relative bg-white ${props.className}`}>
              <div className="text-sm">{children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default MobileViewFullScreenModal;
