/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const adminGetAnalytics = /* GraphQL */ `
  query AdminGetAnalytics(
    $currentPeriodEndDate: String
    $currentPeriodStartDate: String
    $limit: Int
    $merchantId: String
    $nextToken: String
    $prevPeriodEndDate: String
    $prevPeriodStartDate: String
    $reportType: String
    $salesChannel: String
  ) {
    adminGetAnalytics(
      currentPeriodEndDate: $currentPeriodEndDate
      currentPeriodStartDate: $currentPeriodStartDate
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      prevPeriodEndDate: $prevPeriodEndDate
      prevPeriodStartDate: $prevPeriodStartDate
      reportType: $reportType
      salesChannel: $salesChannel
    ) {
      currentPeriod {
        X
        Y
      }
      message
      onlineStoreConversion {
        increaseRate
        percentange
        type
      }
      previousPeriod {
        X
        Y
      }
      reportDetails {
        nextToken
        total
        totalSalesDetails {
          day
          discounts
          grossSale
          month
          netSales
          return
          shipping
          tax
          totalOrder
          totalSales
          year
        }
        totalSession {
          addToCart
          checkoutCart
          conversion
          day
          month
          totalSession
          year
        }
      }
      salesPerformanceOnSalesChannel {
        changes
        title
        value
      }
      status
      topResult {
        description
        title
        totalAmount
      }
    }
  }
`;
export const adminGetContactUsInfo = /* GraphQL */ `
  query AdminGetContactUsInfo($merchantId: String) {
    adminGetContactUsInfo(merchantId: $merchantId) {
      contactUsContent
      contactUsFormEnabled
      message
      status
      storeList {
        address
        city
        contactUsStoreId
        createdAt
        createdBy
        latitude
        longitude
        merchantId
        postalCode
        state
        storeCode
        storeId
        storeName
        updatedAt
        updatedBy
      }
    }
  }
`;
export const adminGetContactUsStore = /* GraphQL */ `
  query AdminGetContactUsStore($contactUsStoreId: String, $merchantId: String) {
    adminGetContactUsStore(
      contactUsStoreId: $contactUsStoreId
      merchantId: $merchantId
    ) {
      item {
        address
        city
        contactUsStoreId
        createdAt
        createdBy
        latitude
        longitude
        merchantId
        postalCode
        state
        storeCode
        storeId
        storeName
        updatedAt
        updatedBy
      }
      message
      status
    }
  }
`;
export const adminGetCustomOrder = /* GraphQL */ `
  query AdminGetCustomOrder($customOrderId: String, $merchantId: String) {
    adminGetCustomOrder(
      customOrderId: $customOrderId
      merchantId: $merchantId
    ) {
      customOrder {
        billingAddress
        billingCity
        billingCountry
        billingPostalCode
        billingState
        cognitoUsername
        createdAt
        createdBy
        customOrderId
        customerFirstName
        customerId
        customerLastName
        itemList {
          itemId
          itemProperty
          itemTitle
          quantity
          subtotal
          type
        }
        merchantId
        mobileNo
        orderId
        orderNumber
        remark
        shippingAddress
        shippingCity
        shippingCountry
        shippingPostalCode
        shippingState
        status
        updatedAt
        updatedBy
      }
      customerCarts {
        createdAt
        customOrderId
        customerCartId
        customerFirstName
        customerId
        customerLastName
        customerMobileNo
        customerPrimaryEmail
        deliveryCompareAtPrice
        deliveryPrice
        deliveryPriceWithTax
        errorMessage
        facebookLiveCampaignDetailId
        isFreeItem
        itemId
        itemImage
        itemProperty
        itemSku
        itemTitle
        itemVariantName1
        itemVariantName2
        itemVariantName3
        itemVariantValue1
        itemVariantValue2
        itemVariantValue3
        mandatoryItem
        maxQuantity
        merchantId
        modifierSubtotal
        pickupCompareAtPrice
        pickupPrice
        pickupPriceWithoutTax
        quantity
        salesChannelName
        selectedModifierGroups {
          isSelected
          modifier {
            availableStatus
            isSelected
            modifierId
            modifierName
            price
          }
          modifierGroupId
          modifierGroupName
          modifierGroupType
          requestRemark
          selectionRangeMax
          selectionRangeMin
        }
        shippingWeight
        shippingWeightUnit
        storeId
        storeName
        subtotal
        subtotalWithTax
        type
        updatedAt
      }
      message
      orderDetails {
        associatedProducts {
          productId
          productImage
          productSku
          productTitle
          productUOMId
          quantity
          variantName1
          variantName2
          variantName3
          variantValue1
          variantValue2
          variantValue3
        }
        comment
        createdAt
        createdBy
        deliveryCompareAtPrice
        deliveryPrice
        deliveryPriceWithTax
        fulfilledQuantity
        isFreeItem
        itemId
        itemImage
        itemIsVirtualGoods
        itemProperty
        itemSku
        itemStatus
        itemTitle
        itemVariantName1
        itemVariantName2
        itemVariantName3
        itemVariantValue1
        itemVariantValue2
        itemVariantValue3
        mandatoryItem
        manualPaymentReceipt
        merchantId
        orderDetailId
        orderId
        orderNumber
        orderedQuantity
        pickupCompareAtPrice
        pickupPrice
        pickupPriceWithTax
        promoCode
        promoCodeDiscount
        rating
        refundQuantity
        selectedModifierGroups {
          isSelected
          modifier {
            availableStatus
            isSelected
            modifierId
            modifierName
            price
          }
          modifierGroupId
          modifierGroupName
          modifierGroupType
          requestRemark
          selectionRangeMax
          selectionRangeMin
        }
        subtotal
        subtotalWithTax
        totalDiscount
        totalPromoCodeDiscount
        totalVoucherDiscount
        updatedAt
        updatedBy
        voucherDiscount
        voucherNumber
      }
      status
    }
  }
`;
export const adminGetCustomer = /* GraphQL */ `
  query AdminGetCustomer($customerId: String, $merchantId: String) {
    adminGetCustomer(customerId: $customerId, merchantId: $merchantId) {
      accountNo
      address
      address2
      city
      country
      dateOfBirth
      firstName
      gender
      lastName
      lastPurchasedDateTime
      marketingConsent
      membershipPoint
      membershipPointExpiryDate
      membershipTier
      message
      mobileNo
      postal
      primaryEmail
      secondaryEmail
      state
      status
      taggingNames
      totalSpent
    }
  }
`;
export const adminGetCustomerCarts = /* GraphQL */ `
  query AdminGetCustomerCarts($customerId: String, $merchantId: String) {
    adminGetCustomerCarts(customerId: $customerId, merchantId: $merchantId) {
      customer {
        accountNo
        activated
        address
        address2
        city
        consentPersonalData
        country
        createdBy
        customerId
        dateOfBirth
        deviceEndpoint
        deviceToken
        emailSubcriptionStatus
        employmentStatus
        facebookID
        firstName
        gender
        hasCompletedProfile
        hasRewarded
        identityCard
        isBlocked
        isFacebook
        lastName
        lastPurchasedDateTime
        maritalStatus
        membershipPoint
        membershipPointExpiryDate
        membershipTier
        merchantId
        mobileNo
        nationality
        password
        personalIncomeLevel
        postal
        primaryEmail
        profilePicture
        qrCode
        race
        secondaryEmail
        signedUpDateTime
        state
        taggingNames
        totalSalesOrder
        totalSpent
        updatedBy
      }
      items {
        createdAt
        customOrderId
        customerCartId
        customerFirstName
        customerId
        customerLastName
        customerMobileNo
        customerPrimaryEmail
        deliveryCompareAtPrice
        deliveryPrice
        deliveryPriceWithTax
        errorMessage
        facebookLiveCampaignDetailId
        isFreeItem
        itemId
        itemImage
        itemProperty
        itemSku
        itemTitle
        itemVariantName1
        itemVariantName2
        itemVariantName3
        itemVariantValue1
        itemVariantValue2
        itemVariantValue3
        mandatoryItem
        maxQuantity
        merchantId
        modifierSubtotal
        pickupCompareAtPrice
        pickupPrice
        pickupPriceWithoutTax
        quantity
        salesChannelName
        selectedModifierGroups {
          isSelected
          modifier {
            availableStatus
            isSelected
            modifierId
            modifierName
            price
          }
          modifierGroupId
          modifierGroupName
          modifierGroupType
          requestRemark
          selectionRangeMax
          selectionRangeMin
        }
        shippingWeight
        shippingWeightUnit
        storeId
        storeName
        subtotal
        subtotalWithTax
        type
        updatedAt
      }
      message
      status
    }
  }
`;
export const adminGetCustomerOrderDetailList = /* GraphQL */ `
  query AdminGetCustomerOrderDetailList($merchantId: String, $orderId: String) {
    adminGetCustomerOrderDetailList(
      merchantId: $merchantId
      orderId: $orderId
    ) {
      message
      order {
        accumulatedRefundAmount
        actualDeliveryFee
        billingAddress
        billingCity
        billingCountry
        billingPostalCode
        billingState
        cancelledBy
        cancelledReason
        createdAt
        createdBy
        customerAccountNo
        customerFirstName
        customerIP
        customerId
        customerLastName
        customerMobileNo
        customerPrimaryEmail
        deliveryAddress
        deliveryCity
        deliveryCountry
        deliveryDiscount
        deliveryLatitude
        deliveryLongitude
        deliveryMode
        deliveryNumber
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartnerName
        deliveryPostalCode
        deliveryState
        deliveryStatus
        deliveryType
        driverName
        driverPhone
        driverPlateNumber
        estimatedDeliveryFee
        grandTotal
        isAdvancedOrder
        isRefunded
        isReviewAvailable
        manualPaymentMethodName
        manualPaymentReceipt
        merchantId
        noteToRider
        orderCancellationDateTime
        orderCollectedDateTime
        orderCompletedDateTime
        orderDateTime
        orderId
        orderNumber
        orderType
        overallRating
        packageNumber
        partialFulfilmentAmount
        partialFulfilmentDiscount
        paymentDateTime
        paymentMethod
        paymentStatus
        paymentType
        pickupAddress
        pickupCity
        pickupCountry
        pickupPostalCode
        pickupState
        promoCode
        promoCodeDiscount
        promoCodeId
        promoCodeTitle
        refundAmount
        refundCompleteDateTime
        refundReason
        remarks
        salesChannelName
        scheduledDateTime
        sellerNote
        standardDeliveryAwb
        standardDeliveryAwbIdLink
        standardDeliveryTrackingUrl
        status
        storeCode
        storeId
        storeName
        subtotal
        subtotalWithTax
        tableNumber
        taggingNames
        totalDiscount
        totalOrderItems
        totalPromoCodeDiscount
        totalRefundedAmount
        totalVoucherDiscount
        transactionId
        type
        updatedAt
        updatedBy
        voucherDiscount
        voucherNumber
      }
      orderDetails {
        associatedProducts {
          productId
          productImage
          productSku
          productTitle
          productUOMId
          quantity
          variantName1
          variantName2
          variantName3
          variantValue1
          variantValue2
          variantValue3
        }
        comment
        createdAt
        createdBy
        deliveryCompareAtPrice
        deliveryPrice
        deliveryPriceWithTax
        fulfilledQuantity
        isFreeItem
        itemId
        itemImage
        itemIsVirtualGoods
        itemProperty
        itemSku
        itemStatus
        itemTitle
        itemVariantName1
        itemVariantName2
        itemVariantName3
        itemVariantValue1
        itemVariantValue2
        itemVariantValue3
        mandatoryItem
        manualPaymentReceipt
        merchantId
        orderDetailId
        orderId
        orderNumber
        orderedQuantity
        pickupCompareAtPrice
        pickupPrice
        pickupPriceWithTax
        promoCode
        promoCodeDiscount
        rating
        refundQuantity
        selectedModifierGroups {
          isSelected
          modifier {
            availableStatus
            isSelected
            modifierId
            modifierName
            price
          }
          modifierGroupId
          modifierGroupName
          modifierGroupType
          requestRemark
          selectionRangeMax
          selectionRangeMin
        }
        subtotal
        subtotalWithTax
        totalDiscount
        totalPromoCodeDiscount
        totalVoucherDiscount
        updatedAt
        updatedBy
        voucherDiscount
        voucherNumber
      }
      status
    }
  }
`;
export const adminGetCustomerOrderList = /* GraphQL */ `
  query AdminGetCustomerOrderList(
    $customerId: String
    $merchantId: String
    $nextToken: Int
    $status: String
  ) {
    adminGetCustomerOrderList(
      customerId: $customerId
      merchantId: $merchantId
      nextToken: $nextToken
      status: $status
    ) {
      message
      nextToken
      orders {
        accumulatedRefundAmount
        actualDeliveryFee
        billingAddress
        billingCity
        billingCountry
        billingPostalCode
        billingState
        cancelledBy
        cancelledReason
        createdAt
        createdBy
        customerAccountNo
        customerFirstName
        customerIP
        customerId
        customerLastName
        customerMobileNo
        customerPrimaryEmail
        deliveryAddress
        deliveryCity
        deliveryCountry
        deliveryDiscount
        deliveryLatitude
        deliveryLongitude
        deliveryMode
        deliveryNumber
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartnerName
        deliveryPostalCode
        deliveryState
        deliveryStatus
        deliveryType
        driverName
        driverPhone
        driverPlateNumber
        estimatedDeliveryFee
        grandTotal
        isAdvancedOrder
        isRefunded
        isReviewAvailable
        manualPaymentMethodName
        manualPaymentReceipt
        merchantId
        noteToRider
        orderCancellationDateTime
        orderCollectedDateTime
        orderCompletedDateTime
        orderDateTime
        orderId
        orderNumber
        orderType
        overallRating
        packageNumber
        partialFulfilmentAmount
        partialFulfilmentDiscount
        paymentDateTime
        paymentMethod
        paymentStatus
        paymentType
        pickupAddress
        pickupCity
        pickupCountry
        pickupPostalCode
        pickupState
        promoCode
        promoCodeDiscount
        promoCodeId
        promoCodeTitle
        refundAmount
        refundCompleteDateTime
        refundReason
        remarks
        salesChannelName
        scheduledDateTime
        sellerNote
        standardDeliveryAwb
        standardDeliveryAwbIdLink
        standardDeliveryTrackingUrl
        status
        storeCode
        storeId
        storeName
        subtotal
        subtotalWithTax
        tableNumber
        taggingNames
        totalDiscount
        totalOrderItems
        totalPromoCodeDiscount
        totalRefundedAmount
        totalVoucherDiscount
        transactionId
        type
        updatedAt
        updatedBy
        voucherDiscount
        voucherNumber
      }
      status
      total
    }
  }
`;
export const adminGetCustomerWishList = /* GraphQL */ `
  query AdminGetCustomerWishList(
    $customerId: String
    $merchantId: String
    $nextToken: String
    $orderType: String
    $storeId: String
  ) {
    adminGetCustomerWishList(
      customerId: $customerId
      merchantId: $merchantId
      nextToken: $nextToken
      orderType: $orderType
      storeId: $storeId
    ) {
      message
      products {
        createdAt
        createdBy
        customerId
        customerWishListId
        deliveryCompareAtPrice
        deliveryPrice
        deliveryPriceWithTax
        itemId
        itemImage
        itemProperty
        itemSeoUrl
        itemSku
        itemTitle
        itemVariantName1
        itemVariantName2
        itemVariantName3
        itemVariantValue1
        itemVariantValue2
        itemVariantValue3
        merchantId
        pickupCompareAtPrice
        pickupPrice
        pickupPriceWithoutTax
        productId
        salesChannelName
        updatedAt
        updatedBy
      }
      status
    }
  }
`;
export const adminGetDownloadJobStatus = /* GraphQL */ `
  query AdminGetDownloadJobStatus($downloadJobId: String, $merchantId: String) {
    adminGetDownloadJobStatus(
      downloadJobId: $downloadJobId
      merchantId: $merchantId
    ) {
      message
      status
      url
    }
  }
`;
export const adminGetFacebookLiveCampaign = /* GraphQL */ `
  query AdminGetFacebookLiveCampaign(
    $facebookLiveCampaignId: String
    $merchantId: String
  ) {
    adminGetFacebookLiveCampaign(
      facebookLiveCampaignId: $facebookLiveCampaignId
      merchantId: $merchantId
    ) {
      createdAt
      createdBy
      embeddedHtmlLink
      facebookLiveCampaignDetails {
        associatedProducts {
          productId
          productImage
          productSku
          productTitle
          productUOMId
          quantity
          variantName1
          variantName2
          variantName3
          variantValue1
          variantValue2
          variantValue3
        }
        commentedQuantity
        createdAt
        createdBy
        deliveryCompareAtPrice
        deliveryPrice
        deliveryPriceWithTax
        facebookLiveCampaignDetailId
        facebookLiveCampaignId
        hotkey
        itemId
        itemImage
        itemProperty
        itemSku
        itemTitle
        itemVariantName1
        itemVariantName2
        itemVariantName3
        itemVariantValue1
        itemVariantValue2
        itemVariantValue3
        merchantId
        productId
        quantity
        updatedAt
        updatedBy
      }
      facebookLiveCampaignId
      merchantId
      salesChannelName
      scheduleEndDateTime
      scheduleStartDateTime
      status
      storeId
      storeName
      title
      updatedAt
      updatedBy
    }
  }
`;
export const adminGetFacebookLiveMessageTemplate = /* GraphQL */ `
  query AdminGetFacebookLiveMessageTemplate($merchantId: String) {
    adminGetFacebookLiveMessageTemplate(merchantId: $merchantId) {
      merchantId
      messageList {
        facebookLiveComment
        facebookMessengerResponseOutOfStock
        facebookMessengerResponsePartialSuccess
        facebookMessengerResponseSuccess
      }
      status
    }
  }
`;
export const adminGetFacebookLiveSalesPerformance = /* GraphQL */ `
  query AdminGetFacebookLiveSalesPerformance(
    $facebookLiveCampaignId: String
    $merchantId: String
  ) {
    adminGetFacebookLiveSalesPerformance(
      facebookLiveCampaignId: $facebookLiveCampaignId
      merchantId: $merchantId
    ) {
      message
      status
      totalOrders
      totalSales
    }
  }
`;
export const adminGetFacebookTokenIndicator = /* GraphQL */ `
  query AdminGetFacebookTokenIndicator($merchantId: String) {
    adminGetFacebookTokenIndicator(merchantId: $merchantId) {
      message
      status
    }
  }
`;
export const adminGetFaq = /* GraphQL */ `
  query AdminGetFaq($faqId: String, $merchantId: String) {
    adminGetFaq(faqId: $faqId, merchantId: $merchantId) {
      item {
        answer
        createdAt
        createdBy
        faqCategory
        faqId
        faqType
        merchantId
        parentId
        question
        sequence
        updatedAt
        updatedBy
      }
      message
      status
    }
  }
`;
export const adminGetFaqCategory = /* GraphQL */ `
  query AdminGetFaqCategory($faqCategoryId: String, $merchantId: String) {
    adminGetFaqCategory(
      faqCategoryId: $faqCategoryId
      merchantId: $merchantId
    ) {
      item {
        childItems {
          answer
          createdAt
          createdBy
          faqCategory
          faqId
          faqType
          merchantId
          parentId
          question
          sequence
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        faqCategoryId
        merchantId
        sequence
        title
        updatedAt
        updatedBy
      }
      message
      status
    }
  }
`;
export const adminGetFilterTab = /* GraphQL */ `
  query AdminGetFilterTab($merchantId: String, $type: String) {
    adminGetFilterTab(merchantId: $merchantId, type: $type) {
      filterTabs {
        condition
        createdAt
        createdBy
        filterTabId
        merchantId
        title
        type
        updatedAt
        updatedBy
        userId
      }
    }
  }
`;
export const adminGetHomeCollection = /* GraphQL */ `
  query AdminGetHomeCollection($homeCollectionId: String, $merchantId: String) {
    adminGetHomeCollection(
      homeCollectionId: $homeCollectionId
      merchantId: $merchantId
    ) {
      homeCollection {
        collectionIcon
        collectionId
        collectionImage
        collectionName
        collectionSeoUrl
        createdAt
        createdBy
        effectiveEndDateTime
        effectiveStartDateTime
        homeCollectionId
        homeCollectionType
        isDisabled
        merchantId
        sequence
        title
        updatedAt
        updatedBy
      }
      message
      status
    }
  }
`;
export const adminGetImportJobStatus = /* GraphQL */ `
  query AdminGetImportJobStatus($fileName: String) {
    adminGetImportJobStatus(fileName: $fileName) {
      errorMessage
      errorRecords
      fileName
      importJobId
      successfulUploads
      uploadStatus
    }
  }
`;
export const adminGetItemQuantityForSales = /* GraphQL */ `
  query AdminGetItemQuantityForSales(
    $itemId: String
    $itemProperty: String
    $merchantId: String
  ) {
    adminGetItemQuantityForSales(
      itemId: $itemId
      itemProperty: $itemProperty
      merchantId: $merchantId
    ) {
      itemId
      message
      quantityForSales
      status
    }
  }
`;
export const adminGetLandingPageBanner = /* GraphQL */ `
  query AdminGetLandingPageBanner(
    $landingPageBannerId: String
    $merchantId: String
  ) {
    adminGetLandingPageBanner(
      landingPageBannerId: $landingPageBannerId
      merchantId: $merchantId
    ) {
      landingPageBanner {
        buttonAction
        buttonActionValue
        buttonTitle
        createdAt
        createdBy
        description
        detailPageImage
        effectiveEndDateTime
        effectiveStartDateTime
        homeImage
        isDisabled
        landingPageBannerId
        merchantId
        selectedItem {
          itemId
          itemImage
          itemProperty
          itemSeoUrl
          itemTitle
        }
        seoDescription
        seoTitle
        seoUrl
        sequence
        title
        updatedAt
        updatedBy
      }
      message
      status
    }
  }
`;
export const adminGetLazadaProductSpec = /* GraphQL */ `
  query AdminGetLazadaProductSpec(
    $merchantId: String
    $parentId: String
    $storeId: String
    $type: String
  ) {
    adminGetLazadaProductSpec(
      merchantId: $merchantId
      parentId: $parentId
      storeId: $storeId
      type: $type
    ) {
      message
      products {
        actualName
        hasChildren
        inputType
        isRequired
        parentId
        type
        typeId
        typeName
        typeValues {
          valueEnglishName
          valueId
          valueName
          valueUnit
        }
      }
      status
    }
  }
`;
export const adminGetLegalPolicy = /* GraphQL */ `
  query AdminGetLegalPolicy($merchantId: String) {
    adminGetLegalPolicy(merchantId: $merchantId) {
      legalPolicies {
        createdAt
        createdBy
        isDisabled
        merchantId
        policyContent
        policyType
        updatedAt
        updatedBy
      }
      message
      status
    }
  }
`;
export const adminGetMerchantAboutUs = /* GraphQL */ `
  query AdminGetMerchantAboutUs($merchantId: String) {
    adminGetMerchantAboutUs(merchantId: $merchantId) {
      aboutUsBanner
      aboutUsDescription
      message
      status
    }
  }
`;
export const adminGetMerchantBusinessInfo = /* GraphQL */ `
  query AdminGetMerchantBusinessInfo {
    adminGetMerchantBusinessInfo {
      companyAddress
      companyEmail
      companyName
      message
      status
    }
  }
`;
export const adminGetMerchantGeneralConfig = /* GraphQL */ `
  query AdminGetMerchantGeneralConfig($merchantId: String) {
    adminGetMerchantGeneralConfig(merchantId: $merchantId) {
      address
      currency
      domain
      kycBusinessAddress
      kycNRIC
      kycNRICName
      merchantRegistrationNumber
      message
      name
      notificationEmail
      orderOption
      ownerEmail
      ownerPhone
      preferredLanguage
      senderEmail
      status
      storeOpenCloseHours {
        close
        day
        open
      }
      tax
      warungStoreStatus
      webQrCode
      whatsappNo
    }
  }
`;
export const adminGetMerchantGeneralConfigAndSiteAnalytics = /* GraphQL */ `
  query AdminGetMerchantGeneralConfigAndSiteAnalytics($merchantId: String) {
    adminGetMerchantGeneralConfigAndSiteAnalytics(merchantId: $merchantId) {
      effectiveEndDate
      effectiveStartDate
      facebookPixelId
      favicon
      googleAnalyticsId
      logo
      message
      notificationMessage
      seoDescription
      seoTitle
      status
    }
  }
`;
export const adminGetMerchantPaymentConfig = /* GraphQL */ `
  query AdminGetMerchantPaymentConfig($merchantId: String) {
    adminGetMerchantPaymentConfig(merchantId: $merchantId) {
      manualPayment {
        accountNumber
        bankAccountName
        isBankTransfer
        isDisabled
        manualPaymentMethodName
        manualPaymentOptionsId
        merchantId
        paymentInstructions
        qrCode
      }
      message
      paymentGateway {
        companyName
        isDisabled
        serviceId
        servicePassword
      }
      status
    }
  }
`;
export const adminGetMerchantShippingCreditBalance = /* GraphQL */ `
  query AdminGetMerchantShippingCreditBalance($merchantId: String) {
    adminGetMerchantShippingCreditBalance(merchantId: $merchantId) {
      message
      shippingCreditBalance
      status
    }
  }
`;
export const adminGetMerchantSiteFontAndColor = /* GraphQL */ `
  query AdminGetMerchantSiteFontAndColor($merchantId: String) {
    adminGetMerchantSiteFontAndColor(merchantId: $merchantId) {
      copyright
      facebookName
      facebookUrl
      googlePlayUrl
      instagramName
      instagramUrl
      linkedInName
      linkedInUrl
      logo
      message
      playStoreUrl
      siteColor
      siteFont
      status
      tiktokName
      tiktokUrl
      twitterName
      twitterUrl
      youtubeName
      youtubeUrl
    }
  }
`;
export const adminGetNotificationBarMessage = /* GraphQL */ `
  query AdminGetNotificationBarMessage($merchantId: String) {
    adminGetNotificationBarMessage(merchantId: $merchantId) {
      notificationMessage
    }
  }
`;
export const adminGetNotificationSetting = /* GraphQL */ `
  query AdminGetNotificationSetting($merchantId: String, $type: String) {
    adminGetNotificationSetting(merchantId: $merchantId, type: $type) {
      callbackEvent
      callbackUrl
      createdAt
      createdBy
      emailBody
      emailSubject
      merchantId
      notificationSettingId
      status
      type
      updatedAt
      updatedBy
    }
  }
`;
export const adminGetNotificationSettingStatus = /* GraphQL */ `
  query AdminGetNotificationSettingStatus($merchantId: String) {
    adminGetNotificationSettingStatus(merchantId: $merchantId) {
      message
      notificationSettingList {
        callbackEvent
        callbackUrl
        createdAt
        createdBy
        emailBody
        emailSubject
        merchantId
        notificationSettingId
        status
        type
        updatedAt
        updatedBy
      }
      status
    }
  }
`;
export const adminGetOrderPrintDetails = /* GraphQL */ `
  query AdminGetOrderPrintDetails(
    $merchantId: String
    $orderId: [String]
    $type: String
  ) {
    adminGetOrderPrintDetails(
      merchantId: $merchantId
      orderId: $orderId
      type: $type
    ) {
      message
      orders {
        AWBRef
        barcode
        billingAddress
        currency
        deliveryAddressType
        deliveryCode
        deliveryDiscount
        deliveryNumber
        deliveryOrderDateTime
        deliveryType
        estimatedDeliveryFee
        grandTotal
        invoiceNo
        issueDateTime
        merchantEmail
        merchantName
        officeRemarks
        orderDateTime
        orderId
        orderItems {
          associatedProducts {
            productId
            productImage
            productSku
            productTitle
            productUOMId
            quantity
            variantName1
            variantName2
            variantName3
            variantValue1
            variantValue2
            variantValue3
          }
          comment
          createdAt
          createdBy
          deliveryCompareAtPrice
          deliveryPrice
          deliveryPriceWithTax
          fulfilledQuantity
          isFreeItem
          itemId
          itemImage
          itemIsVirtualGoods
          itemProperty
          itemSku
          itemStatus
          itemTitle
          itemVariantName1
          itemVariantName2
          itemVariantName3
          itemVariantValue1
          itemVariantValue2
          itemVariantValue3
          mandatoryItem
          manualPaymentReceipt
          merchantId
          orderDetailId
          orderId
          orderNumber
          orderedQuantity
          pickupCompareAtPrice
          pickupPrice
          pickupPriceWithTax
          promoCode
          promoCodeDiscount
          rating
          refundQuantity
          selectedModifierGroups {
            isSelected
            modifier {
              availableStatus
              isSelected
              modifierId
              modifierName
              price
            }
            modifierGroupId
            modifierGroupName
            modifierGroupType
            requestRemark
            selectionRangeMax
            selectionRangeMin
          }
          subtotal
          subtotalWithTax
          totalDiscount
          totalPromoCodeDiscount
          totalVoucherDiscount
          updatedAt
          updatedBy
          voucherDiscount
          voucherNumber
        }
        parcelInfo
        paymentAmount
        paymentType
        portCode
        printDateTime
        promoCode
        promoCodeDiscount
        promoCodeTitle
        recipientInfo {
          address
          name
          phone
        }
        remarks
        salesChannelName
        sellerRemarks
        senderInfo {
          merchantAddress
          merchantName
          merchantPhone
        }
        shippingAddress
        status
        subtotal
        subtotalWithTax
        totalOrderItems
        type
        weight
      }
      status
    }
  }
`;
export const adminGetPriceGroup = /* GraphQL */ `
  query AdminGetPriceGroup($merchantId: String, $priceGroupId: String) {
    adminGetPriceGroup(merchantId: $merchantId, priceGroupId: $priceGroupId) {
      createdAt
      createdBy
      increment
      incrementType
      merchantId
      name
      priceGroupId
      stores {
        storeCode
        storeId
        storeName
      }
      updatedAt
      updatedBy
    }
  }
`;
export const adminGetProduct = /* GraphQL */ `
  query AdminGetProduct($merchantId: String, $productId: String) {
    adminGetProduct(merchantId: $merchantId, productId: $productId) {
      message
      product {
        code
        collectionNames
        cover
        createdAt
        createdBy
        deliveryCompareAtPrice
        deliveryCostPerItem
        deliveryPrice
        deliveryPriceWithTax
        description
        effectiveEndDateTime
        effectiveStartDateTime
        hasVariant
        image
        isDisabled
        isVirtualGoods
        merchantId
        noOfPurchases
        noOfViews
        pickupCompareAtPrice
        pickupCostPerItem
        pickupPrice
        pickupPriceWithTax
        platform
        productId
        promotionEndDateTime
        promotionStartDateTime
        sellOnFacebookStore
        sellOnFoodPanda
        sellOnGrabFood
        sellOnGrabMart
        sellOnInstagram
        sellOnLazada
        sellOnOfflineStore
        sellOnOnlineStore
        sellOnPandaMart
        sellOnShopee
        seoDescription
        seoTitle
        seoUrl
        taggingNames
        title
        updatedAt
        updatedBy
        variantName1
        variantName2
        variantName3
        variantValues1
        variantValues2
        variantValues3
        video
        virtualGoodsExpiredAt
        virtualGoodsExpiryDays
      }
      status
    }
  }
`;
export const adminGetProductBundle = /* GraphQL */ `
  query AdminGetProductBundle($merchantId: String, $productBundleId: String) {
    adminGetProductBundle(
      merchantId: $merchantId
      productBundleId: $productBundleId
    ) {
      barcode
      collectionNames
      cover
      deliveryCompareAtPrice
      deliveryCostPerItem
      deliveryPrice
      deliveryPriceWithTax
      description
      effectiveEndDateTime
      effectiveStartDateTime
      image
      isDisabled
      isProductBundleTaxable
      merchantId
      message
      pickupCompareAtPrice
      pickupCostPerItem
      pickupPrice
      pickupPriceWithTax
      productBundleDetails {
        merchantId
        productBundleDetailId
        productBundleId
        productId
        productImage
        productSku
        productTitle
        productUOMId
        quantity
        updatedAt
        updatedBy
        variantName1
        variantName2
        variantName3
        variantValue1
        variantValue2
        variantValue3
      }
      productBundleId
      productBundlePricing {
        salesChannelName
        storeProducts {
          createdAt
          createdBy
          deliveryCompareAtPrice
          deliveryCostPerItem
          deliveryPrice
          deliveryPriceWithTax
          isDisabled
          marketPlaceAttributes {
            actualName
            hasChildren
            inputType
            isRequired
            parentId
            type
            typeId
            typeName
            typeValues {
              valueEnglishName
              valueId
              valueName
              valueUnit
            }
          }
          marketPlaceBrand {
            actualName
            hasChildren
            inputType
            isRequired
            parentId
            type
            typeId
            typeName
            typeValues {
              valueEnglishName
              valueId
              valueName
              valueUnit
            }
          }
          marketPlaceCategories {
            actualName
            hasChildren
            inputType
            isRequired
            parentId
            type
            typeId
            typeName
            typeValues {
              valueEnglishName
              valueId
              valueName
              valueUnit
            }
          }
          marketPlaceProductCode
          marketPlaceProductUOMCode
          merchantId
          noOfPurchases
          pickupCompareAtPrice
          pickupCostPerItem
          pickupPrice
          pickupPriceWithTax
          quantityForSales
          quantityType
          salesChannelName
          storeId
          storeName
          storeProductId
          updatedAt
          updatedBy
        }
      }
      promotionEndDateTime
      promotionStartDateTime
      quantityForSales
      sellOnFacebookStore
      sellOnFoodPanda
      sellOnGrabFood
      sellOnGrabMart
      sellOnInstagram
      sellOnLazada
      sellOnOfflineStore
      sellOnOnlineStore
      sellOnPandaMart
      sellOnShopee
      seoDescription
      seoTitle
      seoUrl
      shippingDimensionHeight
      shippingDimensionLength
      shippingDimensionWidth
      shippingWeight
      shippingWeightUnit
      sku
      status
      taggingNames
      title
      totalStockQuantity
    }
  }
`;
export const adminGetProductBundleNames = /* GraphQL */ `
  query AdminGetProductBundleNames(
    $merchantId: String
    $productIdList: [String]
  ) {
    adminGetProductBundleNames(
      merchantId: $merchantId
      productIdList: $productIdList
    ) {
      message
      productBundleNames
      status
    }
  }
`;
export const adminGetProductCollection = /* GraphQL */ `
  query AdminGetProductCollection(
    $merchantId: String
    $productCollectionId: String
  ) {
    adminGetProductCollection(
      merchantId: $merchantId
      productCollectionId: $productCollectionId
    ) {
      banner
      condition
      conditionType
      createdAt
      createdBy
      description
      icon
      merchantId
      name
      productCollectionId
      seoDescription
      seoTitle
      seoUrl
      taggingNames
      type
      updatedAt
      updatedBy
    }
  }
`;
export const adminGetProductInventory = /* GraphQL */ `
  query AdminGetProductInventory($merchantId: String, $productId: String) {
    adminGetProductInventory(merchantId: $merchantId, productId: $productId) {
      items {
        productUOMs {
          barcode
          collectionNames
          deliveryCompareAtPrice
          deliveryCostPerItem
          deliveryEffectiveEndDateTime
          deliveryEffectiveStartDateTime
          deliveryPrice
          deliveryPriceWithTax
          image
          incomingQuantity
          isDisabled
          isProductTaxable
          isVirtualGoods
          marketPlaceAttributes {
            actualName
            hasChildren
            inputType
            isRequired
            parentId
            type
            typeId
            typeName
            typeValues {
              valueEnglishName
              valueId
              valueName
              valueUnit
            }
          }
          marketPlaceBrand {
            actualName
            hasChildren
            inputType
            isRequired
            parentId
            type
            typeId
            typeName
            typeValues {
              valueEnglishName
              valueId
              valueName
              valueUnit
            }
          }
          marketPlaceCategories {
            actualName
            hasChildren
            inputType
            isRequired
            parentId
            type
            typeId
            typeName
            typeValues {
              valueEnglishName
              valueId
              valueName
              valueUnit
            }
          }
          marketPlaceProductCode
          marketPlaceProductUOMCode
          marketPlaceShippingPartner
          merchantId
          modifierGroups {
            isSelected
            modifier {
              availableStatus
              isSelected
              modifierId
              modifierName
              price
            }
            modifierGroupId
            modifierGroupName
            modifierGroupType
            requestRemark
            selectionRangeMax
            selectionRangeMin
          }
          noOfPurchases
          pickupCompareAtPrice
          pickupCostPerItem
          pickupEffectiveEndDateTime
          pickupEffectiveStartDateTime
          pickupPrice
          pickupPriceWithTax
          productId
          productTitle
          productUOMId
          productUOMPricingId
          quantityBundleForSales
          quantityForSales
          quantityType
          salesChannelName
          shippingDimensionHeight
          shippingDimensionLength
          shippingDimensionWidth
          shippingWeight
          shippingWeightUnit
          sku
          storeId
          storeName
          storeProductId
          taggingNames
          totalStockQuantity
          trackQuantity
          updatedAt
          updatedBy
          variantName1
          variantName2
          variantName3
          variantValue1
          variantValue2
          variantValue3
          virtualGoodsExpiredAt
          virtualGoodsExpiryDays
        }
        salesChannelName
      }
      message
      status
      updatedAt
      updatedBy
    }
  }
`;
export const adminGetPromoCodeCampaign = /* GraphQL */ `
  query AdminGetPromoCodeCampaign(
    $merchantId: String
    $promoCodeCampaignId: String
  ) {
    adminGetPromoCodeCampaign(
      merchantId: $merchantId
      promoCodeCampaignId: $promoCodeCampaignId
    ) {
      items {
        activeCount
        category
        createdBy
        customerCondition
        deliveryDiscountType
        discountAmountCap
        discountOnProductValue
        discountQuantityCap
        effectiveEndDateTime
        effectiveStartDateTime
        isDisabled
        maxUserUsageLimit
        merchantId
        minimumCondition
        minimumPurchase
        minimumQuantity
        orderType
        productConditions {
          ids {
            collectionName
            productBundleId
            productId
            productUOMId
          }
          type
        }
        productsDiscount {
          ids {
            collectionName
            productBundleId
            productId
            productUOMId
          }
          type
        }
        promoCode
        promoCodeCampaignId
        remainingUsage
        specificCustomerTag
        specificCustomers
        status
        stores
        title
        totalUsageLimit
        type
        updatedAt
        updatedBy
      }
      message
      status
    }
  }
`;
export const adminGetSalesChannelSetting = /* GraphQL */ `
  query AdminGetSalesChannelSetting(
    $merchantId: String
    $salesChannelId: String
    $salesChannelName: String
    $storeId: String
  ) {
    adminGetSalesChannelSetting(
      merchantId: $merchantId
      salesChannelId: $salesChannelId
      salesChannelName: $salesChannelName
      storeId: $storeId
    ) {
      isDisabled
      message
      status
      storeCode
      storeId
      storeName
      syncFrequency
    }
  }
`;
export const adminGetShopeeAWB = /* GraphQL */ `
  query AdminGetShopeeAWB(
    $merchantId: String
    $orderNumber: String
    $packageNumber: String
    $storeId: String
    $trackingNo: String
  ) {
    adminGetShopeeAWB(
      merchantId: $merchantId
      orderNumber: $orderNumber
      packageNumber: $packageNumber
      storeId: $storeId
      trackingNo: $trackingNo
    ) {
      AWBUrl
      message
      status
    }
  }
`;
export const adminGetShopeeDeliveryPartner = /* GraphQL */ `
  query AdminGetShopeeDeliveryPartner($merchantId: String, $storeId: String) {
    adminGetShopeeDeliveryPartner(merchantId: $merchantId, storeId: $storeId) {
      delivery_partner_list {
        cod_enabled
        enabled
        fee_type
        force_enable
        item_max_dimension {
          dimension_sum
          height
          length
          unit
          width
        }
        logistics_channel_id
        logistics_channel_name
        logistics_description
        mask_channel_id
        preferred
        size_list
        volume_limit {
          item_max_volume
          item_min_volume
        }
        weight_limit {
          item_max_weight
          item_min_weight
        }
      }
      message
      status
    }
  }
`;
export const adminGetShopeeProductSpec = /* GraphQL */ `
  query AdminGetShopeeProductSpec(
    $merchantId: String
    $parentId: String
    $storeId: String
    $type: String
  ) {
    adminGetShopeeProductSpec(
      merchantId: $merchantId
      parentId: $parentId
      storeId: $storeId
      type: $type
    ) {
      message
      products {
        actualName
        hasChildren
        inputType
        isRequired
        parentId
        type
        typeId
        typeName
        typeValues {
          valueEnglishName
          valueId
          valueName
          valueUnit
        }
      }
      status
    }
  }
`;
export const adminGetSiteNavigation = /* GraphQL */ `
  query AdminGetSiteNavigation($merchantId: String, $siteNavigationId: String) {
    adminGetSiteNavigation(
      merchantId: $merchantId
      siteNavigationId: $siteNavigationId
    ) {
      message
      selectedItem {
        itemId
        itemImage
        itemProperty
        itemTitle
      }
      siteNavigation {
        childItems {
          childItems {
            childItems {
              createdAt
              createdBy
              effectiveEndDateTime
              effectiveStartDateTime
              isDisabled
              linkValue
              menuType
              merchantId
              navigationType
              openNewTab
              parentId
              sequence
              siteNavigationId
              title
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            effectiveEndDateTime
            effectiveStartDateTime
            isDisabled
            linkValue
            menuType
            merchantId
            navigationType
            openNewTab
            parentId
            sequence
            siteNavigationId
            title
            updatedAt
            updatedBy
          }
          createdAt
          createdBy
          effectiveEndDateTime
          effectiveStartDateTime
          isDisabled
          linkValue
          menuType
          merchantId
          navigationType
          openNewTab
          parentId
          sequence
          siteNavigationId
          title
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        effectiveEndDateTime
        effectiveStartDateTime
        isDisabled
        linkValue
        menuType
        merchantId
        navigationType
        openNewTab
        parentId
        sequence
        siteNavigationId
        title
        updatedAt
        updatedBy
      }
      status
    }
  }
`;
export const adminGetSplashScreen = /* GraphQL */ `
  query AdminGetSplashScreen($merchantId: String, $splashScreenId: String) {
    adminGetSplashScreen(
      merchantId: $merchantId
      splashScreenId: $splashScreenId
    ) {
      message
      splashScreen {
        buttonAction
        buttonActionValue
        createdAt
        createdBy
        effectiveEndDateTime
        effectiveStartDateTime
        homeImage
        isDisabled
        merchantId
        selectedItem {
          itemId
          itemImage
          itemProperty
          itemSeoUrl
          itemTitle
        }
        sequence
        splashScreenId
        title
        updatedAt
        updatedBy
      }
      status
    }
  }
`;
export const adminGetStockMovement = /* GraphQL */ `
  query AdminGetStockMovement($merchantId: String, $stockMovementId: String) {
    adminGetStockMovement(
      merchantId: $merchantId
      stockMovementId: $stockMovementId
    ) {
      buyerNotes
      createdAt
      createdBy
      expectedArrivalDate
      merchantId
      referenceNumber
      status
      stockMovementDetails {
        createdAt
        createdBy
        merchantId
        orderedQuantity
        productId
        productImage
        productTitle
        productUOMId
        receivedQuantity
        remarks
        stockMovementDetailId
        stockMovementId
        updatedAt
        updatedBy
        variantName1
        variantName2
        variantName3
        variantValue1
        variantValue2
        variantValue3
      }
      stockMovementId
      supplierId
      supplierName
      taggingNames
      trackingNumber
      updatedAt
      updatedBy
    }
  }
`;
export const adminGetStore = /* GraphQL */ `
  query AdminGetStore($code: String, $merchantId: String) {
    adminGetStore(code: $code, merchantId: $merchantId) {
      address
      city
      deliveryDuration
      deliveryFee
      deliveryServiceAvailable
      groupName
      isDisabled
      latitude
      longitude
      managerContact
      managerName
      maxPurchaseQuantity
      message
      minPurchase
      name
      offlineStoreOperate24Hour
      onlineStoreOperate24Hour
      pickupServiceAvailable
      postalCode
      preparationTime
      primaryEmail
      state
      status
      storeId
      taggings
    }
  }
`;
export const adminGetStoreBlockedOutPeriod = /* GraphQL */ `
  query AdminGetStoreBlockedOutPeriod($merchantId: String, $storeId: String) {
    adminGetStoreBlockedOutPeriod(merchantId: $merchantId, storeId: $storeId) {
      endDateTime
      message
      startDateTime
      status
      storeType
    }
  }
`;
export const adminGetStoreListByProduct = /* GraphQL */ `
  query AdminGetStoreListByProduct(
    $merchantId: String
    $productBundleId: String
    $productId: String
  ) {
    adminGetStoreListByProduct(
      merchantId: $merchantId
      productBundleId: $productBundleId
      productId: $productId
    ) {
      items {
        isProductSelling
        salesChannelName
        storeId
        storeName
      }
      message
      status
    }
  }
`;
export const adminGetStoreOperatingHour = /* GraphQL */ `
  query AdminGetStoreOperatingHour($merchantId: String, $storeId: String) {
    adminGetStoreOperatingHour(merchantId: $merchantId, storeId: $storeId) {
      message
      status
      storeOperatingHours {
        createdAt
        createdBy
        day
        endingTimeAfterMidnight
        merchantId
        offlineStoreClosingHour
        offlineStoreOpeningHour
        storeId
        storeOperatingHourId
        updatedAt
        updatedBy
      }
    }
  }
`;
export const adminGetSupplier = /* GraphQL */ `
  query AdminGetSupplier($merchantId: String, $supplierId: String) {
    adminGetSupplier(merchantId: $merchantId, supplierId: $supplierId) {
      address
      contact
      contactName
      country
      email
      merchantId
      name
      supplierId
    }
  }
`;
export const adminGetUserMatrix = /* GraphQL */ `
  query AdminGetUserMatrix($merchantId: String, $userId: String) {
    adminGetUserMatrix(merchantId: $merchantId, userId: $userId) {
      message
      status
      userMatrixes {
        canAdd
        canDelete
        canEdit
        canList
        canView
        cognitoUsername
        createdAt
        createdBy
        merchantId
        module
        updatedAt
        updatedBy
        userMatrixId
      }
    }
  }
`;
export const adminGetUserMerchantList = /* GraphQL */ `
  query AdminGetUserMerchantList {
    adminGetUserMerchantList {
      items {
        aboutUsBanner
        aboutUsDescription
        activated
        address
        code
        copyright
        createdAt
        currency
        domain
        facebookName
        facebookPixelId
        facebookUrl
        favicon
        googleAnalyticsId
        googlePlayUrl
        instagramName
        instagramUrl
        isBlocked
        linkedInName
        linkedInUrl
        logo
        membershipTierActivated
        merchantId
        name
        notificationEmail
        offlineStore
        onlineStore
        ownerEmail
        ownerName
        ownerPhone
        playStoreUrl
        senderEmail
        seoDescription
        seoTitle
        serviceId
        servicePassword
        tax
        tiktokName
        tiktokUrl
        twitterName
        twitterUrl
        updatedAt
        whatsappNo
        youtubeName
        youtubeUrl
      }
    }
  }
`;
export const adminGetVoucher = /* GraphQL */ `
  query AdminGetVoucher($merchantId: String, $voucherId: String) {
    adminGetVoucher(merchantId: $merchantId, voucherId: $voucherId) {
      message
      status
      voucher {
        acquireOptions {
          type
          values
        }
        activeCount
        category
        createdAt
        createdBy
        customerCondition
        deliveryDiscountType
        discountAmountCap
        discountOnProductValue
        discountQuantityCap
        effectiveEndDateTime
        effectiveStartDateTime
        maxUserUsageLimit
        merchantId
        minimumAmount
        minimumCondition
        minimumQuantity
        orderType
        productConditions {
          ids
          type
        }
        productsDiscount {
          ids
          type
        }
        salesChannelName {
          ids
          type
        }
        status
        stores
        title
        totalUsageLimit
        type
        updatedAt
        updatedBy
        voucherBanner
        voucherCode
        voucherIcon
        voucherId
      }
    }
  }
`;
export const adminGetWarehouse = /* GraphQL */ `
  query AdminGetWarehouse($merchantId: String) {
    adminGetWarehouse(merchantId: $merchantId) {
      address
      city
      country
      createdAt
      createdBy
      isOnDemandDeliveryEnabled
      latitude
      longitude
      maxDeliveryDistance
      merchantId
      postalCode
      state
      updatedAt
      updatedBy
      warehouseId
    }
  }
`;
export const adminGetWarehouseDeliveryConfig = /* GraphQL */ `
  query AdminGetWarehouseDeliveryConfig($warehouseId: String) {
    adminGetWarehouseDeliveryConfig(warehouseId: $warehouseId) {
      isOwnTransportEnabled
      isStandardDeliveryEnabled
      message
      onDemandDefaultVehicleType
      onDemandMaxDistance
      standardPreferredPartnerName1
      standardPreferredPartnerName2
      status
    }
  }
`;
export const adminGetWebhookCallbackConfig = /* GraphQL */ `
  query AdminGetWebhookCallbackConfig($merchantId: String) {
    adminGetWebhookCallbackConfig(merchantId: $merchantId) {
      callbackEvent
      callbackUrl
      createdAt
      createdBy
      merchantId
      updatedAt
      updatedBy
      webhookCallbackConfigId
    }
  }
`;
export const adminListFaqCategories = /* GraphQL */ `
  query AdminListFaqCategories(
    $filter: SearchFaqCategoriesFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    adminListFaqCategories(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        childItems {
          answer
          createdAt
          createdBy
          faqCategory
          faqId
          faqType
          merchantId
          parentId
          question
          sequence
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        faqCategoryId
        merchantId
        sequence
        title
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const adminListItemsAndPricing = /* GraphQL */ `
  query AdminListItemsAndPricing(
    $merchantId: String
    $productBundleIdList: [String]
    $productId: String
    $productUOMIdList: [String]
    $salesChannelName: String
  ) {
    adminListItemsAndPricing(
      merchantId: $merchantId
      productBundleIdList: $productBundleIdList
      productId: $productId
      productUOMIdList: $productUOMIdList
      salesChannelName: $salesChannelName
    ) {
      bundles {
        productBundles {
          barcode
          collectionNames
          cover
          deliveryCompareAtPrice
          deliveryCostPerItem
          deliveryEffectiveEndDateTime
          deliveryEffectiveStartDateTime
          deliveryPrice
          deliveryPriceWithTax
          description
          image
          marketPlaceAttributes {
            actualName
            hasChildren
            inputType
            isRequired
            parentId
            type
            typeId
            typeName
            typeValues {
              valueEnglishName
              valueId
              valueName
              valueUnit
            }
          }
          marketPlaceBrand {
            actualName
            hasChildren
            inputType
            isRequired
            parentId
            type
            typeId
            typeName
            typeValues {
              valueEnglishName
              valueId
              valueName
              valueUnit
            }
          }
          marketPlaceCategories {
            actualName
            hasChildren
            inputType
            isRequired
            parentId
            type
            typeId
            typeName
            typeValues {
              valueEnglishName
              valueId
              valueName
              valueUnit
            }
          }
          marketPlaceProductCode
          marketPlaceProductUOMCode
          marketPlaceShippingPartner
          merchantId
          pickupCompareAtPrice
          pickupCostPerItem
          pickupEffectiveEndDateTime
          pickupEffectiveStartDateTime
          pickupPrice
          pickupPriceWithTax
          productBundleId
          productBundlePricingId
          quantityForSales
          salesChannelName
          seoDescription
          seoTitle
          seoUrl
          sku
          storeId
          storeName
          storeProductId
          taggingNames
          title
        }
        salesChannelName
      }
      message
      status
      uoms {
        productUOMs {
          barcode
          collectionNames
          deliveryCompareAtPrice
          deliveryCostPerItem
          deliveryEffectiveEndDateTime
          deliveryEffectiveStartDateTime
          deliveryPrice
          deliveryPriceWithTax
          image
          incomingQuantity
          isDisabled
          isProductTaxable
          isVirtualGoods
          marketPlaceAttributes {
            actualName
            hasChildren
            inputType
            isRequired
            parentId
            type
            typeId
            typeName
            typeValues {
              valueEnglishName
              valueId
              valueName
              valueUnit
            }
          }
          marketPlaceBrand {
            actualName
            hasChildren
            inputType
            isRequired
            parentId
            type
            typeId
            typeName
            typeValues {
              valueEnglishName
              valueId
              valueName
              valueUnit
            }
          }
          marketPlaceCategories {
            actualName
            hasChildren
            inputType
            isRequired
            parentId
            type
            typeId
            typeName
            typeValues {
              valueEnglishName
              valueId
              valueName
              valueUnit
            }
          }
          marketPlaceProductCode
          marketPlaceProductUOMCode
          marketPlaceShippingPartner
          merchantId
          modifierGroups {
            isSelected
            modifier {
              availableStatus
              isSelected
              modifierId
              modifierName
              price
            }
            modifierGroupId
            modifierGroupName
            modifierGroupType
            requestRemark
            selectionRangeMax
            selectionRangeMin
          }
          noOfPurchases
          pickupCompareAtPrice
          pickupCostPerItem
          pickupEffectiveEndDateTime
          pickupEffectiveStartDateTime
          pickupPrice
          pickupPriceWithTax
          productId
          productTitle
          productUOMId
          productUOMPricingId
          quantityBundleForSales
          quantityForSales
          quantityType
          salesChannelName
          shippingDimensionHeight
          shippingDimensionLength
          shippingDimensionWidth
          shippingWeight
          shippingWeightUnit
          sku
          storeId
          storeName
          storeProductId
          taggingNames
          totalStockQuantity
          trackQuantity
          updatedAt
          updatedBy
          variantName1
          variantName2
          variantName3
          variantValue1
          variantValue2
          variantValue3
          virtualGoodsExpiredAt
          virtualGoodsExpiryDays
        }
        salesChannelName
      }
    }
  }
`;
export const adminListManualPaymentOptions = /* GraphQL */ `
  query AdminListManualPaymentOptions($merchantId: String) {
    adminListManualPaymentOptions(merchantId: $merchantId) {
      manualPaymentOptionList {
        accountNumber
        bankAccountName
        isBankTransfer
        isDisabled
        manualPaymentMethodName
        manualPaymentOptionsId
        merchantId
        paymentInstructions
        qrCode
      }
      message
      status
    }
  }
`;
export const adminListMerchantCreditTransactions = /* GraphQL */ `
  query AdminListMerchantCreditTransactions(
    $limit: Int
    $merchantId: String
    $nextToken: String
  ) {
    adminListMerchantCreditTransactions(
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
    ) {
      items {
        actionType
        amount
        createdAt
        createdBy
        merchantCreditTransactionId
        merchantId
        merchantOrderId
        orderNumber
        transactionId
      }
      nextToken
      total
    }
  }
`;
export const adminListPendingCheckoutCarts = /* GraphQL */ `
  query AdminListPendingCheckoutCarts(
    $filter: ListPendingCheckoutCartsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    adminListPendingCheckoutCarts(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        createdAt
        customerFirstName
        customerId
        customerLastName
        merchantId
        salesChannelName
        totalPrice
        totalProducts
        totalQuantity
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const adminListPointConversion = /* GraphQL */ `
  query AdminListPointConversion(
    $filter: SearchPointConversionsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    adminListPointConversion(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        createdAt
        createdBy
        effectiveEndDateTime
        effectiveStartDateTime
        itemId
        itemImage
        itemProperty
        itemTitle
        merchantId
        multiplier
        pointConversionId
        productId
        spent
        updatedAt
        updatedBy
      }
      message
      nextToken
      status
      total
    }
  }
`;
export const adminListProductExclusion = /* GraphQL */ `
  query AdminListProductExclusion(
    $filter: SearchProductExclusionsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    adminListProductExclusion(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        createdAt
        createdBy
        itemId
        itemImage
        itemProperty
        itemTitle
        merchantId
        productExclusionId
        productId
        type
        updatedAt
        updatedBy
      }
      message
      nextToken
      status
      total
    }
  }
`;
export const adminListProductInventories = /* GraphQL */ `
  query AdminListProductInventories(
    $filter: SearchProductUOMsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    adminListProductInventories(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        merchantId
        productCover
        productId
        productTitle
        productUOMs {
          barcode
          collectionNames
          createdAt
          createdBy
          image
          incomingQuantity
          isDisabled
          isProductTaxable
          isVirtualGoods
          merchantId
          modifierGroups {
            isSelected
            modifier {
              availableStatus
              isSelected
              modifierId
              modifierName
              price
            }
            modifierGroupId
            modifierGroupName
            modifierGroupType
            requestRemark
            selectionRangeMax
            selectionRangeMin
          }
          noOfPurchases
          productCover
          productId
          productTitle
          productUOMId
          quantityForSales
          shippingDimensionHeight
          shippingDimensionLength
          shippingDimensionWidth
          shippingWeight
          shippingWeightUnit
          sku
          taggingNames
          totalStockQuantity
          trackQuantity
          updatedAt
          updatedBy
          variantName1
          variantName2
          variantName3
          variantValue1
          variantValue2
          variantValue3
          virtualGoodsExpiredAt
          virtualGoodsExpiryDays
        }
      }
      nextToken
      total
    }
  }
`;
export const adminListProductsWithProductUOMs = /* GraphQL */ `
  query AdminListProductsWithProductUOMs(
    $filter: ListProductsWithProductUOMsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    adminListProductsWithProductUOMs(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        merchantId
        productCover
        productId
        productTitle
        productUOMs {
          barcode
          collectionNames
          createdAt
          createdBy
          image
          incomingQuantity
          isDisabled
          isProductTaxable
          isVirtualGoods
          merchantId
          modifierGroups {
            isSelected
            modifier {
              availableStatus
              isSelected
              modifierId
              modifierName
              price
            }
            modifierGroupId
            modifierGroupName
            modifierGroupType
            requestRemark
            selectionRangeMax
            selectionRangeMin
          }
          noOfPurchases
          productCover
          productId
          productTitle
          productUOMId
          quantityForSales
          shippingDimensionHeight
          shippingDimensionLength
          shippingDimensionWidth
          shippingWeight
          shippingWeightUnit
          sku
          taggingNames
          totalStockQuantity
          trackQuantity
          updatedAt
          updatedBy
          variantName1
          variantName2
          variantName3
          variantValue1
          variantValue2
          variantValue3
          virtualGoodsExpiredAt
          virtualGoodsExpiryDays
        }
      }
      nextToken
      total
    }
  }
`;
export const adminListShippingZonesAndRates = /* GraphQL */ `
  query AdminListShippingZonesAndRates(
    $merchantId: String
    $warehouseId: String
  ) {
    adminListShippingZonesAndRates(
      merchantId: $merchantId
      warehouseId: $warehouseId
    ) {
      items {
        country
        createdAt
        createdBy
        merchantId
        shippingRates {
          amount
          conditionType
          createdAt
          createdBy
          estimatedDuration
          maxValue
          merchantId
          minValue
          shippingRateId
          shippingZoneId
          title
          updatedAt
          updatedBy
        }
        shippingZoneId
        state
        title
        transportType
        updatedAt
        updatedBy
        warehouseId
      }
      message
      status
    }
  }
`;
export const adminListSiteNavigations = /* GraphQL */ `
  query AdminListSiteNavigations(
    $filter: SearchSiteNavigationsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    adminListSiteNavigations(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        childItems {
          childItems {
            childItems {
              createdAt
              createdBy
              effectiveEndDateTime
              effectiveStartDateTime
              isDisabled
              linkValue
              menuType
              merchantId
              navigationType
              openNewTab
              parentId
              sequence
              siteNavigationId
              title
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            effectiveEndDateTime
            effectiveStartDateTime
            isDisabled
            linkValue
            menuType
            merchantId
            navigationType
            openNewTab
            parentId
            sequence
            siteNavigationId
            title
            updatedAt
            updatedBy
          }
          createdAt
          createdBy
          effectiveEndDateTime
          effectiveStartDateTime
          isDisabled
          linkValue
          menuType
          merchantId
          navigationType
          openNewTab
          parentId
          sequence
          siteNavigationId
          title
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        effectiveEndDateTime
        effectiveStartDateTime
        isDisabled
        linkValue
        menuType
        merchantId
        navigationType
        openNewTab
        parentId
        sequence
        siteNavigationId
        title
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const adminListStoreProductsBySalesChannel = /* GraphQL */ `
  query AdminListStoreProductsBySalesChannel(
    $merchantId: String
    $productBundleId: String
    $productId: String
  ) {
    adminListStoreProductsBySalesChannel(
      merchantId: $merchantId
      productBundleId: $productBundleId
      productId: $productId
    ) {
      bundles {
        productBundles {
          barcode
          collectionNames
          cover
          deliveryCompareAtPrice
          deliveryCostPerItem
          deliveryEffectiveEndDateTime
          deliveryEffectiveStartDateTime
          deliveryPrice
          deliveryPriceWithTax
          description
          image
          marketPlaceAttributes {
            actualName
            hasChildren
            inputType
            isRequired
            parentId
            type
            typeId
            typeName
            typeValues {
              valueEnglishName
              valueId
              valueName
              valueUnit
            }
          }
          marketPlaceBrand {
            actualName
            hasChildren
            inputType
            isRequired
            parentId
            type
            typeId
            typeName
            typeValues {
              valueEnglishName
              valueId
              valueName
              valueUnit
            }
          }
          marketPlaceCategories {
            actualName
            hasChildren
            inputType
            isRequired
            parentId
            type
            typeId
            typeName
            typeValues {
              valueEnglishName
              valueId
              valueName
              valueUnit
            }
          }
          marketPlaceProductCode
          marketPlaceProductUOMCode
          marketPlaceShippingPartner
          merchantId
          pickupCompareAtPrice
          pickupCostPerItem
          pickupEffectiveEndDateTime
          pickupEffectiveStartDateTime
          pickupPrice
          pickupPriceWithTax
          productBundleId
          productBundlePricingId
          quantityForSales
          salesChannelName
          seoDescription
          seoTitle
          seoUrl
          sku
          storeId
          storeName
          storeProductId
          taggingNames
          title
        }
        salesChannelName
      }
      message
      status
      uoms {
        productUOMs {
          barcode
          collectionNames
          deliveryCompareAtPrice
          deliveryCostPerItem
          deliveryEffectiveEndDateTime
          deliveryEffectiveStartDateTime
          deliveryPrice
          deliveryPriceWithTax
          image
          incomingQuantity
          isDisabled
          isProductTaxable
          isVirtualGoods
          marketPlaceAttributes {
            actualName
            hasChildren
            inputType
            isRequired
            parentId
            type
            typeId
            typeName
            typeValues {
              valueEnglishName
              valueId
              valueName
              valueUnit
            }
          }
          marketPlaceBrand {
            actualName
            hasChildren
            inputType
            isRequired
            parentId
            type
            typeId
            typeName
            typeValues {
              valueEnglishName
              valueId
              valueName
              valueUnit
            }
          }
          marketPlaceCategories {
            actualName
            hasChildren
            inputType
            isRequired
            parentId
            type
            typeId
            typeName
            typeValues {
              valueEnglishName
              valueId
              valueName
              valueUnit
            }
          }
          marketPlaceProductCode
          marketPlaceProductUOMCode
          marketPlaceShippingPartner
          merchantId
          modifierGroups {
            isSelected
            modifier {
              availableStatus
              isSelected
              modifierId
              modifierName
              price
            }
            modifierGroupId
            modifierGroupName
            modifierGroupType
            requestRemark
            selectionRangeMax
            selectionRangeMin
          }
          noOfPurchases
          pickupCompareAtPrice
          pickupCostPerItem
          pickupEffectiveEndDateTime
          pickupEffectiveStartDateTime
          pickupPrice
          pickupPriceWithTax
          productId
          productTitle
          productUOMId
          productUOMPricingId
          quantityBundleForSales
          quantityForSales
          quantityType
          salesChannelName
          shippingDimensionHeight
          shippingDimensionLength
          shippingDimensionWidth
          shippingWeight
          shippingWeightUnit
          sku
          storeId
          storeName
          storeProductId
          taggingNames
          totalStockQuantity
          trackQuantity
          updatedAt
          updatedBy
          variantName1
          variantName2
          variantName3
          variantValue1
          variantValue2
          variantValue3
          virtualGoodsExpiredAt
          virtualGoodsExpiryDays
        }
        salesChannelName
      }
    }
  }
`;
export const adminListTimeline = /* GraphQL */ `
  query AdminListTimeline($merchantId: String, $timelineForId: String) {
    adminListTimeline(merchantId: $merchantId, timelineForId: $timelineForId) {
      timelines {
        createdAt
        createdBy
        description
        merchantId
        timelineForId
        timelineId
        title
        type
        updatedAt
        updatedBy
      }
    }
  }
`;
export const adminValidateVirtualGoodsOrderCode = /* GraphQL */ `
  query AdminValidateVirtualGoodsOrderCode($code: String, $merchantId: String) {
    adminValidateVirtualGoodsOrderCode(code: $code, merchantId: $merchantId) {
      code
      codeStatus
      codeUpdatedAt
      message
      orderDetails {
        associatedProducts {
          productId
          productImage
          productSku
          productTitle
          productUOMId
          quantity
          variantName1
          variantName2
          variantName3
          variantValue1
          variantValue2
          variantValue3
        }
        comment
        createdAt
        createdBy
        deliveryCompareAtPrice
        deliveryPrice
        deliveryPriceWithTax
        fulfilledQuantity
        isFreeItem
        itemId
        itemImage
        itemIsVirtualGoods
        itemProperty
        itemSku
        itemStatus
        itemTitle
        itemVariantName1
        itemVariantName2
        itemVariantName3
        itemVariantValue1
        itemVariantValue2
        itemVariantValue3
        mandatoryItem
        manualPaymentReceipt
        merchantId
        orderDetailId
        orderId
        orderNumber
        orderedQuantity
        pickupCompareAtPrice
        pickupPrice
        pickupPriceWithTax
        promoCode
        promoCodeDiscount
        rating
        refundQuantity
        selectedModifierGroups {
          isSelected
          modifier {
            availableStatus
            isSelected
            modifierId
            modifierName
            price
          }
          modifierGroupId
          modifierGroupName
          modifierGroupType
          requestRemark
          selectionRangeMax
          selectionRangeMin
        }
        subtotal
        subtotalWithTax
        totalDiscount
        totalPromoCodeDiscount
        totalVoucherDiscount
        updatedAt
        updatedBy
        voucherDiscount
        voucherNumber
      }
      productDescription
      status
    }
  }
`;
export const checkMerchantSetupStatus = /* GraphQL */ `
  query CheckMerchantSetupStatus($merchantId: String) {
    checkMerchantSetupStatus(merchantId: $merchantId) {
      collectKYCInfo
      message
      status
      storeSetupStatus
    }
  }
`;
export const customerSearchProducts = /* GraphQL */ `
  query CustomerSearchProducts(
    $filter: SearchStoreProductsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $orderType: String
    $sort: ElasticSearchSortDirection
    $storeId: String
  ) {
    customerSearchProducts(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      orderType: $orderType
      sort: $sort
      storeId: $storeId
    ) {
      items {
        cover
        deliveryCompareAtPrice
        deliveryCompareAtPriceRange
        deliveryPrice
        deliveryPriceRange
        deliveryPriceWithTax
        deliveryPriceWithTaxRange
        description
        effectiveEndDateTime
        effectiveStartDateTime
        hasStock
        hasVariant
        homeCollectionId
        homeCollectionTitle
        image
        isDisabled
        itemId
        itemProperty
        productIsDisabled
        promotionEndDateTime
        promotionStartDateTime
        seoUrl
        title
        totalRatings
        totalReviews
        updatedAt
        video
      }
      message
      nextToken
      status
      total
    }
  }
`;
export const getAndCheckCustomerCartProduct = /* GraphQL */ `
  query GetAndCheckCustomerCartProduct(
    $accessToken: String
    $addressLatitude: String
    $addressLongitude: String
    $deliveryAddress: String
    $deviceDateTime: String
    $distance: Float
    $merchantId: String
    $orderType: String
    $productList: String
    $promoCode: String
    $returnTotalNumber: Boolean
    $scheduledDateTime: String
    $storeId: String
    $voucherEntryNumber: String
    $voucherNumber: String
  ) {
    getAndCheckCustomerCartProduct(
      accessToken: $accessToken
      addressLatitude: $addressLatitude
      addressLongitude: $addressLongitude
      deliveryAddress: $deliveryAddress
      deviceDateTime: $deviceDateTime
      distance: $distance
      merchantId: $merchantId
      orderType: $orderType
      productList: $productList
      promoCode: $promoCode
      returnTotalNumber: $returnTotalNumber
      scheduledDateTime: $scheduledDateTime
      storeId: $storeId
      voucherEntryNumber: $voucherEntryNumber
      voucherNumber: $voucherNumber
    ) {
      basketValue
      deliveryDiscountAmount
      deliveryFee
      deliveryFree
      discountCap
      discountDeliveryCap
      discountProductQuantityCap
      discountTypeLevel
      indicationMessage
      message
      products {
        customerCartProductUOMId
        discountAmount
        discountedPrice
        ecommerceMaximumQuantity
        image
        isFreeItem
        isOutOfStock
        mandatoryItem
        pickupMaximumQuantity
        price
        promoDiscount
        quantity
        status
        subtotal
        title
        voucherDiscount
      }
      promoApplicable
      promoCode
      promoDeliveryDiscount
      promoDiscount
      promoTitle
      returnTotalNumber
      status
      storeStatus
      storeStatusMessage
      totalDiscountAmount
      totalPromoDiscount
      totalVoucherDiscount
      voucherApplicable
      voucherDeliveryDiscount
      voucherNumber
      voucherTitle
    }
  }
`;
export const getCollections = /* GraphQL */ `
  query GetCollections(
    $filter: SearchGetCollectionsFilter
    $homeCollectionId: String
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    getCollections(
      filter: $filter
      homeCollectionId: $homeCollectionId
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      collections {
        homeCollectionId
        isDisabled
        title
      }
      message
      selectedCollectionsItems {
        homeCollectionId
        homeCollectionTitle
        nextToken
        products {
          cover
          deliveryCompareAtPrice
          deliveryCompareAtPriceRange
          deliveryPrice
          deliveryPriceRange
          deliveryPriceWithTax
          deliveryPriceWithTaxRange
          description
          effectiveEndDateTime
          effectiveStartDateTime
          hasStock
          hasVariant
          homeCollectionId
          homeCollectionTitle
          image
          isDisabled
          itemId
          itemProperty
          productIsDisabled
          promotionEndDateTime
          promotionStartDateTime
          seoUrl
          title
          totalRatings
          totalReviews
          updatedAt
          video
        }
        total
      }
      status
    }
  }
`;
export const getContactUsInfo = /* GraphQL */ `
  query GetContactUsInfo($merchantId: String) {
    getContactUsInfo(merchantId: $merchantId) {
      contactUsContent
      contactUsFormEnabled
      message
      status
      storeList {
        address
        city
        contactUsStoreId
        createdAt
        createdBy
        latitude
        longitude
        merchantId
        postalCode
        state
        storeCode
        storeId
        storeName
        updatedAt
        updatedBy
      }
    }
  }
`;
export const getCustomOrder = /* GraphQL */ `
  query GetCustomOrder($customOrderId: String, $merchantId: String) {
    getCustomOrder(customOrderId: $customOrderId, merchantId: $merchantId) {
      customOrder {
        billingAddress
        billingCity
        billingCountry
        billingPostalCode
        billingState
        cognitoUsername
        createdAt
        createdBy
        customOrderId
        customerFirstName
        customerId
        customerLastName
        itemList {
          itemId
          itemProperty
          itemTitle
          quantity
          subtotal
          type
        }
        merchantId
        mobileNo
        orderId
        orderNumber
        remark
        shippingAddress
        shippingCity
        shippingCountry
        shippingPostalCode
        shippingState
        status
        updatedAt
        updatedBy
      }
      customerCarts {
        createdAt
        customOrderId
        customerCartId
        customerFirstName
        customerId
        customerLastName
        customerMobileNo
        customerPrimaryEmail
        deliveryCompareAtPrice
        deliveryPrice
        deliveryPriceWithTax
        errorMessage
        facebookLiveCampaignDetailId
        isFreeItem
        itemId
        itemImage
        itemProperty
        itemSku
        itemTitle
        itemVariantName1
        itemVariantName2
        itemVariantName3
        itemVariantValue1
        itemVariantValue2
        itemVariantValue3
        mandatoryItem
        maxQuantity
        merchantId
        modifierSubtotal
        pickupCompareAtPrice
        pickupPrice
        pickupPriceWithoutTax
        quantity
        salesChannelName
        selectedModifierGroups {
          isSelected
          modifier {
            availableStatus
            isSelected
            modifierId
            modifierName
            price
          }
          modifierGroupId
          modifierGroupName
          modifierGroupType
          requestRemark
          selectionRangeMax
          selectionRangeMin
        }
        shippingWeight
        shippingWeightUnit
        storeId
        storeName
        subtotal
        subtotalWithTax
        type
        updatedAt
      }
      message
      orderDetails {
        associatedProducts {
          productId
          productImage
          productSku
          productTitle
          productUOMId
          quantity
          variantName1
          variantName2
          variantName3
          variantValue1
          variantValue2
          variantValue3
        }
        comment
        createdAt
        createdBy
        deliveryCompareAtPrice
        deliveryPrice
        deliveryPriceWithTax
        fulfilledQuantity
        isFreeItem
        itemId
        itemImage
        itemIsVirtualGoods
        itemProperty
        itemSku
        itemStatus
        itemTitle
        itemVariantName1
        itemVariantName2
        itemVariantName3
        itemVariantValue1
        itemVariantValue2
        itemVariantValue3
        mandatoryItem
        manualPaymentReceipt
        merchantId
        orderDetailId
        orderId
        orderNumber
        orderedQuantity
        pickupCompareAtPrice
        pickupPrice
        pickupPriceWithTax
        promoCode
        promoCodeDiscount
        rating
        refundQuantity
        selectedModifierGroups {
          isSelected
          modifier {
            availableStatus
            isSelected
            modifierId
            modifierName
            price
          }
          modifierGroupId
          modifierGroupName
          modifierGroupType
          requestRemark
          selectionRangeMax
          selectionRangeMin
        }
        subtotal
        subtotalWithTax
        totalDiscount
        totalPromoCodeDiscount
        totalVoucherDiscount
        updatedAt
        updatedBy
        voucherDiscount
        voucherNumber
      }
      status
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($customerId: String, $merchantId: String) {
    getCustomer(customerId: $customerId, merchantId: $merchantId) {
      accountNo
      address
      address2
      city
      country
      dateOfBirth
      firstName
      gender
      lastName
      lastPurchasedDateTime
      marketingConsent
      membershipPoint
      membershipPointExpiryDate
      membershipTier
      message
      mobileNo
      postal
      primaryEmail
      secondaryEmail
      state
      status
      taggingNames
      totalSpent
    }
  }
`;
export const getCustomerCarts = /* GraphQL */ `
  query GetCustomerCarts($customerId: String, $merchantId: String) {
    getCustomerCarts(customerId: $customerId, merchantId: $merchantId) {
      customer {
        accountNo
        activated
        address
        address2
        city
        consentPersonalData
        country
        createdBy
        customerId
        dateOfBirth
        deviceEndpoint
        deviceToken
        emailSubcriptionStatus
        employmentStatus
        facebookID
        firstName
        gender
        hasCompletedProfile
        hasRewarded
        identityCard
        isBlocked
        isFacebook
        lastName
        lastPurchasedDateTime
        maritalStatus
        membershipPoint
        membershipPointExpiryDate
        membershipTier
        merchantId
        mobileNo
        nationality
        password
        personalIncomeLevel
        postal
        primaryEmail
        profilePicture
        qrCode
        race
        secondaryEmail
        signedUpDateTime
        state
        taggingNames
        totalSalesOrder
        totalSpent
        updatedBy
      }
      items {
        createdAt
        customOrderId
        customerCartId
        customerFirstName
        customerId
        customerLastName
        customerMobileNo
        customerPrimaryEmail
        deliveryCompareAtPrice
        deliveryPrice
        deliveryPriceWithTax
        errorMessage
        facebookLiveCampaignDetailId
        isFreeItem
        itemId
        itemImage
        itemProperty
        itemSku
        itemTitle
        itemVariantName1
        itemVariantName2
        itemVariantName3
        itemVariantValue1
        itemVariantValue2
        itemVariantValue3
        mandatoryItem
        maxQuantity
        merchantId
        modifierSubtotal
        pickupCompareAtPrice
        pickupPrice
        pickupPriceWithoutTax
        quantity
        salesChannelName
        selectedModifierGroups {
          isSelected
          modifier {
            availableStatus
            isSelected
            modifierId
            modifierName
            price
          }
          modifierGroupId
          modifierGroupName
          modifierGroupType
          requestRemark
          selectionRangeMax
          selectionRangeMin
        }
        shippingWeight
        shippingWeightUnit
        storeId
        storeName
        subtotal
        subtotalWithTax
        type
        updatedAt
      }
      message
      status
    }
  }
`;
export const getCustomerFavouriteAddresses = /* GraphQL */ `
  query GetCustomerFavouriteAddresses(
    $accessToken: String
    $customerId: String
    $merchantId: String
  ) {
    getCustomerFavouriteAddresses(
      accessToken: $accessToken
      customerId: $customerId
      merchantId: $merchantId
    ) {
      addresses {
        address
        city
        country
        createdAt
        customerFavouriteAddressId
        customerId
        isDefaultBilling
        isDefaultShipping
        latitude
        longitude
        merchantId
        name
        postalCode
        state
        updatedAt
      }
      message
      status
    }
  }
`;
export const getCustomerOrderDetailList = /* GraphQL */ `
  query GetCustomerOrderDetailList(
    $accessToken: String
    $customerId: String
    $merchantId: String
    $orderId: String
  ) {
    getCustomerOrderDetailList(
      accessToken: $accessToken
      customerId: $customerId
      merchantId: $merchantId
      orderId: $orderId
    ) {
      message
      order {
        accumulatedRefundAmount
        actualDeliveryFee
        billingAddress
        billingCity
        billingCountry
        billingPostalCode
        billingState
        cancelledBy
        cancelledReason
        createdAt
        createdBy
        customerAccountNo
        customerFirstName
        customerIP
        customerId
        customerLastName
        customerMobileNo
        customerPrimaryEmail
        deliveryAddress
        deliveryCity
        deliveryCountry
        deliveryDiscount
        deliveryLatitude
        deliveryLongitude
        deliveryMode
        deliveryNumber
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartnerName
        deliveryPostalCode
        deliveryState
        deliveryStatus
        deliveryType
        driverName
        driverPhone
        driverPlateNumber
        estimatedDeliveryFee
        grandTotal
        isAdvancedOrder
        isRefunded
        isReviewAvailable
        manualPaymentMethodName
        manualPaymentReceipt
        merchantId
        noteToRider
        orderCancellationDateTime
        orderCollectedDateTime
        orderCompletedDateTime
        orderDateTime
        orderId
        orderNumber
        orderType
        overallRating
        packageNumber
        partialFulfilmentAmount
        partialFulfilmentDiscount
        paymentDateTime
        paymentMethod
        paymentStatus
        paymentType
        pickupAddress
        pickupCity
        pickupCountry
        pickupPostalCode
        pickupState
        promoCode
        promoCodeDiscount
        promoCodeId
        promoCodeTitle
        refundAmount
        refundCompleteDateTime
        refundReason
        remarks
        salesChannelName
        scheduledDateTime
        sellerNote
        standardDeliveryAwb
        standardDeliveryAwbIdLink
        standardDeliveryTrackingUrl
        status
        storeCode
        storeId
        storeName
        subtotal
        subtotalWithTax
        tableNumber
        taggingNames
        totalDiscount
        totalOrderItems
        totalPromoCodeDiscount
        totalRefundedAmount
        totalVoucherDiscount
        transactionId
        type
        updatedAt
        updatedBy
        voucherDiscount
        voucherNumber
      }
      orderDetails {
        associatedProducts {
          productId
          productImage
          productSku
          productTitle
          productUOMId
          quantity
          variantName1
          variantName2
          variantName3
          variantValue1
          variantValue2
          variantValue3
        }
        comment
        createdAt
        createdBy
        deliveryCompareAtPrice
        deliveryPrice
        deliveryPriceWithTax
        fulfilledQuantity
        isFreeItem
        itemId
        itemImage
        itemIsVirtualGoods
        itemProperty
        itemSku
        itemStatus
        itemTitle
        itemVariantName1
        itemVariantName2
        itemVariantName3
        itemVariantValue1
        itemVariantValue2
        itemVariantValue3
        mandatoryItem
        manualPaymentReceipt
        merchantId
        orderDetailId
        orderId
        orderNumber
        orderedQuantity
        pickupCompareAtPrice
        pickupPrice
        pickupPriceWithTax
        promoCode
        promoCodeDiscount
        rating
        refundQuantity
        selectedModifierGroups {
          isSelected
          modifier {
            availableStatus
            isSelected
            modifierId
            modifierName
            price
          }
          modifierGroupId
          modifierGroupName
          modifierGroupType
          requestRemark
          selectionRangeMax
          selectionRangeMin
        }
        subtotal
        subtotalWithTax
        totalDiscount
        totalPromoCodeDiscount
        totalVoucherDiscount
        updatedAt
        updatedBy
        voucherDiscount
        voucherNumber
      }
      status
    }
  }
`;
export const getCustomerOrderList = /* GraphQL */ `
  query GetCustomerOrderList(
    $accessToken: String
    $customerId: String
    $filter: SearchOrdersFilter
    $limit: Int
    $merchantId: String
    $nextToken: Int
    $sort: ElasticSearchSortDirection
  ) {
    getCustomerOrderList(
      accessToken: $accessToken
      customerId: $customerId
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      message
      nextToken
      orders {
        accumulatedRefundAmount
        actualDeliveryFee
        billingAddress
        billingCity
        billingCountry
        billingPostalCode
        billingState
        cancelledBy
        cancelledReason
        createdAt
        createdBy
        customerAccountNo
        customerFirstName
        customerIP
        customerId
        customerLastName
        customerMobileNo
        customerPrimaryEmail
        deliveryAddress
        deliveryCity
        deliveryCountry
        deliveryDiscount
        deliveryLatitude
        deliveryLongitude
        deliveryMode
        deliveryNumber
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartnerName
        deliveryPostalCode
        deliveryState
        deliveryStatus
        deliveryType
        driverName
        driverPhone
        driverPlateNumber
        estimatedDeliveryFee
        grandTotal
        isAdvancedOrder
        isRefunded
        isReviewAvailable
        manualPaymentMethodName
        manualPaymentReceipt
        merchantId
        noteToRider
        orderCancellationDateTime
        orderCollectedDateTime
        orderCompletedDateTime
        orderDateTime
        orderId
        orderNumber
        orderType
        overallRating
        packageNumber
        partialFulfilmentAmount
        partialFulfilmentDiscount
        paymentDateTime
        paymentMethod
        paymentStatus
        paymentType
        pickupAddress
        pickupCity
        pickupCountry
        pickupPostalCode
        pickupState
        promoCode
        promoCodeDiscount
        promoCodeId
        promoCodeTitle
        refundAmount
        refundCompleteDateTime
        refundReason
        remarks
        salesChannelName
        scheduledDateTime
        sellerNote
        standardDeliveryAwb
        standardDeliveryAwbIdLink
        standardDeliveryTrackingUrl
        status
        storeCode
        storeId
        storeName
        subtotal
        subtotalWithTax
        tableNumber
        taggingNames
        totalDiscount
        totalOrderItems
        totalPromoCodeDiscount
        totalRefundedAmount
        totalVoucherDiscount
        transactionId
        type
        updatedAt
        updatedBy
        voucherDiscount
        voucherNumber
      }
      status
      total
    }
  }
`;
export const getCustomerOrderPaymentMessage = /* GraphQL */ `
  query GetCustomerOrderPaymentMessage(
    $accessToken: String
    $customerId: String
    $merchantId: String
    $messageId: String
  ) {
    getCustomerOrderPaymentMessage(
      accessToken: $accessToken
      customerId: $customerId
      merchantId: $merchantId
      messageId: $messageId
    ) {
      createdAt
      errorMessage
      gatewayPaymentId
      gatewayPaymentParams
      gatewayPaymentUrl
      merchantId
      messageId
      orderId
      orderNumber
      orderPaymentMessageId
      updatedAt
    }
  }
`;
export const getCustomerWishList = /* GraphQL */ `
  query GetCustomerWishList(
    $accessToken: String
    $customerId: String
    $merchantId: String
    $nextToken: String
    $orderType: String
    $storeId: String
  ) {
    getCustomerWishList(
      accessToken: $accessToken
      customerId: $customerId
      merchantId: $merchantId
      nextToken: $nextToken
      orderType: $orderType
      storeId: $storeId
    ) {
      message
      products {
        createdAt
        createdBy
        customerId
        customerWishListId
        deliveryCompareAtPrice
        deliveryPrice
        deliveryPriceWithTax
        itemId
        itemImage
        itemProperty
        itemSeoUrl
        itemSku
        itemTitle
        itemVariantName1
        itemVariantName2
        itemVariantName3
        itemVariantValue1
        itemVariantValue2
        itemVariantValue3
        merchantId
        pickupCompareAtPrice
        pickupPrice
        pickupPriceWithoutTax
        productId
        salesChannelName
        updatedAt
        updatedBy
      }
      status
    }
  }
`;
export const getDeliveryQuotation = /* GraphQL */ `
  query GetDeliveryQuotation(
    $customerCartIds: [String]
    $customerFirstName: String
    $customerLastName: String
    $customerMobileNo: String
    $deliveryAddress: String
    $deliveryCity: String
    $deliveryCountry: String
    $deliveryPostalCode: String
    $deliveryState: String
    $merchantId: String
    $promoCode: String
  ) {
    getDeliveryQuotation(
      customerCartIds: $customerCartIds
      customerFirstName: $customerFirstName
      customerLastName: $customerLastName
      customerMobileNo: $customerMobileNo
      deliveryAddress: $deliveryAddress
      deliveryCity: $deliveryCity
      deliveryCountry: $deliveryCountry
      deliveryPostalCode: $deliveryPostalCode
      deliveryState: $deliveryState
      merchantId: $merchantId
      promoCode: $promoCode
    ) {
      deliveryOptions {
        estimatedDuration
        name
        rate
      }
      message
      status
    }
  }
`;
export const getFaqList = /* GraphQL */ `
  query GetFaqList($merchantId: String) {
    getFaqList(merchantId: $merchantId) {
      categories {
        childItems {
          answer
          createdAt
          createdBy
          faqCategory
          faqId
          faqType
          merchantId
          parentId
          question
          sequence
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        faqCategoryId
        merchantId
        sequence
        title
        updatedAt
        updatedBy
      }
      items {
        category
        faqContent {
          answer
          createdAt
          createdBy
          faqCategory
          faqId
          faqType
          merchantId
          parentId
          question
          sequence
          updatedAt
          updatedBy
        }
      }
      message
      status
    }
  }
`;
export const getFeaturedHomeCollectionCache = /* GraphQL */ `
  query GetFeaturedHomeCollectionCache($merchantId: String) {
    getFeaturedHomeCollectionCache(merchantId: $merchantId) {
      homeCollections {
        collectionIcon
        collectionId
        collectionImage
        collectionName
        collectionSeoUrl
        createdAt
        createdBy
        effectiveEndDateTime
        effectiveStartDateTime
        homeCollectionId
        homeCollectionType
        isDisabled
        merchantId
        sequence
        title
        updatedAt
        updatedBy
      }
      message
      status
    }
  }
`;
export const getHeader = /* GraphQL */ `
  query GetHeader($merchantId: String) {
    getHeader(merchantId: $merchantId) {
      image
      message
      status
      title
    }
  }
`;
export const getHomeScreenCollectionCache = /* GraphQL */ `
  query GetHomeScreenCollectionCache($merchantId: String) {
    getHomeScreenCollectionCache(merchantId: $merchantId) {
      collectionsAndItems {
        collectionImage
        homeCollectionTitle
        items {
          cover
          deliveryCompareAtPrice
          deliveryCompareAtPriceRange
          deliveryPrice
          deliveryPriceRange
          deliveryPriceWithTax
          deliveryPriceWithTaxRange
          description
          effectiveEndDateTime
          effectiveStartDateTime
          hasStock
          hasVariant
          homeCollectionId
          homeCollectionTitle
          image
          isDisabled
          itemId
          itemProperty
          productIsDisabled
          promotionEndDateTime
          promotionStartDateTime
          seoUrl
          title
          totalRatings
          totalReviews
          updatedAt
          video
        }
        productCollectionId
        productCollectionName
        productCollectionSeoUrl
        sequence
      }
      hasStock
      message
      productStatus
      status
    }
  }
`;
export const getIconBar = /* GraphQL */ `
  query GetIconBar($merchantId: String) {
    getIconBar(merchantId: $merchantId) {
      icons {
        description
        iconId
        image
        merchantId
        title
      }
      message
      status
    }
  }
`;
export const getLandingPageBannerCache = /* GraphQL */ `
  query GetLandingPageBannerCache($merchantId: String) {
    getLandingPageBannerCache(merchantId: $merchantId) {
      banners {
        buttonAction
        buttonActionValue
        buttonTitle
        createdAt
        createdBy
        description
        detailPageImage
        effectiveEndDateTime
        effectiveStartDateTime
        homeImage
        isDisabled
        landingPageBannerId
        merchantId
        selectedItem {
          itemId
          itemImage
          itemProperty
          itemSeoUrl
          itemTitle
        }
        seoDescription
        seoTitle
        seoUrl
        sequence
        title
        updatedAt
        updatedBy
      }
      message
      status
    }
  }
`;
export const getLandingPageBannerDetail = /* GraphQL */ `
  query GetLandingPageBannerDetail($merchantId: String, $seoUrl: String) {
    getLandingPageBannerDetail(merchantId: $merchantId, seoUrl: $seoUrl) {
      bannerDetail {
        buttonAction
        buttonActionValue
        buttonTitle
        createdAt
        createdBy
        description
        detailPageImage
        effectiveEndDateTime
        effectiveStartDateTime
        homeImage
        isDisabled
        landingPageBannerId
        merchantId
        selectedItem {
          itemId
          itemImage
          itemProperty
          itemSeoUrl
          itemTitle
        }
        seoDescription
        seoTitle
        seoUrl
        sequence
        title
        updatedAt
        updatedBy
      }
      message
      status
    }
  }
`;
export const getLandingPageMenuCache = /* GraphQL */ `
  query GetLandingPageMenuCache(
    $merchantId: String
    $platform: String
    $version: String
  ) {
    getLandingPageMenuCache(
      merchantId: $merchantId
      platform: $platform
      version: $version
    ) {
      menus {
        actionId
        actionType
        createdAt
        createdBy
        effectiveEndDate
        effectiveStartDate
        image
        isDisabled
        landingPageMenuId
        merchantId
        sequenceOrder
        title
        updatedAt
        updatedBy
      }
      message
      status
    }
  }
`;
export const getLandingPageProductCache = /* GraphQL */ `
  query GetLandingPageProductCache(
    $merchantId: String
    $orderType: String
    $page: Int
    $pageSize: Int
    $storeId: String
  ) {
    getLandingPageProductCache(
      merchantId: $merchantId
      orderType: $orderType
      page: $page
      pageSize: $pageSize
      storeId: $storeId
    ) {
      departmentId
      message
      productTaggingId
      products {
        categories
        category
        createdBy
        departments
        description
        dimension
        discount
        discountEndDate
        discountPercentage
        discountStartDate
        discountedPrice
        ecommerceMaximumQuantity
        image
        isAvailable
        isDisabled
        itemCategoryCode
        minDeliveryDuration
        minFoodPreparationDuration
        name
        pickupMaximumQuantity
        price
        productId
        promoCode
        promotionDescription
        sku
        taggings
        temperature
        title
        uom
        updatedBy
      }
      status
      title
      totalCount
    }
  }
`;
export const getLegalPolicy = /* GraphQL */ `
  query GetLegalPolicy($merchantId: String) {
    getLegalPolicy(merchantId: $merchantId) {
      legalPolicies {
        createdAt
        createdBy
        isDisabled
        merchantId
        policyContent
        policyType
        updatedAt
        updatedBy
      }
      message
      status
    }
  }
`;
export const getManualPaymentOrderStatus = /* GraphQL */ `
  query GetManualPaymentOrderStatus($merchantId: String, $orderNumber: String) {
    getManualPaymentOrderStatus(
      merchantId: $merchantId
      orderNumber: $orderNumber
    ) {
      grandTotal
      message
      orderId
      orderStatus
      status
    }
  }
`;
export const getMemberPointLog = /* GraphQL */ `
  query GetMemberPointLog(
    $accessToken: String
    $customerId: String
    $merchantId: String
  ) {
    getMemberPointLog(
      accessToken: $accessToken
      customerId: $customerId
      merchantId: $merchantId
    ) {
      memberPointLog {
        createdAt
        createdBy
        customerId
        expiredDateTime
        memberId
        memberPointLogId
        merchantId
        orderId
        points
        referenceId
        type
        updatedAt
        updatedBy
      }
      message
      status
    }
  }
`;
export const getMenuCache = /* GraphQL */ `
  query GetMenuCache($merchantId: String) {
    getMenuCache(merchantId: $merchantId) {
      menus {
        actionId
        actionType
        createdAt
        createdBy
        effectiveEndDate
        effectiveStartDate
        isDisabled
        menuId
        merchantId
        parentId
        sequenceOrder
        submenus {
          actionId
          actionType
          createdAt
          createdBy
          effectiveEndDate
          effectiveStartDate
          isDisabled
          menuId
          merchantId
          parentId
          sequenceOrder
          submenus {
            actionId
            actionType
            createdAt
            createdBy
            effectiveEndDate
            effectiveStartDate
            isDisabled
            menuId
            merchantId
            parentId
            sequenceOrder
            submenus {
              actionId
              actionType
              createdAt
              createdBy
              effectiveEndDate
              effectiveStartDate
              isDisabled
              menuId
              merchantId
              parentId
              sequenceOrder
              title
              updatedAt
              updatedBy
            }
            title
            updatedAt
            updatedBy
          }
          title
          updatedAt
          updatedBy
        }
        title
        updatedAt
        updatedBy
      }
      message
      status
    }
  }
`;
export const getMerchantDetails = /* GraphQL */ `
  query GetMerchantDetails($merchantId: String) {
    getMerchantDetails(merchantId: $merchantId) {
      aboutUsBanner
      aboutUsDescription
      address
      copyright
      email
      facebookName
      facebookUrl
      googlePlayUrl
      instagramName
      instagramUrl
      linkedInName
      linkedInUrl
      message
      name
      phone
      playStoreUrl
      status
      tiktokName
      tiktokUrl
      twitterName
      twitterUrl
      whatsappNo
      youtubeName
      youtubeUrl
    }
  }
`;
export const getMerchantLogo = /* GraphQL */ `
  query GetMerchantLogo($merchantId: String) {
    getMerchantLogo(merchantId: $merchantId) {
      logo
      message
      name
      status
    }
  }
`;
export const getNearbyStores = /* GraphQL */ `
  query GetNearbyStores(
    $customerAddress: String
    $deliveryMode: String
    $merchantId: String
  ) {
    getNearbyStores(
      customerAddress: $customerAddress
      deliveryMode: $deliveryMode
      merchantId: $merchantId
    ) {
      address
      city
      deliveryDuration
      deliveryFee
      deliveryServiceAvailable
      groupName
      isDisabled
      latitude
      longitude
      managerContact
      managerName
      maxPurchaseQuantity
      message
      minPurchase
      name
      offlineStoreOperate24Hour
      onlineStoreOperate24Hour
      pickupServiceAvailable
      postalCode
      preparationTime
      primaryEmail
      state
      status
      storeId
      taggings
    }
  }
`;
export const getNewsLetter = /* GraphQL */ `
  query GetNewsLetter($merchantId: String) {
    getNewsLetter(merchantId: $merchantId) {
      buttonLabel
      description
      message
      status
      title
    }
  }
`;
export const getOrderInvoice = /* GraphQL */ `
  query GetOrderInvoice($merchantId: String, $orderId: String) {
    getOrderInvoice(merchantId: $merchantId, orderId: $orderId) {
      message
      order {
        AWBRef
        barcode
        billingAddress
        currency
        deliveryAddressType
        deliveryCode
        deliveryDiscount
        deliveryNumber
        deliveryOrderDateTime
        deliveryType
        estimatedDeliveryFee
        grandTotal
        invoiceNo
        issueDateTime
        merchantEmail
        merchantName
        officeRemarks
        orderDateTime
        orderId
        orderItems {
          associatedProducts {
            productId
            productImage
            productSku
            productTitle
            productUOMId
            quantity
            variantName1
            variantName2
            variantName3
            variantValue1
            variantValue2
            variantValue3
          }
          comment
          createdAt
          createdBy
          deliveryCompareAtPrice
          deliveryPrice
          deliveryPriceWithTax
          fulfilledQuantity
          isFreeItem
          itemId
          itemImage
          itemIsVirtualGoods
          itemProperty
          itemSku
          itemStatus
          itemTitle
          itemVariantName1
          itemVariantName2
          itemVariantName3
          itemVariantValue1
          itemVariantValue2
          itemVariantValue3
          mandatoryItem
          manualPaymentReceipt
          merchantId
          orderDetailId
          orderId
          orderNumber
          orderedQuantity
          pickupCompareAtPrice
          pickupPrice
          pickupPriceWithTax
          promoCode
          promoCodeDiscount
          rating
          refundQuantity
          selectedModifierGroups {
            isSelected
            modifier {
              availableStatus
              isSelected
              modifierId
              modifierName
              price
            }
            modifierGroupId
            modifierGroupName
            modifierGroupType
            requestRemark
            selectionRangeMax
            selectionRangeMin
          }
          subtotal
          subtotalWithTax
          totalDiscount
          totalPromoCodeDiscount
          totalVoucherDiscount
          updatedAt
          updatedBy
          voucherDiscount
          voucherNumber
        }
        parcelInfo
        paymentAmount
        paymentType
        portCode
        printDateTime
        promoCode
        promoCodeDiscount
        promoCodeTitle
        recipientInfo {
          address
          name
          phone
        }
        remarks
        salesChannelName
        sellerRemarks
        senderInfo {
          merchantAddress
          merchantName
          merchantPhone
        }
        shippingAddress
        status
        subtotal
        subtotalWithTax
        totalOrderItems
        type
        weight
      }
      status
    }
  }
`;
export const getPageLayout = /* GraphQL */ `
  query GetPageLayout($merchantId: String, $type: String) {
    getPageLayout(merchantId: $merchantId, type: $type) {
      message
      pageLayout {
        collectionProductDisplayCount
        createdAt
        createdBy
        merchantId
        newsletterBanner
        newsletterText
        pageLayoutId
        type
        updatedAt
        updatedBy
      }
      status
    }
  }
`;
export const getPaymentOptions = /* GraphQL */ `
  query GetPaymentOptions($merchantId: String) {
    getPaymentOptions(merchantId: $merchantId) {
      manualPaymentOptionList {
        accountNumber
        bankAccountName
        isBankTransfer
        isDisabled
        manualPaymentMethodName
        manualPaymentOptionsId
        merchantId
        paymentInstructions
        qrCode
      }
      message
      status
    }
  }
`;
export const getPendingProfessionalServicesMerchantList = /* GraphQL */ `
  query GetPendingProfessionalServicesMerchantList {
    getPendingProfessionalServicesMerchantList {
      merchantList {
        aboutUsBanner
        aboutUsDescription
        activated
        address
        code
        copyright
        createdAt
        currency
        domain
        facebookName
        facebookPixelId
        facebookUrl
        favicon
        googleAnalyticsId
        googlePlayUrl
        instagramName
        instagramUrl
        isBlocked
        linkedInName
        linkedInUrl
        logo
        membershipTierActivated
        merchantId
        name
        notificationEmail
        offlineStore
        onlineStore
        ownerEmail
        ownerName
        ownerPhone
        playStoreUrl
        senderEmail
        seoDescription
        seoTitle
        serviceId
        servicePassword
        tax
        tiktokName
        tiktokUrl
        twitterName
        twitterUrl
        updatedAt
        whatsappNo
        youtubeName
        youtubeUrl
      }
      message
      status
    }
  }
`;
export const getProductBundleDetails = /* GraphQL */ `
  query GetProductBundleDetails($merchantId: String, $seoUrl: String) {
    getProductBundleDetails(merchantId: $merchantId, seoUrl: $seoUrl) {
      barcode
      collectionNames
      cover
      deliveryCompareAtPrice
      deliveryCostPerItem
      deliveryPrice
      deliveryPriceWithTax
      description
      effectiveEndDateTime
      effectiveStartDateTime
      image
      isDisabled
      isProductBundleTaxable
      merchantId
      message
      pickupCompareAtPrice
      pickupCostPerItem
      pickupPrice
      pickupPriceWithTax
      productBundleDetails {
        merchantId
        productBundleDetailId
        productBundleId
        productId
        productImage
        productSku
        productTitle
        productUOMId
        quantity
        updatedAt
        updatedBy
        variantName1
        variantName2
        variantName3
        variantValue1
        variantValue2
        variantValue3
      }
      productBundleId
      productBundlePricing {
        salesChannelName
        storeProducts {
          createdAt
          createdBy
          deliveryCompareAtPrice
          deliveryCostPerItem
          deliveryPrice
          deliveryPriceWithTax
          isDisabled
          marketPlaceAttributes {
            actualName
            hasChildren
            inputType
            isRequired
            parentId
            type
            typeId
            typeName
            typeValues {
              valueEnglishName
              valueId
              valueName
              valueUnit
            }
          }
          marketPlaceBrand {
            actualName
            hasChildren
            inputType
            isRequired
            parentId
            type
            typeId
            typeName
            typeValues {
              valueEnglishName
              valueId
              valueName
              valueUnit
            }
          }
          marketPlaceCategories {
            actualName
            hasChildren
            inputType
            isRequired
            parentId
            type
            typeId
            typeName
            typeValues {
              valueEnglishName
              valueId
              valueName
              valueUnit
            }
          }
          marketPlaceProductCode
          marketPlaceProductUOMCode
          merchantId
          noOfPurchases
          pickupCompareAtPrice
          pickupCostPerItem
          pickupPrice
          pickupPriceWithTax
          quantityForSales
          quantityType
          salesChannelName
          storeId
          storeName
          storeProductId
          updatedAt
          updatedBy
        }
      }
      promotionEndDateTime
      promotionStartDateTime
      quantityForSales
      sellOnFacebookStore
      sellOnFoodPanda
      sellOnGrabFood
      sellOnGrabMart
      sellOnInstagram
      sellOnLazada
      sellOnOfflineStore
      sellOnOnlineStore
      sellOnPandaMart
      sellOnShopee
      seoDescription
      seoTitle
      seoUrl
      shippingDimensionHeight
      shippingDimensionLength
      shippingDimensionWidth
      shippingWeight
      shippingWeightUnit
      sku
      status
      taggingNames
      title
      totalStockQuantity
    }
  }
`;
export const getProductCache = /* GraphQL */ `
  query GetProductCache(
    $departmentId: String
    $merchantId: String
    $orderType: String
    $page: Int
    $pageSize: Int
    $productTaggingId: String
    $storeId: String
  ) {
    getProductCache(
      departmentId: $departmentId
      merchantId: $merchantId
      orderType: $orderType
      page: $page
      pageSize: $pageSize
      productTaggingId: $productTaggingId
      storeId: $storeId
    ) {
      departmentId
      message
      productTaggingId
      products {
        categories
        category
        createdBy
        departments
        description
        dimension
        discount
        discountEndDate
        discountPercentage
        discountStartDate
        discountedPrice
        ecommerceMaximumQuantity
        image
        isAvailable
        isDisabled
        itemCategoryCode
        minDeliveryDuration
        minFoodPreparationDuration
        name
        pickupMaximumQuantity
        price
        productId
        promoCode
        promotionDescription
        sku
        taggings
        temperature
        title
        uom
        updatedBy
      }
      status
      title
      totalCount
    }
  }
`;
export const getProductDetails = /* GraphQL */ `
  query GetProductDetails(
    $merchantId: String
    $seoUrl: String
    $storeId: String
  ) {
    getProductDetails(
      merchantId: $merchantId
      seoUrl: $seoUrl
      storeId: $storeId
    ) {
      cover
      description
      discountPercentage
      image
      message
      modifierGroups {
        isSelected
        modifier {
          availableStatus
          isSelected
          modifierId
          modifierName
          price
        }
        modifierGroupId
        modifierGroupName
        modifierGroupType
        requestRemark
        selectionRangeMax
        selectionRangeMin
      }
      priceComparedAtPriceRange
      priceRange
      productIsDisabled
      productUOMs {
        barcode
        collectionNames
        deliveryCompareAtPrice
        deliveryCostPerItem
        deliveryEffectiveEndDateTime
        deliveryEffectiveStartDateTime
        deliveryPrice
        deliveryPriceWithTax
        image
        incomingQuantity
        isDisabled
        isProductTaxable
        marketPlaceAttributes {
          actualName
          hasChildren
          inputType
          isRequired
          parentId
          type
          typeId
          typeName
          typeValues {
            valueEnglishName
            valueId
            valueName
            valueUnit
          }
        }
        marketPlaceBrand {
          actualName
          hasChildren
          inputType
          isRequired
          parentId
          type
          typeId
          typeName
          typeValues {
            valueEnglishName
            valueId
            valueName
            valueUnit
          }
        }
        marketPlaceCategories {
          actualName
          hasChildren
          inputType
          isRequired
          parentId
          type
          typeId
          typeName
          typeValues {
            valueEnglishName
            valueId
            valueName
            valueUnit
          }
        }
        marketPlaceProductUOMCode
        marketPlaceShippingPartner
        merchantId
        noOfPurchases
        pickupCompareAtPrice
        pickupCostPerItem
        pickupEffectiveEndDateTime
        pickupEffectiveStartDateTime
        pickupPrice
        pickupPriceWithTax
        productId
        productTitle
        productUOMId
        productUOMPricingId
        quantityForSales
        quantityType
        salesChannelName
        shippingDimensionHeight
        shippingDimensionLength
        shippingDimensionWidth
        shippingWeight
        shippingWeightUnit
        sku
        storeId
        storeName
        storeProductId
        taggingNames
        totalStockQuantity
        trackQuantity
        variantName1
        variantName2
        variantName3
        variantValue1
        variantValue2
        variantValue3
      }
      status
      title
      totalRatings
      totalReviews
      variantName1
      variantName2
      variantName3
      variantValues1
      variantValues2
      variantValues3
      video
    }
  }
`;
export const getProductsRecommendation = /* GraphQL */ `
  query GetProductsRecommendation(
    $merchantId: String
    $salesChannelName: String
  ) {
    getProductsRecommendation(
      merchantId: $merchantId
      salesChannelName: $salesChannelName
    ) {
      message
      products {
        barcode
        collectionNames
        continueSellingWhenOOS
        createdAt
        createdBy
        discountedPrice
        isDisabled
        media
        merchantId
        price
        productId
        productTitle
        productUOMId
        quantity
        seoUrl
        sku
        taggingNames
        trackQuantity
        updatedAt
        updatedBy
        variant {
          name
          value
        }
      }
      status
    }
  }
`;
export const getRecaptchaResponse = /* GraphQL */ `
  query GetRecaptchaResponse($token: String) {
    getRecaptchaResponse(token: $token) {
      message
      status
    }
  }
`;
export const getShopByCategoryCache = /* GraphQL */ `
  query GetShopByCategoryCache($merchantId: String) {
    getShopByCategoryCache(merchantId: $merchantId) {
      homeCollections {
        collectionIcon
        collectionId
        collectionImage
        collectionName
        collectionSeoUrl
        createdAt
        createdBy
        effectiveEndDateTime
        effectiveStartDateTime
        homeCollectionId
        homeCollectionType
        isDisabled
        merchantId
        sequence
        title
        updatedAt
        updatedBy
      }
      message
      status
    }
  }
`;
export const getSiteNavigationCache = /* GraphQL */ `
  query GetSiteNavigationCache($merchantId: String) {
    getSiteNavigationCache(merchantId: $merchantId) {
      message
      siteNavigations {
        childItems {
          childItems {
            childItems {
              createdAt
              createdBy
              effectiveEndDateTime
              effectiveStartDateTime
              isDisabled
              linkValue
              menuType
              merchantId
              navigationType
              openNewTab
              parentId
              sequence
              siteNavigationId
              title
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            effectiveEndDateTime
            effectiveStartDateTime
            isDisabled
            linkValue
            menuType
            merchantId
            navigationType
            openNewTab
            parentId
            sequence
            siteNavigationId
            title
            updatedAt
            updatedBy
          }
          createdAt
          createdBy
          effectiveEndDateTime
          effectiveStartDateTime
          isDisabled
          linkValue
          menuType
          merchantId
          navigationType
          openNewTab
          parentId
          sequence
          siteNavigationId
          title
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        effectiveEndDateTime
        effectiveStartDateTime
        isDisabled
        linkValue
        menuType
        merchantId
        navigationType
        openNewTab
        parentId
        sequence
        siteNavigationId
        title
        updatedAt
        updatedBy
      }
      status
    }
  }
`;
export const getSplashScreenCache = /* GraphQL */ `
  query GetSplashScreenCache($merchantId: String) {
    getSplashScreenCache(merchantId: $merchantId) {
      items {
        buttonAction
        buttonActionValue
        createdAt
        createdBy
        effectiveEndDateTime
        effectiveStartDateTime
        homeImage
        isDisabled
        merchantId
        selectedItem {
          itemId
          itemImage
          itemProperty
          itemSeoUrl
          itemTitle
        }
        sequence
        splashScreenId
        title
        updatedAt
        updatedBy
      }
      message
      status
    }
  }
`;
export const getStandardDeliveryQuotation = /* GraphQL */ `
  query GetStandardDeliveryQuotation(
    $customerCartIds: [String]
    $customerFirstName: String
    $customerLastName: String
    $customerMobileNo: String
    $deliveryAddress: String
    $deliveryCity: String
    $deliveryCountry: String
    $deliveryPostalCode: String
    $deliveryState: String
    $merchantId: String
    $promoCode: String
  ) {
    getStandardDeliveryQuotation(
      customerCartIds: $customerCartIds
      customerFirstName: $customerFirstName
      customerLastName: $customerLastName
      customerMobileNo: $customerMobileNo
      deliveryAddress: $deliveryAddress
      deliveryCity: $deliveryCity
      deliveryCountry: $deliveryCountry
      deliveryPostalCode: $deliveryPostalCode
      deliveryState: $deliveryState
      merchantId: $merchantId
      promoCode: $promoCode
    ) {
      deliveryOptions {
        estimatedDuration
        name
        rate
      }
      message
      status
    }
  }
`;
export const getThankYouPageOrderInfo = /* GraphQL */ `
  query GetThankYouPageOrderInfo(
    $accessToken: String
    $merchantId: String
    $orderNumber: String
  ) {
    getThankYouPageOrderInfo(
      accessToken: $accessToken
      merchantId: $merchantId
      orderNumber: $orderNumber
    ) {
      customerFirstName
      customerLastName
      customerMobileNo
      customerPrimaryEmail
      deliveryAddress
      deliveryCity
      deliveryCountry
      deliveryFee
      deliveryPostalCode
      deliveryState
      grandTotal
      isBankTransfer
      manualPaymentMethodName
      manualPaymentOrderStatus
      message
      orderId
      orderNumber
      paymentMethod
      paymentStatus
      paymentType
      status
      subtotal
      totalDiscount
    }
  }
`;
export const getVirtualGoodsRedemptionCode = /* GraphQL */ `
  query GetVirtualGoodsRedemptionCode(
    $merchantId: String
    $orderDetailId: String
  ) {
    getVirtualGoodsRedemptionCode(
      merchantId: $merchantId
      orderDetailId: $orderDetailId
    ) {
      message
      status
      virtualGoodsRedemption {
        code
        customerFirstName
        customerId
        customerLastName
        customerMobileNo
        customerPrimaryEmail
        expiredAt
        merchantId
        orderDetailId
        orderId
        orderNumber
        status
        virtualGoodsRedemptionId
      }
    }
  }
`;
export const kdsGetMerchantServiceCatalog = /* GraphQL */ `
  query KdsGetMerchantServiceCatalog {
    kdsGetMerchantServiceCatalog {
      addOnPackageList {
        createdAt
        createdBy
        description
        packageId
        packageType
        price
        title
        updatedAt
        updatedBy
      }
      subscriptionPackageList {
        createdAt
        createdBy
        description
        packageId
        packageType
        price
        title
        updatedAt
        updatedBy
      }
    }
  }
`;
export const kdsGetOrderDetail = /* GraphQL */ `
  query KdsGetOrderDetail(
    $merchantId: String
    $orderId: String
    $storeId: String
    $token: String
  ) {
    kdsGetOrderDetail(
      merchantId: $merchantId
      orderId: $orderId
      storeId: $storeId
      token: $token
    ) {
      collectionMethod
      customerName
      deliveryPartnerName
      deliveryTime
      orderId
      orderNumber
      orderQuantity
      orderStatus
      productList {
        itemTitle
        kdsOrderDetailId
        productCode
        productStatus
        quantity
      }
      remark
      riderContact
      riderName
      status
    }
  }
`;
export const kdsGetOrderList = /* GraphQL */ `
  query KdsGetOrderList(
    $merchantId: String
    $status: String
    $storeId: String
    $token: String
  ) {
    kdsGetOrderList(
      merchantId: $merchantId
      status: $status
      storeId: $storeId
      token: $token
    ) {
      kdsOrderList {
        collectionMethod
        customerName
        deliveryPartnerName
        deliveryTime
        orderId
        orderNumber
        orderQuantity
        orderStatus
        productList {
          itemTitle
          kdsOrderDetailId
          productCode
          productStatus
          quantity
        }
        remark
        riderContact
        riderName
        status
      }
      message
      status
    }
  }
`;
export const kdsGetWarungLandingPage = /* GraphQL */ `
  query KdsGetWarungLandingPage($merchantId: String) {
    kdsGetWarungLandingPage(merchantId: $merchantId) {
      address
      item {
        close
        day
        open
      }
      message
      orderOption
      status
      warungStoreStatus
    }
  }
`;
export const kdsOrderHistortList = /* GraphQL */ `
  query KdsOrderHistortList(
    $merchantId: String
    $orderType: String
    $totalDayOfRecord: String
  ) {
    kdsOrderHistortList(
      merchantId: $merchantId
      orderType: $orderType
      totalDayOfRecord: $totalDayOfRecord
    ) {
      message
      noOfCancelledOrder
      noOfOrderCompleted
      noOfRefundedOrder
      orderList {
        amount
        orderDateTime
        orderId
        orderNumber
      }
      status
      totalAmountOfPendingRefundAmount
      totalAmountOfRefundedAmount
      totalAmountOfRevenue
    }
  }
`;
export const listItemReviews = /* GraphQL */ `
  query ListItemReviews(
    $itemId: String
    $itemProperty: String
    $limit: Int
    $merchantId: String
    $nextToken: String
  ) {
    listItemReviews(
      itemId: $itemId
      itemProperty: $itemProperty
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
    ) {
      items {
        comment
        createdAt
        createdBy
        customerFirstName
        customerLastName
        itemId
        itemProperty
        itemVariantName1
        itemVariantName2
        itemVariantName3
        itemVariantValue1
        itemVariantValue2
        itemVariantValue3
        merchantId
        orderDetailId
        orderId
        orderNumber
        productReviewId
        rating
        reviewAsAnonymous
        updatedAt
        updatedBy
      }
      message
      nextToken
      status
      total
    }
  }
`;
export const listItemsByCollection = /* GraphQL */ `
  query ListItemsByCollection(
    $filter: SearchProductsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $seoUrl: String
    $sort: ElasticSearchSortDirection
  ) {
    listItemsByCollection(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      seoUrl: $seoUrl
      sort: $sort
    ) {
      items {
        cover
        deliveryCompareAtPrice
        deliveryCompareAtPriceRange
        deliveryPrice
        deliveryPriceRange
        deliveryPriceWithTax
        deliveryPriceWithTaxRange
        description
        effectiveEndDateTime
        effectiveStartDateTime
        hasStock
        hasVariant
        homeCollectionId
        homeCollectionTitle
        image
        isDisabled
        itemId
        itemProperty
        productIsDisabled
        promotionEndDateTime
        promotionStartDateTime
        seoUrl
        title
        totalRatings
        totalReviews
        updatedAt
        video
      }
      message
      nextToken
      productCollectionBanner
      productCollectionId
      productCollectionName
      status
      total
    }
  }
`;
export const listSimilarItems = /* GraphQL */ `
  query ListSimilarItems(
    $itemProperty: String
    $merchantId: String
    $seoUrl: String
  ) {
    listSimilarItems(
      itemProperty: $itemProperty
      merchantId: $merchantId
      seoUrl: $seoUrl
    ) {
      items {
        cover
        deliveryCompareAtPrice
        deliveryPrice
        deliveryPriceWithTax
        description
        effectiveEndDateTime
        effectiveStartDateTime
        hasStock
        hasVariant
        image
        itemId
        itemProperty
        productIsDisabled
        promotionEndDateTime
        promotionStartDateTime
        seoUrl
        title
        updatedAt
        video
      }
      message
      status
    }
  }
`;
export const searchContactUsStores = /* GraphQL */ `
  query SearchContactUsStores(
    $filter: SearchContactUsStoreFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchContactUsStores(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        address
        city
        contactUsStoreId
        createdAt
        createdBy
        latitude
        longitude
        merchantId
        postalCode
        state
        storeCode
        storeId
        storeName
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchCustomOrders = /* GraphQL */ `
  query SearchCustomOrders(
    $filter: SearchCustomOrdersFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchCustomOrders(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        billingAddress
        billingCity
        billingCountry
        billingPostalCode
        billingState
        cognitoUsername
        createdAt
        createdBy
        customOrderId
        customerFirstName
        customerId
        customerLastName
        itemList {
          itemId
          itemProperty
          itemTitle
          quantity
          subtotal
          type
        }
        merchantId
        mobileNo
        orderId
        orderNumber
        remark
        shippingAddress
        shippingCity
        shippingCountry
        shippingPostalCode
        shippingState
        status
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchCustomerVouchers = /* GraphQL */ `
  query SearchCustomerVouchers(
    $filter: SearchCustomerVouchersFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchCustomerVouchers(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        createdAt
        createdBy
        customerId
        customerVoucherId
        merchantId
        status
        updatedAt
        updatedBy
        voucherCode
        voucherExpiryDate
        voucherId
      }
      nextToken
      total
    }
  }
`;
export const searchCustomers = /* GraphQL */ `
  query SearchCustomers(
    $filter: SearchCustomersFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchCustomers(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        accountNo
        activated
        address
        address2
        city
        consentPersonalData
        country
        createdBy
        customerId
        dateOfBirth
        deviceEndpoint
        deviceToken
        emailSubcriptionStatus
        employmentStatus
        facebookID
        firstName
        gender
        hasCompletedProfile
        hasRewarded
        identityCard
        isBlocked
        isFacebook
        lastName
        lastPurchasedDateTime
        maritalStatus
        membershipPoint
        membershipPointExpiryDate
        membershipTier
        merchantId
        mobileNo
        nationality
        password
        personalIncomeLevel
        postal
        primaryEmail
        profilePicture
        qrCode
        race
        secondaryEmail
        signedUpDateTime
        state
        taggingNames
        totalSalesOrder
        totalSpent
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchFacebookLiveCampaigns = /* GraphQL */ `
  query SearchFacebookLiveCampaigns(
    $filter: SearchFacebookLiveCampaignsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchFacebookLiveCampaigns(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        createdAt
        createdBy
        facebookLiveCampaignId
        merchantId
        message
        salesChannelName
        scheduleEndDateTime
        scheduleStartDateTime
        status
        storeId
        storeName
        title
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchFaqCategories = /* GraphQL */ `
  query SearchFaqCategories(
    $filter: SearchFaqCategoriesFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchFaqCategories(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        childItems {
          answer
          createdAt
          createdBy
          faqCategory
          faqId
          faqType
          merchantId
          parentId
          question
          sequence
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        faqCategoryId
        merchantId
        sequence
        title
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchFaqs = /* GraphQL */ `
  query SearchFaqs(
    $filter: SearchFaqsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchFaqs(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        answer
        createdAt
        createdBy
        faqCategory
        faqId
        faqType
        merchantId
        parentId
        question
        sequence
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchGalleryDetails = /* GraphQL */ `
  query SearchGalleryDetails(
    $filter: SearchGalleryDetailsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchGalleryDetails(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        createdAt
        createdBy
        filesize
        galleryDetailId
        merchantId
        name
        s3Path
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchHomeCollections = /* GraphQL */ `
  query SearchHomeCollections(
    $filter: SearchHomeCollectionsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchHomeCollections(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        collectionIcon
        collectionId
        collectionImage
        collectionName
        collectionSeoUrl
        createdAt
        createdBy
        effectiveEndDateTime
        effectiveStartDateTime
        homeCollectionId
        homeCollectionType
        isDisabled
        merchantId
        sequence
        title
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchKdsOrders = /* GraphQL */ `
  query SearchKdsOrders(
    $filter: SearchKdsOrdersFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchKdsOrders(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        collectionMethod
        customerName
        deliveryPartnerName
        deliveryTime
        orderId
        orderNumber
        orderQuantity
        status
      }
      nextToken
      total
    }
  }
`;
export const searchLandingPageBanners = /* GraphQL */ `
  query SearchLandingPageBanners(
    $filter: SearchLandingPageBannersFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchLandingPageBanners(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        buttonAction
        buttonActionValue
        buttonTitle
        createdAt
        createdBy
        description
        detailPageImage
        effectiveEndDateTime
        effectiveStartDateTime
        homeImage
        isDisabled
        landingPageBannerId
        merchantId
        selectedItem {
          itemId
          itemImage
          itemProperty
          itemSeoUrl
          itemTitle
        }
        seoDescription
        seoTitle
        seoUrl
        sequence
        title
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchMarketplaceSyncHistories = /* GraphQL */ `
  query SearchMarketplaceSyncHistories(
    $filter: SearchMarketplaceSyncHistoriesFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchMarketplaceSyncHistories(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        createdAt
        createdBy
        merchantId
        merketPlaceSyncHistoryId
        noOfRecords
        salesChannelId
        salesChannelName
        syncStatus
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchMemberPointLog = /* GraphQL */ `
  query SearchMemberPointLog(
    $filter: SearchMemberPointLogFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchMemberPointLog(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        createdAt
        createdBy
        customerId
        expiredDateTime
        memberId
        memberPointLogId
        merchantId
        orderId
        points
        referenceId
        type
        updatedAt
        updatedBy
      }
      message
      nextToken
      status
      total
    }
  }
`;
export const searchMembershipTiers = /* GraphQL */ `
  query SearchMembershipTiers(
    $filter: SearchMembershipTiersFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchMembershipTiers(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        createdAt
        createdBy
        membershipTierId
        merchantId
        point
        title
        updatedAt
        updatedBy
      }
      message
      nextToken
      status
      total
    }
  }
`;
export const searchMerchantCreditTransactions = /* GraphQL */ `
  query SearchMerchantCreditTransactions(
    $filter: SearchMerchantCreditTransactionsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchMerchantCreditTransactions(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        actionType
        amount
        createdAt
        createdBy
        merchantCreditTransactionId
        merchantId
        merchantOrderId
        orderNumber
        transactionId
      }
      nextToken
      total
    }
  }
`;
export const searchOrders = /* GraphQL */ `
  query SearchOrders(
    $filter: SearchOrdersFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchOrders(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        accumulatedRefundAmount
        actualDeliveryFee
        billingAddress
        billingCity
        billingCountry
        billingPostalCode
        billingState
        cancelledBy
        cancelledReason
        createdAt
        createdBy
        customerAccountNo
        customerFirstName
        customerIP
        customerId
        customerLastName
        customerMobileNo
        customerPrimaryEmail
        deliveryAddress
        deliveryCity
        deliveryCountry
        deliveryDiscount
        deliveryLatitude
        deliveryLongitude
        deliveryMode
        deliveryNumber
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartnerName
        deliveryPostalCode
        deliveryState
        deliveryStatus
        deliveryType
        driverName
        driverPhone
        driverPlateNumber
        estimatedDeliveryFee
        grandTotal
        isAdvancedOrder
        isRefunded
        isReviewAvailable
        manualPaymentMethodName
        manualPaymentReceipt
        merchantId
        noteToRider
        orderCancellationDateTime
        orderCollectedDateTime
        orderCompletedDateTime
        orderDateTime
        orderId
        orderNumber
        orderType
        overallRating
        packageNumber
        partialFulfilmentAmount
        partialFulfilmentDiscount
        paymentDateTime
        paymentMethod
        paymentStatus
        paymentType
        pickupAddress
        pickupCity
        pickupCountry
        pickupPostalCode
        pickupState
        promoCode
        promoCodeDiscount
        promoCodeId
        promoCodeTitle
        refundAmount
        refundCompleteDateTime
        refundReason
        remarks
        salesChannelName
        scheduledDateTime
        sellerNote
        standardDeliveryAwb
        standardDeliveryAwbIdLink
        standardDeliveryTrackingUrl
        status
        storeCode
        storeId
        storeName
        subtotal
        subtotalWithTax
        tableNumber
        taggingNames
        totalDiscount
        totalOrderItems
        totalPromoCodeDiscount
        totalRefundedAmount
        totalVoucherDiscount
        transactionId
        type
        updatedAt
        updatedBy
        voucherDiscount
        voucherNumber
      }
      nextToken
      total
    }
  }
`;
export const searchPointConversions = /* GraphQL */ `
  query SearchPointConversions(
    $filter: SearchPointConversionsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchPointConversions(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        createdAt
        createdBy
        effectiveEndDateTime
        effectiveStartDateTime
        itemId
        itemImage
        itemProperty
        itemTitle
        merchantId
        multiplier
        pointConversionId
        productId
        spent
        updatedAt
        updatedBy
      }
      message
      nextToken
      status
      total
    }
  }
`;
export const searchPriceGroups = /* GraphQL */ `
  query SearchPriceGroups(
    $filter: SearchPriceGroupsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchPriceGroups(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        createdAt
        createdBy
        increment
        incrementType
        merchantId
        name
        priceGroupId
        stores {
          storeCode
          storeId
          storeName
        }
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchProductBundles = /* GraphQL */ `
  query SearchProductBundles(
    $filter: SearchProductBundlesFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchProductBundles(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        barcode
        collectionNames
        cover
        createdAt
        createdBy
        deliveryCompareAtPrice
        deliveryCostPerItem
        deliveryPrice
        deliveryPriceWithTax
        description
        effectiveEndDateTime
        effectiveStartDateTime
        hasVariant
        image
        isDisabled
        isProductBundleTaxable
        merchantId
        pickupCompareAtPrice
        pickupCostPerItem
        pickupPrice
        pickupPriceWithTax
        productBundleId
        promotionEndDateTime
        promotionStartDateTime
        sellOnFacebookStore
        sellOnFoodPanda
        sellOnGrabFood
        sellOnGrabMart
        sellOnInstagram
        sellOnLazada
        sellOnOfflineStore
        sellOnOnlineStore
        sellOnPandaMart
        sellOnShopee
        seoDescription
        seoTitle
        seoUrl
        shippingDimensionHeight
        shippingDimensionLength
        shippingDimensionWidth
        shippingWeight
        shippingWeightUnit
        sku
        taggingNames
        title
        updatedAt
        updatedBy
        video
      }
      nextToken
      total
    }
  }
`;
export const searchProductCollections = /* GraphQL */ `
  query SearchProductCollections(
    $filter: SearchProductCollectionsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchProductCollections(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        banner
        condition
        conditionType
        createdAt
        createdBy
        description
        icon
        merchantId
        name
        productCollectionId
        seoDescription
        seoTitle
        seoUrl
        taggingNames
        type
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchProductExclusions = /* GraphQL */ `
  query SearchProductExclusions(
    $filter: SearchProductExclusionsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchProductExclusions(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        createdAt
        createdBy
        itemId
        itemImage
        itemProperty
        itemTitle
        merchantId
        productExclusionId
        productId
        type
        updatedAt
        updatedBy
      }
      message
      nextToken
      status
      total
    }
  }
`;
export const searchProductUOMs = /* GraphQL */ `
  query SearchProductUOMs(
    $filter: SearchProductUOMsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchProductUOMs(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        barcode
        collectionNames
        createdAt
        createdBy
        image
        incomingQuantity
        isDisabled
        isProductTaxable
        isVirtualGoods
        merchantId
        modifierGroups {
          isSelected
          modifier {
            availableStatus
            isSelected
            modifierId
            modifierName
            price
          }
          modifierGroupId
          modifierGroupName
          modifierGroupType
          requestRemark
          selectionRangeMax
          selectionRangeMin
        }
        noOfPurchases
        productCover
        productId
        productTitle
        productUOMId
        quantityForSales
        shippingDimensionHeight
        shippingDimensionLength
        shippingDimensionWidth
        shippingWeight
        shippingWeightUnit
        sku
        taggingNames
        totalStockQuantity
        trackQuantity
        updatedAt
        updatedBy
        variantName1
        variantName2
        variantName3
        variantValue1
        variantValue2
        variantValue3
        virtualGoodsExpiredAt
        virtualGoodsExpiryDays
      }
      nextToken
      total
    }
  }
`;
export const searchProducts = /* GraphQL */ `
  query SearchProducts(
    $filter: SearchProductsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchProducts(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        code
        collectionNames
        cover
        createdAt
        createdBy
        deliveryCompareAtPrice
        deliveryCostPerItem
        deliveryPrice
        deliveryPriceWithTax
        description
        effectiveEndDateTime
        effectiveStartDateTime
        hasVariant
        image
        isDisabled
        isVirtualGoods
        merchantId
        noOfPurchases
        noOfViews
        pickupCompareAtPrice
        pickupCostPerItem
        pickupPrice
        pickupPriceWithTax
        platform
        productId
        promotionEndDateTime
        promotionStartDateTime
        sellOnFacebookStore
        sellOnFoodPanda
        sellOnGrabFood
        sellOnGrabMart
        sellOnInstagram
        sellOnLazada
        sellOnOfflineStore
        sellOnOnlineStore
        sellOnPandaMart
        sellOnShopee
        seoDescription
        seoTitle
        seoUrl
        taggingNames
        title
        updatedAt
        updatedBy
        variantName1
        variantName2
        variantName3
        variantValues1
        variantValues2
        variantValues3
        video
        virtualGoodsExpiredAt
        virtualGoodsExpiryDays
      }
      nextToken
      total
    }
  }
`;
export const searchPromoCodeCampaigns = /* GraphQL */ `
  query SearchPromoCodeCampaigns(
    $filter: SearchPromoCodeCampaignsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchPromoCodeCampaigns(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        activeCount
        category
        createdBy
        customerCondition
        deliveryDiscountType
        discountAmountCap
        discountOnProductValue
        discountQuantityCap
        effectiveEndDateTime
        effectiveStartDateTime
        isDisabled
        maxUserUsageLimit
        merchantId
        minimumCondition
        minimumPurchase
        minimumQuantity
        orderType
        productConditions {
          ids {
            collectionName
            productBundleId
            productId
            productUOMId
          }
          type
        }
        productsDiscount {
          ids {
            collectionName
            productBundleId
            productId
            productUOMId
          }
          type
        }
        promoCode
        promoCodeCampaignId
        remainingUsage
        specificCustomerTag
        specificCustomers
        status
        stores
        title
        totalUsageLimit
        type
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchSiteNavigations = /* GraphQL */ `
  query SearchSiteNavigations(
    $filter: SearchSiteNavigationsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchSiteNavigations(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        childItems {
          childItems {
            childItems {
              createdAt
              createdBy
              effectiveEndDateTime
              effectiveStartDateTime
              isDisabled
              linkValue
              menuType
              merchantId
              navigationType
              openNewTab
              parentId
              sequence
              siteNavigationId
              title
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            effectiveEndDateTime
            effectiveStartDateTime
            isDisabled
            linkValue
            menuType
            merchantId
            navigationType
            openNewTab
            parentId
            sequence
            siteNavigationId
            title
            updatedAt
            updatedBy
          }
          createdAt
          createdBy
          effectiveEndDateTime
          effectiveStartDateTime
          isDisabled
          linkValue
          menuType
          merchantId
          navigationType
          openNewTab
          parentId
          sequence
          siteNavigationId
          title
          updatedAt
          updatedBy
        }
        createdAt
        createdBy
        effectiveEndDateTime
        effectiveStartDateTime
        isDisabled
        linkValue
        menuType
        merchantId
        navigationType
        openNewTab
        parentId
        sequence
        siteNavigationId
        title
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchSplashScreens = /* GraphQL */ `
  query SearchSplashScreens(
    $filter: SearchSplashScreensFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchSplashScreens(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        buttonAction
        buttonActionValue
        createdAt
        createdBy
        effectiveEndDateTime
        effectiveStartDateTime
        homeImage
        isDisabled
        merchantId
        selectedItem {
          itemId
          itemImage
          itemProperty
          itemSeoUrl
          itemTitle
        }
        sequence
        splashScreenId
        title
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchStockMovements = /* GraphQL */ `
  query SearchStockMovements(
    $filter: SearchStockMovementsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchStockMovements(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        createdAt
        createdBy
        expectedArrivalDate
        merchantId
        referenceNumber
        status
        stockMovementId
        supplierId
        supplierName
        taggingNames
        totalOrderedQuantity
        totalReceivedQuantity
        trackingNumber
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchStoreGroups = /* GraphQL */ `
  query SearchStoreGroups(
    $filter: SearchStoreGroupsFilter
    $limit: Int
    $merchantId: String
    $name: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchStoreGroups(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      name: $name
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        createdAt
        createdBy
        merchantId
        name
        storeGroupId
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchStoreProducts = /* GraphQL */ `
  query SearchStoreProducts(
    $filter: SearchStoreProductsFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchStoreProducts(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        collectionNames
        createdAt
        createdBy
        deliveryCompareAtPrice
        deliveryPrice
        deliveryPriceWithTax
        isDisabled
        itemCover
        itemHasVariant
        itemId
        itemImage
        itemIsVirtualGoods
        itemProperty
        itemSeoUrl
        itemSku
        itemTitle
        itemVariantName1
        itemVariantName2
        itemVariantName3
        itemVariantValue1
        itemVariantValue2
        itemVariantValue3
        itemVirtualGoodsExpiredAt
        itemVirtualGoodsExpiryDays
        marketPlaceAttributes {
          actualName
          hasChildren
          inputType
          isRequired
          parentId
          type
          typeId
          typeName
          typeValues {
            valueEnglishName
            valueId
            valueName
            valueUnit
          }
        }
        marketPlaceBrand {
          actualName
          hasChildren
          inputType
          isRequired
          parentId
          type
          typeId
          typeName
          typeValues {
            valueEnglishName
            valueId
            valueName
            valueUnit
          }
        }
        marketPlaceCategories {
          actualName
          hasChildren
          inputType
          isRequired
          parentId
          type
          typeId
          typeName
          typeValues {
            valueEnglishName
            valueId
            valueName
            valueUnit
          }
        }
        marketPlaceProductCode
        marketPlaceProductUOMCode
        merchantId
        modifierGroups {
          isSelected
          modifier {
            availableStatus
            isSelected
            modifierId
            modifierName
            price
          }
          modifierGroupId
          modifierGroupName
          modifierGroupType
          requestRemark
          selectionRangeMax
          selectionRangeMin
        }
        noOfPurchases
        pickupCompareAtPrice
        pickupPrice
        pickupPriceWithTax
        quantityForSales
        quantityType
        salesChannelName
        storeId
        storeName
        storeProductId
        taggingNames
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchStores = /* GraphQL */ `
  query SearchStores(
    $filter: SearchStoresFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchStores(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        acceptOrderWithMinPurchase
        address
        city
        closingHour
        code
        createdAt
        createdBy
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryDiscountType
        deliveryFee
        deliveryGracePeriod
        deliveryPartnerMaxRetry
        deliveryServiceAvailable
        driveThru
        eCommEndDate
        eCommStartDate
        freeDeliveryWithMinPurchase
        groupName
        hasSeatingArea
        incrementDistanceCharges
        incrementDistanceUnit
        isDisabled
        isOffline24Hour
        isOnline24Hour
        lastOrderTime
        lastSyncOrderDateTime
        latitude
        longitude
        managerContact
        managerEmail
        managerName
        maxOrderQty
        merchantId
        minDeliveryDuration
        minDistance
        minDistanceCharges
        minFoodPreparationDuration
        minPurchaseAmount
        name
        openingHour
        pickupServiceAvailable
        pollingDeliveryTime
        postalCode
        priceGroupId
        priceGroupName
        priorityDeliveryPartner
        riderTimeout
        routingOption
        salesChannelId
        salesChannelName
        state
        storeId
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchSuppliers = /* GraphQL */ `
  query SearchSuppliers(
    $filter: SearchSuppliersFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchSuppliers(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        address
        contact
        contactName
        country
        email
        merchantId
        name
        supplierId
      }
      nextToken
      total
    }
  }
`;
export const searchTaggings = /* GraphQL */ `
  query SearchTaggings(
    $filter: SearchTaggingsFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchTaggings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        createdAt
        createdBy
        merchantId
        name
        taggingId
        type
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchTimelines = /* GraphQL */ `
  query SearchTimelines(
    $filter: SearchTimelinesFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchTimelines(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        createdAt
        createdBy
        description
        merchantId
        timelineForId
        timelineId
        title
        type
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchUsersFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchUsers(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        createdAt
        createdBy
        email
        isDisabled
        merchantId
        name
        updatedAt
        updatedBy
        userId
      }
      nextToken
      total
    }
  }
`;
export const searchVouchers = /* GraphQL */ `
  query SearchVouchers(
    $filter: SearchVouchersFilter
    $limit: Int
    $merchantId: String
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchVouchers(
      filter: $filter
      limit: $limit
      merchantId: $merchantId
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        acquireOptions {
          type
          values
        }
        activeCount
        category
        createdAt
        createdBy
        customerCondition
        deliveryDiscountType
        discountAmountCap
        discountOnProductValue
        discountQuantityCap
        effectiveEndDateTime
        effectiveStartDateTime
        maxUserUsageLimit
        merchantId
        minimumAmount
        minimumCondition
        minimumQuantity
        orderType
        productConditions {
          ids
          type
        }
        productsDiscount {
          ids
          type
        }
        salesChannelName {
          ids
          type
        }
        status
        stores
        title
        totalUsageLimit
        type
        updatedAt
        updatedBy
        voucherBanner
        voucherCode
        voucherIcon
        voucherId
      }
      nextToken
      total
    }
  }
`;
