import React, { useContext, useEffect, useState } from "react";

import { ContainedButton } from "@/components/buttons/Buttons";

import { GoogleMap } from "@react-google-maps/api";

import { validateDeliveryDialog } from "@/validation/orderModeDialog/validateDeliveryDialog";

import usePlacesAutocomplete from "use-places-autocomplete";

import { Formik, Form } from "formik";

// utils
import _ from "lodash";
import Cookies from "universal-cookie";
import { setToastState } from "@/states/toastBarState";
import { getShopId } from "@/utils/util";
import { useOrder } from "@/contexts/OrderContext";

// context
import MerchantContext from "@/contexts/MerchantContext";
import DeliveryPopupInput from "./DeliveryPopupInput";
import PlacesAutocompleteInput from "./PlacesAutocompleteInput";
import styles from "./OrderModeDialog.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { OrderModalStateActions } from "@/enums/enums";

const AddDelivery = ({
  orderModalState,
  dispatchOrderModalState,
  section,
  handleChangeSection,
  addressDetailToEdit = null,
  getDeliveryAddressList,
  isAuthenticated,
  handleOnSubmitAddressForm,
}) => {
  const cookie = new Cookies();

  const merchantInfoContext = useContext(MerchantContext);
  const [mapStatusMessage, setMapStatusMessage] = useState(
    "No address selected."
  );
  const { deliveryAddress } = useOrder();
  const initialAddressState = {
    name: "",
    address: "",
    isLoggedIn: false,
    selectedLatLng: { lat: null, lng: null },
    country: "",
    postalCode: "",
    state: "",
    city: "",
  };

  const placesAutocompleteProps = usePlacesAutocomplete();

  const funcSplitAddress = (
    addressComponent,
    formattedAddress,
    setFieldValue
  ) => {
    addressComponent.forEach((addressData) => {
      if (addressData.types && addressData.types.length > 0) {
        addressData.types.forEach((type) => {
          switch (type) {
            case "country":
              setFieldValue("country", addressData.long_name);
              break;
            case "postal_code":
              setFieldValue("postalCode", addressData.long_name);
              break;
            case "administrative_area_level_1":
              setFieldValue("state", addressData.long_name);
              break;
            case "administrative_area_level_2":
            case "locality":
              setFieldValue("city", addressData.long_name);
              break;
            default:
          }
        });
      }
    });
    if (formattedAddress) {
      setFieldValue("address", formattedAddress);
    }
  };

  const center = { lat: 5.98604862590014, lng: 116.07658176473377 };

  const getFormikInitialValues = () => {
    let addressItem = null;

    switch (true) {
      case section === "Add":
        addressItem = initialAddressState;
        break;
      case isAuthenticated && section !== "Add":
        const {
          createdAt,
          customerFavouriteAddressId,
          customerId,
          isDefaultBilling,
          isDefaultShipping,
          latitude,
          longitude,
          merchantId,
          updatedAt,
          ...rest
        } = addressDetailToEdit;
        addressItem = {
          ...rest,
          isLoggedIn: isAuthenticated,
          selectedLatLng:
            addressDetailToEdit.latitude && addressDetailToEdit.longitude
              ? {
                  lat: Number(addressDetailToEdit.latitude),
                  lng: Number(addressDetailToEdit.longitude),
                }
              : center,
        };
        break;
      case !isAuthenticated && section !== "Add":
        addressItem = deliveryAddress;
        break;
      default:
    }

    return {
      name: addressItem?.name ?? "",
      address: addressItem?.address ?? "",
      isLoggedIn: isAuthenticated,
      selectedLatLng: _.isEmpty(addressItem?.selectedLatLng)
        ? center
        : addressItem?.selectedLatLng,
      country: addressItem?.country ?? "",
      postalCode: addressItem?.postalCode ?? "",
      state: addressItem?.state ?? "",
      city: addressItem?.city ?? "",
      isLoadFromInput: false,
    };
  };

  // let refMap = null;
  const [refMap, setRefMap] = useState(null);

  const getMapCenterLatLngAndAddress = async (
    handleChangeInput,
    handleChangeLatLng,
    formikProps
  ) => {
    if (refMap) {
      const mapCenter = refMap.getCenter();
      const latLng = {
        lat: mapCenter.lat(),
        lng: mapCenter.lng(),
      };
      const place = await reverseGeocode(latLng);
      handleChangeInput(place.formatted_address);
      handleChangeLatLng(latLng);
      funcSplitAddress(
        place.address_components,
        place.formatted_address,
        formikProps.setFieldValue
      );
    }
  };
  const reverseGeocode = async ({ lat, lng }) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GOOGLE_MAPS_API_KEY}&latlng=${lat},${lng}`;
    const res = await fetch(url);
    const data = await res.json();
    return data.results[0];
  };

  const getUserLocation = async (formikProps) => {
    setMapStatusMessage("Retrieving your location...");
    if (!navigator.geolocation) {
      setMapStatusMessage("No address selected.");
      return false;
    } else {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latLng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          const place = await reverseGeocode(latLng);
          formikProps.setFieldValue("selectedLatLng", latLng);
          formikProps.setFieldValue("address", place.formatted_address);
          placesAutocompleteProps.setValue(place.formatted_address);
          setMapStatusMessage("No address selected.");
        },
        () => {
          setMapStatusMessage("No address selected.");
        }
      );
      return true;
    }
  };

  const handleOnDragAndZoom = async (formikProps) => {
    //isLoadFromInput = whenever user trigger drag event from inputting address in textfield
    if (formikProps.values.isLoadFromInput) {
      formikProps.setFieldValue("isLoadFromInput", false);
    } else {
      const handleChangeLatLng = ({ lat, lng }) => {
        formikProps.setFieldValue("selectedLatLng", { lat, lng });
      };

      const handleChangeAddress = (address) => {
        formikProps.setFieldValue("address", address);
        placesAutocompleteProps.setValue(address);
      };

      getMapCenterLatLngAndAddress(
        handleChangeAddress,
        handleChangeLatLng,
        formikProps
      );
    }
  };

  return (
    <div
      className={[
        "flex flex-col items-start mt-5 h-full",
        section !== null ? "mt-5" : "mt-0",
      ].join(" ")}
    >
      {section !== null && (
        <h2 className="text-lg mt-0 mb-3">
          {section === "Add" ? "Add new address" : "Edit address"}
        </h2>
      )}
      <Formik
        initialTouched={{
          field: true,
        }}
        initialValues={getFormikInitialValues()}
        validationSchema={validateDeliveryDialog}
        validateOnMount={orderModalState.validateOnOpen}
        onSubmit={(val, { setSubmitting }) => {
          if (
            !["country", "postalCode", "city", "state"].some(
              (field) => !val[field]
            ) &&
            val.selectedLatLng.lat &&
            val.selectedLatLng.lng
          ) {
            handleOnSubmitAddressForm(val, setSubmitting);
            if (
              (!isAuthenticated && section === null) ||
              orderModalState.closeAfterSubmitting
            ) {
              dispatchOrderModalState({
                type: OrderModalStateActions.CLOSE,
              });
            } else {
              handleChangeSection("Home");
            }
          } else {
            setSubmitting(false);
            setToastState({
              show: true,
              severity: "error",
              message:
                "Please select a valid address from the dropdown selection.",
            });
          }
        }}
      >
        {(formikProps) => {
          useEffect(() => {
            if (!formikProps.initialValues.address) {
              getUserLocation(formikProps);
            }
          }, []);

          return (
            <Form className="flex flex-col items-start w-full h-[inherit] relative">
              {isAuthenticated && (
                <DeliveryPopupInput
                  label="Label *"
                  type="text"
                  name="name"
                  section={section}
                  errors={formikProps.errors.name}
                  touched={formikProps.touched.name}
                />
              )}
              <PlacesAutocompleteInput
                funcSplitAddress={funcSplitAddress}
                section={section}
                formikProps={formikProps}
                getUserLocation={getUserLocation}
                {...placesAutocompleteProps}
              />

              <div
                className={[
                  "flex flex-col items-center justify-center w-full rounded-lg overflow-hidden sm:h-[45vh] relative bg-[#E5E3DF]",
                  styles["fill-remaining-height-mobile-view"],
                ].join(" ")}
              >
                {formikProps.values.selectedLatLng.lat &&
                formikProps.values.selectedLatLng.lng ? (
                  <>
                    <GoogleMap
                      zoom={17}
                      onLoad={(map) => setRefMap(map)}
                      mapContainerClassName="h-full w-full"
                      center={formikProps.values.selectedLatLng}
                      options={{
                        disableDefaultUI: true,
                        gestureHandling: "greedy",
                      }}
                      onDragEnd={() => handleOnDragAndZoom(formikProps)}
                      onZoomChanged={() => handleOnDragAndZoom(formikProps)}
                    >
                      {/* <Marker position={formikProps.values.selectedLatLng} /> */}
                    </GoogleMap>
                    <div className="absolute w-[5rem] text-center right-0 left-0 mx-auto mt-auto mb-[7%] flex flex-col items-center">
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        className="text-primary text-[2.2rem]"
                      />
                      <div className="pt-[5px] border-b-2 border-primary w-[1.2rem]"></div>
                    </div>
                  </>
                ) : (
                  <div className="absolute w-[12rem] text-gray-600 text-center right-0 left-0 mx-auto mt-auto mb-[5%]">
                    {mapStatusMessage}
                  </div>
                )}
              </div>
              <div className="fixed bottom-0 left-0 sm:static w-[100%] h-auto px-5 sm:px-0 py-2 sm:pb-0 bg-white">
                <ContainedButton
                  className="w-full mb-2 mt-3"
                  border="rounded-full"
                  type="submit"
                  loading={
                    !formikProps.isValidating && formikProps.isSubmitting
                  }
                >
                  Confirm
                </ContainedButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddDelivery;
