import useSWR from "swr";
import { API, graphqlOperation } from "aws-amplify";
import { getLegalPolicy } from "@/graphql/queries";

export const useGetLegalPolicies = (path = "", swrOption = {}) => {
  if (!path) {
    throw new Error("Path is required");
  }
  const legalFetcher = async () => {
    try {
      var params = {
        merchantId: path,
      };
      const { data } = await API.graphql(
        graphqlOperation(getLegalPolicy, params)
      );
      const legalPolicyStatus = data?.getLegalPolicy.status;
      if (legalPolicyStatus === false) {
        return [];
      }
      return data?.getLegalPolicy.legalPolicies;
    } catch (error) {
      console.log("error", error);
      return null;
    }
  };

  // Only call the fetcher method if `user` exists
  const { data: legalPolicies } = useSWR(
    "getLegalPolicy",
    legalFetcher,
    swrOption
  );
  return { legalPolicies };
};
