import SplashScreen from "@/components/splashScreen/SplashScreen";
import NewHeader from "@/components/header/NewHeader";
import Footer from "@/components/footer/Footer";
import { FloatingButton } from "@/components/buttons/Buttons";
import Toast from "@/components/alert/Toast";
import OrderModeDialog from "@/components/orderModeDialog/OrderModeDialog";
import { useOrder } from "@/contexts/OrderContext";
import { useAuth } from "@/contexts/AuthContext";

const Layout = ({
  children,
  setting = { siteFont: "Helvetica", siteColor: "#42a5f5" },
  policies,
  appContext,
  headerList,
  footerList,
  legalPolicies,
  splashScreenList,
  routePath,
}) => {
  const { orderModalState, dispatchOrderModalState } = useOrder();
  const { merchantType } = useAuth();

  const isWarung = merchantType === "warung";

  return (
    <>
      <style jsx global>
        {`
          :root {
            --display-font: ${setting.siteFont};
            --body-font: ${setting.siteFont};
            --main-bg-color: ${setting.siteColor};
            --notification-bg-color: #276688;
            --notification-font-color: #ffffff;
            --header-footer-font-color: ${setting.headerFooterFontColor};
            --font-size: 20px;
          }
        `}
      </style>
      <Toast />
      <div className="relative bg-[#F5F6F9] font-body text-body min-h-screen flex flex-col justify-between">
        <>
          {!isWarung && (
            <>
              <FloatingButton whatsappNo={setting.whatsappNo} />
              <SplashScreen splashScreenList={splashScreenList} />
            </>
          )}

          {(!isWarung || (isWarung && routePath !== "/")) && (
            <NewHeader
              siteNav={headerList}
              notificationMessage={setting.notificationMessage}
              isWarung={isWarung}
              dispatchOrderModalState={dispatchOrderModalState}
            />
          )}
        </>

        <main
          className={
            "relative mt-auto mx-auto max-w-1200 mb-auto container flex flex-col flex-grow"
          }
        >
          <div
            className={
              !(isWarung && routePath === "/collections-menu")
                ? "min-h-[553px]"
                : ""
            }
          >
            {children}
          </div>
        </main>
        {!isWarung && <Footer siteNav={footerList} policies={legalPolicies} />}
      </div>
      {(!isWarung || (isWarung && routePath !== "/")) &&
        orderModalState.view && (
          <OrderModeDialog
            orderModalState={orderModalState}
            dispatchOrderModalState={dispatchOrderModalState}
          />
        )}
    </>
  );
};

export default Layout;
