import { Fragment, useState, useEffect, useContext } from "react";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";

import { Dialog, Transition } from "@headlessui/react";
import {
  MenuIcon,
  SearchIcon,
  XIcon,
  UserIcon,
  HeartIcon,
} from "@heroicons/react/outline";

import { BadgeButton } from "@/components/buttons/Buttons";
import CustomMenu from "@/components/menu/CustomMenu";

import { CartIcon } from "@/components/icons/Icons";
import DeliveryIcon from "@/images/bike.svg";
import PickupIcon from "@/images/store.svg";
import DineInIcon from "@/images/table.svg";
// contexts
import MerchantContext from "@/contexts/MerchantContext";
import { useAuth } from "@/contexts/AuthContext";
import { useWishlist } from "@/contexts/WishlistContext";
import { useCart } from "@/contexts/CartContext";
import { useOrder } from "@/contexts/OrderContext";

import { OrderModalStateActions } from "@/enums/enums";

const Header = (props) => {
  const { siteNav, notificationMessage, isWarung } = props;
  const [open, setOpen] = useState(false);
  const { isAuthenticated, handleSignOut, merchantType } = useAuth();
  const { customerWishListIds } = useWishlist();
  const { cartList } = useCart();
  const { orderType, dispatchOrderModalState } = useOrder();
  const merchantInfoContext = useContext(MerchantContext);
  const router = useRouter();

  //hide navbar when scrolling down
  const [position, setPosition] = useState(
    typeof window !== "undefined" ? window.pageYOffset : null
  );
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleScroll = () => {
        let moving = window.pageYOffset;

        setVisible(position > moving);
        setPosition(moving);
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  });

  const profileNavs = {
    childItems: [
      ...(merchantType === "warung"
        ? [
            {
              linkValue: "collections-menu",
              title: "View Menu",
            },
          ]
        : []),
      {
        linkValue: "my-profile",
        title: "My Profile",
      },
      {
        linkValue: "order",
        title: "My Orders",
      },
      {
        linkValue: "address-book",
        title: "Address Book",
      },
      {
        linkValue: "",
        title: "Sign Out",
      },
    ],
    title: "Account",
    buttonIcon: <UserIcon className="w-6 h-6 text-white" aria-hidden="true" />,
  };

  const [isOpen, setIsOpen] = useState({ show: {} });

  const availableOrderMode = {
    Delivery: DeliveryIcon,
    DineIn: DineInIcon,
    PickUp: PickupIcon,
  };

  const toggleListItem = (itemId) => {
    setIsOpen((prevState) => ({
      ...prevState,
      show: {
        ...prevState.show,
        [itemId]: !prevState.show[itemId],
      },
    }));
  };

  useEffect(() => {
    setOpen(false);
  }, [router]);

  return (
    <>
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative max-w-xs w-full bg-primary shadow-xl pb-12 flex flex-col overflow-y-auto">
              <div className="px-4 pt-5 pb-2 flex">
                <button
                  type="button"
                  className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>

              {/* Links */}
              {siteNav?.length > 0 && (
                <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                  {siteNav?.map((category, index) => (
                    <div key={index} className="flow-root">
                      <div key={category.title}>
                        {category.childItems.length > 0 ? (
                          <a
                            id={`${category.siteNavigationId}-heading-mobile`}
                            className="font-medium no-underline text-headerFooterFontColor"
                            onClick={() => {
                              toggleListItem(category.siteNavigationId);
                            }}
                          >
                            {category.title}
                          </a>
                        ) : (
                          <Link href={"/" + category.linkValue} passHref>
                            <a
                              id={`${category.siteNavigationId}-heading-mobile`}
                              className="font-medium no-underline text-headerFooterFontColor"
                            >
                              {category.title}
                            </a>
                          </Link>
                        )}

                        {isOpen.show[category.siteNavigationId] ? (
                          <ul
                            role="list"
                            aria-labelledby={`${category.siteNavigationId}-heading-mobile`}
                            className={"mt-6 flex flex-col space-y-6"}
                          >
                            {category.childItems.map((item) => (
                              <li key={item.title} className="flow-root">
                                {item.linkValue.includes("https:/") ? (
                                  <a
                                    className="-m-2 p-2 block no-underline text-headerFooterFontColor"
                                    target="_blank"
                                    href={item.linkValue}
                                    rel="noopener noreferrer"
                                  >
                                    {item.title}
                                  </a>
                                ) : (
                                  <Link href={"/" + item.linkValue} passHref>
                                    <a className="-m-2 p-2 block no-underline text-headerFooterFontColor">
                                      {item.title}
                                    </a>
                                  </Link>
                                )}
                              </li>
                            ))}
                          </ul>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {isAuthenticated ? (
                <>
                  {profileNavs.childItems.length > 0 && (
                    <div className="py-6 px-4 space-y-6">
                      {profileNavs.childItems.map((item, index) => (
                        <div className="flow-root" key={index}>
                          {item.title === "Sign Out" ? (
                            <a
                              className="-m-2 p-2 block font-medium no-underline text-headerFooterFontColor"
                              onClick={handleSignOut}
                            >
                              {item.title}
                            </a>
                          ) : (
                            <Link href={item.linkValue} passHref>
                              <a className="-m-2 p-2 block font-medium no-underline text-headerFooterFontColor">
                                {item.title}
                              </a>
                            </Link>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <div className="py-6 px-4 space-y-6">
                  <div className="flow-root">
                    <Link href="/collections-menu" passHref>
                      <a className="-m-2 p-2 block font-medium no-underline text-headerFooterFontColor">
                        View Menu
                      </a>
                    </Link>
                  </div>
                  <div className="flow-root">
                    <Link href="/login" passHref>
                      <a className="-m-2 p-2 block font-medium no-underline text-headerFooterFontColor">
                        Sign in
                      </a>
                    </Link>
                  </div>
                  <div className="flow-root">
                    <Link href="/register" passHref>
                      <a className="-m-2 p-2 block font-medium no-underline text-headerFooterFontColor">
                        Create account
                      </a>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      {/* desktop view */}

      <header
        className={[
          "sticky bg-primary h-auto w-full z-20",
          visible ? "top-0 md:top-0" : "top-[-200px] md:top-0",
        ].join(" ")}
        style={{ transition: "top 0.3s ease-out" }}
        id="header"
      >
        {!isWarung && notificationMessage && (
          <label className="pt-2 pb-2 whitespace-pre-wrap bg-notificationBarColor flex items-center justify-center text-sm font-medium text-headerFooterFontColor px-4 sm:px-6 lg:px-8 truncate h-auto">
            {notificationMessage}
          </label>
        )}

        <nav
          aria-label="Top"
          className="mx-auto max-w-1200 px-2 md:px-3 xl:px-0"
        >
          <div className="border-b border-primary">
            <div
              className={`${
                !isWarung ? "h-[60px] sm:h-[92px]" : "h-auto sm:min-h-[60px]"
              } flex justify-between items-center w-full my-3`}
            >
              {/* mobile button */}
              <button
                type="button"
                className="bg-primary p-2 rounded-md text-headerFooterFontColor lg:hidden"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Logo */}
              {!isWarung ? (
                <div className="cursor-pointer flex relative w-full max-w-[5rem] lg:max-w-[15rem] h-full lg:justify-start">
                  <Link href="/" passHref>
                    <a>
                      <Image
                        alt="logo"
                        src={process.env.BUCKET_URL + merchantInfoContext.logo}
                        layout="fill"
                        objectFit="contain"
                      />
                    </a>
                  </Link>
                </div>
              ) : (
                <div className="flex justify-center py-1">
                  <Link href="/" passHref>
                    <a className="text-white no-underline">
                      {merchantInfoContext.name}
                    </a>
                  </Link>
                </div>
              )}

              {siteNav?.map((obj, idx) => (
                <CustomMenu obj={obj} key={idx} />
              ))}

              <div className="flex items-center mr-1 sm:mr-0">
                <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                  {/* Search */}
                  <Link href="/search">
                    <a className="p-2 text-headerFooterFontColor ">
                      <span className="sr-only">Search</span>
                      <SearchIcon className="w-6 h-6" aria-hidden="true" />
                    </a>
                  </Link>
                </div>

                {/* Sign in */}
                {isAuthenticated ? (
                  <>
                    <CustomMenu
                      obj={profileNavs}
                      menuItemsClassName="w-[160px]"
                      menuButtonClassName="p-2"
                    />
                  </>
                ) : (
                  <div className="lg:p-2 lg:flex lg:items-center hidden">
                    <Link href="/login">
                      <a className="text-sm font-medium text-headerFooterFontColor">
                        <UserIcon className="w-6 h-6" aria-hidden="true" />
                      </a>
                    </Link>
                  </div>
                )}

                {/* wishlist */}
                <div className="lg:flex lg:items-center hidden lg:p-2">
                  <BadgeButton
                    icon={
                      <Link href="/wishlist" passHref>
                        <a className="text-sm font-medium text-headerFooterFontColor ">
                          <HeartIcon className="w-6 h-6" aria-hidden="true" />
                        </a>
                      </Link>
                    }
                    number={customerWishListIds?.length}
                  />
                </div>
                {!!orderType && (
                  <div className="lg:p-2 ">
                    <BadgeButton
                      onClick={() =>
                        dispatchOrderModalState({
                          type: OrderModalStateActions.HOME,
                        })
                      }
                      number={0}
                      icon={
                        <Image
                          className="cursor-pointer"
                          src={availableOrderMode[orderType]}
                          width={23}
                          height={23}
                        />
                      }
                    />
                  </div>
                )}

                {/* cart */}
                <div className="lg:p-2">
                  <BadgeButton
                    icon={
                      <Link href="/view-cart">
                        <a className="text-sm font-medium text-headerFooterFontColor ">
                          <CartIcon className="w-6 h-6" />
                        </a>
                      </Link>
                    }
                    number={cartList?.length}
                  />
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
export default Header;
