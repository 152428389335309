/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ap-southeast-1",
  aws_cognito_identity_pool_id:
    "ap-southeast-1:b49dce2a-ce40-4e30-af67-acb1c2207cfa",
  aws_cognito_region: "ap-southeast-1",
  aws_user_pools_id: "ap-southeast-1_jJGn5y8UG",
  aws_user_pools_web_client_id: "680dkaukr95d1b5s5ort3b5jo7",
  oauth: {},
  aws_appsync_graphqlEndpoint:
    "https://2z26v6uyevavng3nuzghdq2fce.appsync-api.ap-southeast-1.amazonaws.com/graphql",
  aws_appsync_region: "ap-southeast-1",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-34cfj7sl2rd73mbu5bwrhmzwwe",
  aws_user_files_s3_bucket: "ecom-webassets-dev",
  aws_user_files_s3_bucket_region: "ap-southeast-1",
};

export default awsmobile;
